import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, Subscription } from 'rxjs';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { ReferralDTO } from 'src/service-proxies/dtos/referrals.dto';
import { UsersServiceProxy } from 'src/service-proxies/service-proxies';
import { EventBus } from 'src/shared/event-bus';
import BigNumber from 'bignumber.js';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'referral-page',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
})
export class ReferralComponent
  extends ComponentBase
  implements OnInit, OnDestroy {
  private sub: Subscription;
  public referralProgramVideoUrl: string;

  constructor(
    private readonly web3Service: Web3Service,
    private readonly userSession: UserSessionProvider,
    private readonly userServiceProxy: UsersServiceProxy,
    private readonly eventBus: EventBus,
    public translate: TranslateService,

  ) {
    super();
    this.referralProgramVideoUrl = environment.referralProgramVideoUrl;
  }

  userRefBonusBalance$: Observable<string> = from(
    this.web3Service.getRewardsAmount(this.userSession.linkedWallet)
  ).pipe(map((i) => i.toString(10)));
  userRefs$: Observable<ReferralDTO[]> = this.userServiceProxy.getReferrals();
  refBonusBalance: number;
  referrals: ReferralDTO[];
  filteredReferrals: ReferralDTO[];
  totalRewardsAmount: string;
  refLink: string;

  async ngOnInit() {
    await this.web3Service.initWeb3();

    await this.web3Service
      .getRewardsAmount(this.userSession.linkedWallet)
      .then((res) => {
        this.refBonusBalance = new BigNumber(res).shiftedBy(-18).toNumber();
      });

    this.userServiceProxy.getReferrals().subscribe((res) => {
      this.referrals = res;
      this.filteredReferrals = this.referrals.filter(
        (referral) => parseInt(referral.stackedAmount) > 0
      );
      this.totalRewardsAmount = String(
        res.reduce((total, item) => total + parseFloat(item.rewardAmount), 0)
      );
    });

    this.sub = this.eventBus.loginEvent.subscribe((result) => {
      this.refreshBindings();
    });

    this.userServiceProxy.getUserReferalToken().subscribe((result)=>{
      this.refLink = `${window.location.origin}/signup?referrer=${result.token}`;
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public async claimReward(): Promise<void> {
    if (this.refBonusBalance < 1500) {
      this.showWarningModal(this.translate.instant('minimalClaimAmount'));
    } else {
      await this.web3Service.claimRefRewards(this.userSession.linkedWallet);
    }
  }

  private refreshBindings(): void {
    this.userRefBonusBalance$ = from(
      this.web3Service.getRewardsAmount(this.userSession.linkedWallet)
    ).pipe(map((i) => i.toString(10)));
  }
}
