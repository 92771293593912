import { Component, OnInit, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dlg-nft-staking-info',
  templateUrl: './dlg-nft-staking-info.component.html',
  styleUrls: ['./dlg-nft-staking-info.component.scss']
})
export class DlgNftStakingInfoComponent implements OnInit {
  @Input() confirm: Function;

  constructor(public dialogRef: MatDialogRef<DlgNftStakingInfoComponent>) { }

  ngOnInit(): void { }

}
