import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgTierPipe',
})
export class ImgTierPipe implements PipeTransform {
    transform(index: number): string {
        if (index == -1) return '/assets/images/tier/none.png';
        if (index == 0) return '/assets/images/tier/flat/Bot.svg';
        if (index == 1) return '/assets/images/tier/flat/Cyborg.svg';
        if (index == 2) return '/assets/images/tier/flat/Android.svg';
        if (index == 3) return '/assets/images/tier/flat/Humanoid.svg';
        if (index == 4) return '/assets/images/tier/flat/Crypto_Ninja.svg';
        return '/assets/images/tier/none.png';
    }
}
