import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './dlg-tier-info.component.html',
})
export class DlgTierInfoComponent implements OnInit {
    @Input() tierId: number;
    @Input() tierProgress: number;
    @Input() tierAmount: string;
    @Input() currentAmount: string;
    @Input() leftAmount: string;
    constructor(public dialogRef: MatDialogRef<DlgTierInfoComponent>, public translate: TranslateService) {}
    async ngOnInit(): Promise<void> {}

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1) return this.translate.instant('none');
    if (tierIndex == 0) return this.translate.instant('bot');
    if (tierIndex == 1) return this.translate.instant('cyborg');
    if (tierIndex == 2) return this.translate.instant('android');
    if (tierIndex == 3) return this.translate.instant('humanoid');
    if (tierIndex == 4) return this.translate.instant('cryptoNinja');
    return 'None';
  }
}
