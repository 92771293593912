import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgLockerModalComponent } from '../dlg-locker-modal';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { from } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { BigNumber } from 'bignumber.js';
import { DlgLockerInfoComponent } from '../dlg-locker-info';
import { DlgTierInfoComponent } from '../dlg-tier-info';
import { KYC_required } from 'src/internal/kyc.decorator';
import { environment } from '../../environments/environment';
import swal from 'sweetalert2';
import networks from '../../app/networks.data';
import { DlgNftStakingInfoComponent } from '../dlg-nft-staking-info';
import { StakingPoolServiceProxy } from 'src/service-proxies/service-proxies';
import { StakingService } from 'src/shared/staking-service';
import {TranslateService} from "@ngx-translate/core";
declare const window: any;

@Component({
    selector: 'app-main-page',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainPageComponent extends ComponentBase implements OnInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private alertSrv: AlertService,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private stakingService: StakingService,
        private stakingPoolService: StakingPoolServiceProxy,
        private userSessionProvider: UserSessionProvider,
        public translate: TranslateService
    ) {
        super();
    }

    public tokenInfo = environment.tokenInfo;

    waiting = false;
    account = '';

    updateUserTimerId: NodeJS.Timeout;

    userWalletBalance: string;
    totalUserLockedBalance: string;
    totalUserGmpdBalance: string;
    lockerOldUserBalance: string;
    lockerUserBalance: string;
    lockedWithBonusValue: string;
    stakingUserBalance: string;
    farmingUserBalance: string;
    bonusBalance: string;
    bonusInterval: number = 0;

    lockedTotalBalance: string;
    penaltyAmount: string;
    userTierIndex = -1;
    nextTierIndex: number;
    isMaxTier = false;
    isNftTier = false;
    currentTierProgress = 0;
    nextTierValue: string;
    nextTierNeedValue: any;

    allTiers: any[] = new Array();
    allPenalties: any[] = new Array();
    userLockingStartTimestamp: number;
    userUnstakeTimestamp: number;
    allTiersLength = 0;

    now = Math.floor(Date.now() / 1000);
    // Last participation time
    lastParticipationTime = 0;
    lockupDuration = 0;

    lockedBalancePercent = 0;
    stakingPercent = 0;
    farmingPercent = 0;
    chartData: object;
    chartOptions = {
        offset: 0,
        aspectRatio: 1,
        responsive: true,
        cutoutPercentage: 92,
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false,
        },
        animation: {
            animateRotate: false,
        },
    };

    isAuthorized = false;
    checking = false;
    selectedNFT: any;
    selectedNFTType: any;
    isNFTLocked = false;
    isNFTStaked = false;
    isNFTBlockVisible = false;
    isLoadingNFT = true;
    nftType: number;

    public tokenID: any;

    @KYC_required
    public async lockNFT(): Promise<any> {
        this.checking = true;
        await this.web3Service.lockerDepositNFT(this.userSessionProvider.linkedWallet, this.tokenID);
        this.checking = false;
    }

    @KYC_required
    public async lockNFTToken(): Promise<void> {
        if (!this.tokenID) {
            this.showErrorModal(this.translate.instant('tokenIdIsRequired'));
            return;
        }

        let chainId = this.web3Service.chainIdNumber;
        if (chainId != environment.defaultChainId) {
            this.showWarningHTMLModal(this.translate.instant('pleaseSwitchToBscToLockNft'), this.translate.instant('confirm'), async () => {
                await this.swtichNetwork(environment.defaultChainId);
            });
            return;
        }

        from(this.web3Service.getTokenOwner(this.tokenID))
            .pipe(
                filter(ownerAddress => {
                    if (this.userSessionProvider.linkedWallet === ownerAddress) {
                        this.isLoadingNFT = true;
                        return true;
                    }
                    this.showErrorModal(this.translate.instant('youAreNotAnNftOwner'));
                    return false;
                }),
                switchMap(i =>
                    from(this.web3Service.lockerDepositNFT(this.userSessionProvider.linkedWallet, this.tokenID)),
                ),
            )
            .subscribe(async result => {
                await this.handleNFTTx(result);
            });
    }

    @KYC_required
    public async stakeNFTToken(): Promise<void> {
        from(this.stakingService.getBridgedTokenOwner(this.tokenID))
            .pipe(
                filter(ownerAddress => {
                    if (this.userSessionProvider.linkedWallet === ownerAddress) {
                        this.isLoadingNFT = true;
                        return true;
                    }
                    this.showErrorModal(this.translate.instant('youAreNotAnNftOwner'));
                    return false;
                }),
                switchMap(i => from(this.stakingService.stakeNFT(this.userSessionProvider.linkedWallet, this.tokenID))),
            )
            .subscribe(async result => {
                await this.handleNFTTxForStaking(result, false);
            });
    }

    private async swtichNetwork(chainId: number): Promise<void> {
        let toNetwork = networks.find(n => n.chainId == chainId);
        try {
            // @ts-ignore
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: toNetwork.networkParams.chainId }],
            });
            return;
        } catch (switchError: any) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [toNetwork.networkParams],
                    });
                } catch (addError) {
                    console.error(addError);
                    this.userSessionProvider.finishSession();
                }
            }
        }
    }

    private async handleNFTTx(result: any): Promise<void> {
        result.error$.subscribe(() => {
            this.isLoadingNFT = false;
        });

        result.transactionHash$.subscribe((val: any) => {
            this.alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await result.receipt$.toPromise();

            this.alertSrv.show(this.translate.instant('confirmedTransaction'));
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.info('catch', err);
        }

        await this.checkLockedNFT();
    }

    private async handleNFTTxForStaking(result: any, isForWithdraw: boolean): Promise<void> {
        result.error$.subscribe(() => {
            this.isLoadingNFT = false;
        });
        result.transactionHash$.subscribe((val: any) => {
            this.alertSrv.show(this.translate.instant('transactionSubmitted'));
        });
        try {
            await result.receipt$.toPromise();
            if (isForWithdraw) {
                let withdrawNFT = await this.stakingPoolService
                    .withdrawNFT(this.userSessionProvider.linkedWallet)
                    .toPromise();
                console.log('withdrawNFT result >>>>', withdrawNFT);
            }
            if (!isForWithdraw) {
                let depositNFT = await this.stakingPoolService
                    .depositNFT(this.userSessionProvider.linkedWallet, parseInt(this.tokenID))
                    .toPromise();
                console.log('depositNFT result >>>>', depositNFT);
            }
            this.alertSrv.show(this.translate.instant('confirmedTransaction'));
        } catch (err) {
            // tslint:disable-next-line:no-console
            console.info('catch', err);
            this.isLoadingNFT = false;
        }

        await this.checkLockedNFT();
    }

    public async checkLockedNFT(): Promise<void> {
        let stakedNFT = 0;
        const lockedNFT = Number(await this.web3Service.getLockedNFT(this.userSessionProvider.linkedWallet));
        try {
            stakedNFT = Number(await this.stakingService.getStakedNFT(this.userSessionProvider.linkedWallet));
        } catch (error) {
            console.log('getStakedNFT', error);
        }
        // tslint:disable-next-line:triple-equals
        if (!lockedNFT && !stakedNFT) {
            console.log('dont have NFT');
            this.isNFTLocked = false;
            this.isNFTStaked = false;
            this.nftType = -1;
        } else {
            if (lockedNFT) this.isNFTLocked = true;
            if (stakedNFT) this.isNFTStaked = true;
            console.log('have NFT');
            let nftId = !lockedNFT ? stakedNFT : lockedNFT;
            this.nftType = Number(await this.web3Service.getNFTTYpe(this.userSessionProvider.linkedWallet, nftId));
        }
        this.isLoadingNFT = false;
        this.updateUserData();
    }

    public async unlockNft(): Promise<void> {
        this.isLoadingNFT = true;
        const actionObs = await this.web3Service.lockerWithdrawNFT(this.userSessionProvider.linkedWallet);
        await this.handleNFTTx(actionObs);
    }

    public async unstakeNft(): Promise<void> {
        if (this.web3Service.chainIdNumber != environment.eth.chainId) {
            this.showWarningHTMLModal(this.translate.instant('pleaseSwitchToEthereumToUnstakeNft'), this.translate.instant('confirm'), async () => {
                await this.swtichNetwork(environment.eth.chainId);
            });
            return;
        }
        if (this.userUnstakeTimestamp > new Date().getTime() / 1000) {
            this.showWarningModal(this.translate.instant('youCantWithdrawNftDuringTheStakingPeriod'));
            return;
        }
        this.isLoadingNFT = true;
        const actionObs = await this.stakingService.unstakeNFT(this.userSessionProvider.linkedWallet);
        await this.handleNFTTxForStaking(actionObs, true);
    }

    public selectedNFTChanged(value: any): void {
        this.selectedNFTType = this.getNFTType(value);
    }

    private isInRange(value: number, min: number, max: number): boolean {
        return value >= min && value <= max;
    }

    private getNFTType(tokenId: number): string {
        if (this.isInRange(tokenId, 100300474083, 100300474092)) {
            return this.translate.instant('android');
        } else if (this.isInRange(tokenId, 100300474093, 100300474392)) {
            return this.translate.instant('bot');
        } else if (this.isInRange(tokenId, 100300474393, 100300474582)) {
            return this.translate.instant('cyborg');
        }
        console.log(`Can't identify token type ${tokenId}`);
        return this.translate.instant('testAndroid');
    }

    async ngOnInit(): Promise<void> {
        this.isAuthorized = this.userSessionProvider.isAuthorized;
        await this.web3Service.initWeb3(
            this.web3Service.chainIdNumber != environment.defaultChainId ? environment.defaultChainId : 0,
        );
        await this.stakingService.initWeb3(
            this.web3Service.chainIdNumber != environment.eth.chainId ? environment.eth.chainId : 0,
        );
        if (this.web3Service.web3) {
            this.updateContractData();
        }

        if (this.userSessionProvider.linkedWallet) {
            this.eventLogin(this.userSessionProvider.linkedWallet);
        }
        this.eventBus.loginEvent.subscribe(result => {
            // console.log('loginEvent subscription:' + result);
            this.eventLogin(result);
        });

        this.eventBus.logoutEvent.subscribe(result => {
            // console.log('logoutEvent subscription:' + result);
            this.eventLogout();
        });

        this.eventBus.logoutEvent.subscribe(result => {
            // console.log('logoutEvent subscription:' + result);
            this.eventLogout();
        });

        this.eventBus.needUpdateUsersInfo.subscribe(result => {
            // console.log('needUpdateUsersInfo subscription:' + result);
            this.updateUserData();
        });
    }

    ngOnDestroy(): void {
        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
    }

    eventLogin(username: string): void {
        // tslint:disable-next-line:triple-equals
        if (this.account != username) {
            this.account = username;

            this.updateUserData();

            this.updateUserTimerId = setInterval(() => {
                this.updateUserData();
            }, this.expectedBlockTime);
        }
    }

    eventLogout(): void {
        this.account = '';

        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
    }

    async updateContractData(): Promise<void> {
        // tslint:disable-next-line:radix
        const tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
        for (let i = 0; i < tiesrLenght; i++) {
            const tier = await this.web3Service.getDealLockupsTiers(i);
            this.allTiers.push(tier);
            this.allTiersLength = tiesrLenght;
        }
        //this.updateUserData();
        this.checkLockedNFT();

        for (let i = 0; i < 5; i++) {
            const penalties = await this.web3Service.getLockerPenalties(i);
            if (penalties != null) {
                this.allPenalties.push(penalties);
            }
        }
        // tslint:disable-next-line:radix
        this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
    }

    public get isLockedBalanceExist(): boolean {
        // tslint:disable-next-line:radix
        const tkn = parseInt(this.lockerOldUserBalance);
        return tkn > 0;
    }

    private async getPoolBalance(poolAddress: string): Promise<string> {
        const poolBalanceData = await this.web3Service.getPoolUserInfo(this.account, poolAddress);
        return poolBalanceData?.[0] || 0;
    }

    private async getStackingBalance(): Promise<any[]> {
        return await Promise.all(
            this.web3Service.getStackingAddresses.map(async poolAddress => await this.getPoolBalance(poolAddress)),
        );
    }

    private getUserTier(balance: BigNumber): number {
        let tierIndex = 0;
        let maxTier = 0;
        let success = false;

        for (let i = 0; i < this.allTiers.length; i++) {
            maxTier = this.allTiers[i].gmpdOnlyAmount;
            if (
                this.allTiers[i] &&
                balance.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i]?.gmpdOnlyAmount)) &&
                // Check that next level is higher than previous
                new BigNumber(this.allTiers[i].gmpdOnlyAmount).isGreaterThanOrEqualTo(
                    new BigNumber(this.allTiers[tierIndex].gmpdOnlyAmount),
                )
            ) {
                tierIndex = i;
                success = true;
            }
        }

        return success ? tierIndex : -1;
    }

    private getUserNftTier(balance: BigNumber): number {
        if (!(this.isNFTLocked || this.isNFTStaked) || this.nftType < 0) {
            return -1;
        }

        let tierIndex = 0;
        let maxTier = 0;
        let success = false;

        for (let i = 0; i < this.allTiers.length; i++) {
            maxTier = this.allTiers[i].gmpdAmount;
            if (
                this.allTiers[i] &&
                balance.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i]?.gmpdAmount)) &&
                // Check that next level is higher than previous
                new BigNumber(this.allTiers[i].gmpdAmount).isGreaterThanOrEqualTo(
                    new BigNumber(this.allTiers[tierIndex].gmpdAmount),
                )
            ) {
                tierIndex = i;
                success = true;
            }
        }

        if (success) {
            return this.nftType < tierIndex ? this.nftType : tierIndex;
        } else {
            return -1;
        }
    }

    async updateUserData(): Promise<void> {
        if (this.account) {
            this.userWalletBalance = await this.web3Service.GetTokenBalance(this.account, this.web3Service.blpAddress);
            this.lockerUserBalance = await this.web3Service.getLockedTokenAmount(
                this.web3Service.lockerAddress,
                this.account,
            );
            this.stakingUserBalance = (await this.getStackingBalance()).reduce(
                (partialSum: string, a: string) => BigInt(partialSum) + BigInt(a),
                0,
            );
            this.farmingUserBalance = (
                await this.web3Service.getPoolUserInfo(this.account, this.web3Service.getFarmingAddress)
            )[0];
            this.lockedTotalBalance = await this.web3Service.getLockedTokenAmountTierCalculator(
                this.web3Service.tierCalculatorAddress,
                this.account,
            );
            this.bonusBalance = await this.web3Service.getBonusAmount(this.web3Service.bonusAddress, this.account);
            this.bonusInterval = parseInt(await this.web3Service.getBonusInterval()) / 60;


          const totalGMPD =
                BigInt(this.lockerUserBalance) + BigInt(this.stakingUserBalance) + BigInt(this.farmingUserBalance);
            this.totalUserLockedBalance = String(totalGMPD.toString().replace('n', ''));

            const penaltiesData = await this.web3Service.getLockerPenaltyBP(
                this.web3Service.lockerAddress,
                this.account,
            );
            this.penaltyAmount = String(penaltiesData[1]);
            let lockedBalanceBn = new BigNumber(this.lockerUserBalance).plus(new BigNumber(this.stakingUserBalance)).plus(new BigNumber(this.bonusBalance));

            let tierIndex = this.getUserTier(lockedBalanceBn);
            const nftTierIndex = this.getUserNftTier(lockedBalanceBn);

            if (nftTierIndex >= tierIndex) {
                this.userTierIndex = nftTierIndex;
                this.isNftTier = this.userTierIndex != -1;
            } else {
                this.userTierIndex = tierIndex;
                this.isNftTier = false;
            }

            this.nextTierIndex = this.userTierIndex + 1;

            let nextIndex = 0;
            let maxTier = 0;
            let success = false;
            console.log(' >>> userTierIndex', this.userTierIndex);

            for (let i = 0; i < this.allTiers.length; i++) {
              maxTier = this.allTiers[i].blpAmount;
              if (this.allTiers[i] && lockedBalanceBn.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i].blpAmount))
                //Check that next level is higher than previous
                && new BigNumber(this.allTiers[i].blpAmount).isGreaterThanOrEqualTo(new BigNumber(this.allTiers[tierIndex].blpAmount))) {
                tierIndex = i;
                nextIndex = i + 1;
                success = true;
                //console.log('[tier] check success');
              }
            }
            // Is current tier is max & filled
            if (this.allTiersLength === tierIndex && Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance) >= maxTier) {
                this.isMaxTier = true;
                this.currentTierProgress = 100;
            } else {
                const nextTier = this.allTiers[this.nextTierIndex];
                if (nextTier) {
                    const nextTierValue = this.isNFTLocked ? nextTier.gmpdAmount : nextTier.gmpdOnlyAmount;
                    const leftToNext = parseInt(nextTierValue) - (parseInt(this.lockerUserBalance) + parseInt(this.stakingUserBalance) + parseInt(this.bonusBalance));
                    this.nextTierNeedValue = leftToNext > 0 ? leftToNext : 0;
                    this.currentTierProgress = (parseInt(this.lockerUserBalance) + parseInt(this.stakingUserBalance) + parseInt(this.bonusBalance)) * 100 / parseInt(nextTierValue);
                    this.nextTierValue = nextTierValue;
                }
            }

            this.web3Service.getDealLockupsTiers(this.userTierIndex).then(async resp => {
                this.lockupDuration = parseInt(resp[2]);
            });

            this.web3Service.getLastParticipations(this.account).then(resp => {
                this.lastParticipationTime = parseInt(resp);
            });

            this.updateTokensGraph();

            if (this.isNFTStaked) {
                this.userUnstakeTimestamp = parseInt(await this.stakingService.getUserStakeEnd(this.account));
            }
        }
    }

    updateTokensGraph(): void {
        // const userWalletBalance = Number(this.userWalletBalance);
        /*TODO: Get staking & farming*/
        const lockedValue = (Number(this.lockerUserBalance) || 0) + (Number(this.bonusBalance) || 0);
        const stakingValue = Number(this.stakingUserBalance) || 0;
        const farmingValue = Number(this.farmingUserBalance) || 0;
        const totalGMPD = lockedValue + stakingValue;

        this.lockedWithBonusValue = lockedValue.toFixed();
        this.totalUserGmpdBalance = String(totalGMPD);
        // const totalGMPD = (Number(this.lockerUserBalance) || 0) + stakingValue + farmingValue;
        this.totalUserLockedBalance = String(totalGMPD);
        this.stakingPercent = Math.round((stakingValue * 100) / Number(this.totalUserLockedBalance)) || 0;
        this.farmingPercent = Math.round((farmingValue * 100) / Number(this.totalUserLockedBalance)) || 0;
        // const blpBalancePercent = Math.round((userWalletBalance * 100) / Number(this.totalUserLockedBalance)) || 0;
        const blpLockedPercent =
            Math.round((Number(this.lockerUserBalance) * 100) / Number(this.totalUserLockedBalance)) || 0;
        this.lockedBalancePercent = Math.round(lockedValue * 100 / totalGMPD) || 0;
        this.chartData = {
            labels: [this.translate.instant('staking'), this.translate.instant('farming'), this.translate.instant('lockedIn')],
            datasets: [
                {
                    data: [this.stakingPercent, this.farmingPercent, this.lockedBalancePercent],
                    backgroundColor: ['#131BCB', '#b3b04f', '#31C2B9'],
                    borderWidth: 0,
                },
            ],
        };
    }

    public showWarningInfoAndLockTokenClick(): void {
        swal.fire({
            text: this.translate.instant('thePoolsWhileHaving1YearLock'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('yesIAmSure'),
            cancelButtonText: this.translate.instant('noIAmNotSure'),
        }).then(result => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.lockTokenClick();
            } else if (result.isDenied) {
                return;
            }
        });
    }

    @KYC_required
    lockTokenClick(): void {
        if (this.account) {
            this.callLockerComponent(true);
        } else {
            this.unlockWalletClick();
        }
    }

    @KYC_required
    unlockTokenClick(): void {
        if (this.account) {
            this.callLockerComponent(false);
        } else {
            this.unlockWalletClick();
        }
    }

    private callLockerComponent(IsLockedMode: boolean): void {
        const dialogRef = this.dialog.open(DlgLockerModalComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
        dialogRef.disableClose = true;
        dialogRef.componentInstance.IsLockedMode = IsLockedMode;

        dialogRef.afterClosed().subscribe(result => {
            this.updateUserData();
        });
    }

    unlockWalletClick(): void {
        const dialogRef = this.dialog.open(DlgUnlockWalletComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
        dialogRef.afterClosed().subscribe(result => {
            this.updateUserData();
        });
    }

    unlockWalletOldClick(): void {
        this.dialog.open(DlgUnlockWalletComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
    }

    openUnlockInfoDlg(): void {
        this.dialog.open(DlgLockerInfoComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
    }

    showTierInfoDlg(i: number): void {
        const dialogRef = this.dialog.open(DlgTierInfoComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
        const amount = this.isNFTLocked ? this.allTiers[i].gmpdAmount : this.allTiers[i].gmpdOnlyAmount;
        let leftToTier = Number(this.allTiers[i].blpAmount) - (Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance));
        if (leftToTier < 0) {
            leftToTier = 0;
        }
        dialogRef.componentInstance.tierId = i;
        dialogRef.componentInstance.tierProgress = this.getTierPercent(i, amount);
        dialogRef.componentInstance.tierAmount = amount;
        dialogRef.componentInstance.currentAmount = String(Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance));
        dialogRef.componentInstance.leftAmount = String(leftToTier);
    }

    @KYC_required
    async showNFTStakingDlg(): Promise<void> {
        let chainId = this.web3Service.chainIdNumber;
        if (!this.tokenID) {
            this.showErrorModal(this.translate.instant('tokenIdIsRequired'));
            return;
        }

        if (chainId != environment.eth.chainId) {
            this.showWarningHTMLModal(this.translate.instant('pleaseSwitchToEthereumToStakeNft'), this.translate.instant('confirm'), async () => {
                await this.swtichNetwork(environment.eth.chainId);
            });
            return;
        }

        let stakingStart = parseInt(await this.stakingService.getStakingStart());
        let stakingPeriod = parseInt(await this.stakingService.getStakingPeriod());
        let now = new Date().getTime() / 1000;

        if (stakingStart == 0 || now < stakingStart) {
            this.showWarningModal(this.translate.instant('NftStakingIsNotStartedYet'));
            return;
        }

        if (now > stakingStart + stakingPeriod) {
            this.showWarningModal(this.translate.instant('NftStakingIsAlreadyFinished'));
            return;
        }

        const dialogRef = this.dialog.open(DlgNftStakingInfoComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
        dialogRef.componentInstance.confirm = async () => {
            await this.stakeNFTToken();
        };
    }

    public get needToWaitTime(): number {
        const needTiWait = this.lastParticipationTime + this.lockupDuration - this.now;
        if (needTiWait > 0) {
            return needTiWait;
        } else {
            return 0;
        }
    }

    public getTierPercent(i: number, current: any): number {
      if (current && i <= this.nextTierIndex) {
        return (Number(this.lockerUserBalance) + Number(this.stakingUserBalance) + Number(this.bonusBalance)) * 100 / Number(current);
      }
      return 0;
    }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1) return this.translate.instant('none');
    if (tierIndex == 0) return this.translate.instant('bot');
    if (tierIndex == 1) return this.translate.instant('cyborg');
    if (tierIndex == 2) return this.translate.instant('android');
    if (tierIndex == 3) return this.translate.instant('humanoid');
    if (tierIndex == 4) return this.translate.instant('cryptoNinja');
    return this.translate.instant('none');
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }
}
