import { Component, OnInit } from '@angular/core';
import { ClaimingDealDTO, ClaimingDealServiceProxy, DealDTO } from '../../service-proxies/service-proxies';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ComponentBase } from '../../shared/component-base';
import { Router } from '@angular/router';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { forkJoin, from, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { BigNumber } from 'bignumber.js';
import {TranslateService} from "@ngx-translate/core";

export interface ClaimingDealItemView extends ClaimingDealDTO {
    isLocked?: boolean;
}

@Component({
    templateUrl: './claiming.component.html',
    styleUrls: ['./claiming.component.scss'],
})
export class ClaimingComponent extends ComponentBase implements OnInit {
    private account: string;

    constructor(
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private claimingDealService: ClaimingDealServiceProxy,
        private router: Router,
        public translate: TranslateService
    ) {
        super();
    }
    public allClaimingDeals: Array<ClaimingDealItemView> = [];
    public allClaimingDeals$: Observable<ClaimingDealItemView[]>;
    waiting: boolean = false;

    async ngOnInit() {
        await this.web3Service.initWeb3();

        window.dispatchEvent(new Event('resize'));

        console.log('linkedWallet');
        this.initialize(this.userSessionProvider.linkedWallet);

        this.allClaimingDeals$ = this.claimingDealService
            .getByChainID(this.account, this.web3Service.chainIdNumber)
            .pipe(this.getClaimingDeals());

        this.claimingDealService.getByChainID(this.account, this.web3Service.chainIdNumber).subscribe(
            result => {
                this.allClaimingDeals = result;
                console.log(this.allClaimingDeals);
                this.waiting = false;
            },
            error => {
                this.processServiceError(error);
            },
        );
    }

    private initialize(username: string): void {
        console.log('initialize - ' + username);
        if (this.account != username) {
            this.account = username;
        }
    }

    processServiceError(error: any) {
        if (error.status == 401) {
            console.error('401');
            this.userSessionProvider.finishAuth();
            this.navigateToLogin();
        } else {
            console.error(error);
            this.showErrorModal(JSON.parse(error.response).message);
        }
    }

    navigateToLogin(): void {
        this.router.navigate(['/login']);
    }

    public navigateToViewDeal(dealAddress: string) {
        this.router.navigate(['/deal-detail'], { queryParams: { address: dealAddress } });
    }

    getClaimedPercent(claimedAmount: string | undefined, totalTokens: string | undefined): number {
        if (totalTokens && claimedAmount && totalTokens != '0')
            return new BigNumber(claimedAmount).div(new BigNumber(totalTokens)).multipliedBy(100).toNumber();
        return 0;
    }

    private getClaimingDeals(): OperatorFunction<ClaimingDealItemView[], ClaimingDealItemView[]> {
        return pipe(
            concatMap((claimingDeals: ClaimingDealItemView[]) => {
                return claimingDeals.length
                    ? forkJoin(claimingDeals.map(claimingDeal => this.checkAddress(claimingDeal)))
                    : of([]);
            }),
        );
    }

    private checkAddress(claimingDeal: ClaimingDealItemView): Observable<ClaimingDealItemView> {
        return from(this.web3Service.isPausedClaimingDeal(claimingDeal.address)).pipe(
            map(claimingInfo => {
                claimingDeal.isLocked = !!claimingInfo;
                return claimingDeal;
            }),
        );
    }

    async claimTokensClaimingDeal(
        claimingDealAddress: string,
        index: number,
        emissionAddress: string,
        availableAmount: string,
        merkleProofs: string[],
    ): Promise<void> {
        this.waiting = true;
        console.log(
            'Claim: \n ClaimingDeal:' +
                claimingDealAddress +
                '\n index: ' +
                index +
                '\n emission:' +
                emissionAddress +
                '\n amount: ' +
                availableAmount +
                '\n merkleProofs: ' +
                merkleProofs,
        );

        const contractEventsSource = this.web3Service.claimTokensClaimingDeal(
            this.account,
            claimingDealAddress,
            index,
            emissionAddress,
            availableAmount,
            merkleProofs,
        );

        try {
            let response = await contractEventsSource.receipt$.toPromise();
            console.log(response);
            //dialogRef.close();
            this.showInfoModal(this.translate.instant('confirmedTransaction'));
        } catch (err) {
            //dialogRef.close();
            this.showInfoModal(this.translate.instant('transactionRejected'));
            console.info('catch');
            console.error(err);
        }
        this.waiting = false;
    }
}
