import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgTokenPipe',
})
export class ImgTokenPipe implements PipeTransform {
    transform(tokenSymbol: string): string {
        switch (tokenSymbol) {
            case 'BUSD':
                return '/assets/images/currency/busd.svg';
            case 'BNB':
                return '/assets/images/currency/bnb.svg';
            case 'USDT':
                return '/assets/images/currency/usdt.svg';
            case 'FLOPPA':
                return '/assets/images/currency/usdt.svg';
            case 'USDC':
                return '/assets/images/currency/usdc.svg';
            default:
                return '/assets/images/no-image.svg';
        }
    }
}
