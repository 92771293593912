import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import { AccountServiceProxy, SignUpDTO } from '../../service-proxies/service-proxies';
import { RecaptchaComponent } from 'ng-recaptcha';
import { ActivatedRoute } from '@angular/router';

@Component({
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
})
export class SignUpPageComponent extends ComponentBase implements OnInit {
    public referrer: string | undefined;

    constructor(private _alertSrv: AlertService, private accountService: AccountServiceProxy, private readonly activatedRoute: ActivatedRoute) {
        super();
        const refValue = this.activatedRoute.snapshot.queryParams['referrer'];
        this.referrer = refValue;
    }
    @ViewChild('recaptchaSignUp') recaptchaSignUpComponent: RecaptchaComponent;
    public captchaResponseSignUp: string;

    waiting: boolean = false;
    account: string = '';
    signUpEmail: string = '';
    step: number = 1;

    async ngOnInit() {}

    async signUpClick() {
        this.waiting = true;

        var data: SignUpDTO = new SignUpDTO();
        data.userName = this.signUpEmail;
        data.captcha = this.captchaResponseSignUp;
        data.referrer = this.referrer;
        this.accountService.signUp(data).subscribe(
            result => {
                this.step = 2;
                this.waiting = false;
                this.recaptchaSignUpComponent.reset();
            },
            error => {
                this.waiting = false;
                this.recaptchaSignUpComponent.reset();
                this._alertSrv.show(JSON.parse(error.response).message, 'error');
                //this.showErrorModal(JSON.parse(error.response).message);
            },
        );
    }
}
