import { Input, OnDestroy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StakingPoolDTO } from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { BigNumber } from 'bignumber.js';
import { EventBus } from '../../shared/event-bus';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { DlgLockerPoolComponent } from '../dlg-locker-pool';
import { environment } from '../../environments/environment';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'pool-viewer',
    templateUrl: './pool-viewer.component.html',
    styleUrls: ['./pool-viewer.component.scss'],
})
export class PoolViewerComponent extends ComponentBase implements OnInit, OnDestroy {
    constructor(
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private _router: Router,
        private userSessionProvider: UserSessionProvider,
        private _dialog: MatDialog,
        public translate: TranslateService
    ) {
        super();
    }

    @Input() item: StakingPoolDTO;

    waiting: boolean;
    account: string;
    web3ChainId: number;
    step: number = 1;
    tokenApproved: boolean;
    allStakeAmount: string = '0';
    currentStakeAmount: string = '0';
    currentPendingReward: string = '0';
    penaltyBP: number = 0;
    staked: boolean;
    WETHaddress: string;
    apy: number;
    isFarming: boolean;

    async ngOnInit() {
        if (this.item.stakingToken != this.item.poolToken) {
            this.isFarming = true;
        }
        await this.web3Service.initWeb3();
        console.log(this.item.poolTokenAmount);
        this.item.poolTokenAmount;
        if (this.userSessionProvider.linkedWallet) {
            this.eventLogin(this.userSessionProvider.linkedWallet);
        }
        this.web3ChainId = this.web3Service.chainIdNumber;

        await this.getUserStake();
        await this.getWETHaddress();
        await this.getAPY();

        if (this.web3Service.web3) {
            this.updateContractData();
        }
    }

    ngOnDestroy(): void {}

    eventLogin(username: string): void {
        console.log('eventLogin');
        console.log(username);
        if (this.account != username) {
            this.account = username;
        }
    }

    async updateContractData() {
        await this.getPenaltyBP();
        this.currentPendingReward = await this.web3Service.getPoolPendingReward(this.item.poolAddress, this.account);
    }

    async getPenaltyBP(): Promise<void> {
        this.web3Service.getPoolPenaltyBP(this.item.startTime, this.item.poolAddress).then(penalty => {
            if (penalty) {
                this.penaltyBP = penalty / 100;
            }
        });
    }

    async getUserStake() {
        this.web3Service.getPoolUserInfo(this.account, this.item.poolAddress).then(result => {
            console.log(result);
            this.currentStakeAmount = result[0];
            if (parseInt(this.currentStakeAmount) > 0) {
                this.staked = true;
            }
        });
    }

    async getWETHaddress() {
        this.WETHaddress = await this.web3Service.WETH();
    }

    async getAPY() {
      console.log('this.WETHaddress', this.WETHaddress);
      console.log('this.item.stakingToken', this.item.stakingToken);

        let stakingAmountsOut = parseFloat(
            await this.web3Service.getAmountsOut(1, [this.item.stakingToken, this.WETHaddress]),
        );
        let rewardAmountsOut = parseFloat(
            await this.web3Service.getAmountsOut(1, [this.item.poolToken, this.WETHaddress]),
        );
        let rewardsPerSec = new BigNumber(await this.web3Service.rewardPerSec(this.item.poolAddress))
            .shiftedBy(-18)
            .toNumber();
        let allStakedAmount = new BigNumber(await this.web3Service.allStakedAmount(this.item.poolAddress))
            .shiftedBy(-18)
            .toNumber();

        this.allStakeAmount = new Intl.NumberFormat('en-US', {
            //@ts-ignore
            notation: 'compact',
            compactDisplay: 'short'
        }).format(allStakedAmount);

        console.log("getAPY: " + this.item.name + " stakingPrice: " + stakingAmountsOut + " rewardPrice: " + rewardAmountsOut + " rewardsPerSec: " + rewardsPerSec + " allStakedAmount: " + allStakedAmount);
        let apy = ((rewardAmountsOut * rewardsPerSec * 86400 * 365) / (stakingAmountsOut * allStakedAmount)) * 100;
        this.apy = apy;
        let currentTime = Math.floor(Date.now() / 1000);
        if (Number.isNaN(apy) || !Number.isFinite(apy) || currentTime > this.item.finishTime) {
            this.apy = 0;
        }
        console.log('APY: ' + this.apy);
    }

    public get getProgressPercent(): number {
        if (this.item == null) return 0;
        let currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > this.item.startTime) {
            if (currentTime > this.item.finishTime) {
                return 100;
            }
            return new BigNumber(currentTime - this.item.startTime)
                .div(this.item.finishTime - this.item.startTime)
                .multipliedBy(100)
                .toNumber();
        }
        return 0;
    }

    public get getCurrentPoolTokenAmount(): number {
        if (this.item == null) return 0;

        // @ts-ignore
        const extensionInfo = environment.extendedPools?.find(x => x.address.toLowerCase() ==
            this.item.poolAddress.toLowerCase());

        let bnPoolAmount = new BigNumber(this.item.poolTokenAmount).shiftedBy(-this.item.poolTokenDecimals).toNumber();
        let startTime = this.item.startTime;
        let distributed = 0;

        if (extensionInfo) {
            distributed = extensionInfo.previousAmount;
            bnPoolAmount -= distributed;
            startTime = extensionInfo.previousFinishTime;
        }

        let currentTime = Math.floor(Date.now() / 1000);
        if (currentTime > this.item.startTime) {
            if (currentTime > this.item.finishTime) {
                return bnPoolAmount;
            }
            return (((currentTime - startTime) / (this.item.finishTime - startTime)) * bnPoolAmount) + distributed;
        }

        return 0;
    }

    async depositDialog() {
        // check whitelist
        if (this.item.hasWhitelisting) {
            let isWhitelisted = await this.web3Service.isWhitelisted(this.account, this.item.poolAddress);
            if (!isWhitelisted) {
                this.showErrorModal(this.translate.instant('youAreNotWhitelistedForThisPool'));
                return;
            }
        }

        const dialogRef = this._dialog.open(DlgLockerPoolComponent, {
            backdropClass: 'dlg-unlock-wallet-backdrop',
            panelClass: ['dlg-unlock-wallet-panel'],
            scrollStrategy: new NoopScrollStrategy(),
        });

        dialogRef.disableClose = true;
        dialogRef.componentInstance.item = this.item;
        dialogRef.componentInstance.account = this.account;
        dialogRef.componentInstance.currentStakeAmount = this.currentStakeAmount;
        dialogRef.componentInstance.isDepositMode = true;

        dialogRef.afterClosed().subscribe(result => {
            this.getUserStake();
        });
    }

    async withdrawDialog() {
        const dialogRef = this._dialog.open(DlgLockerPoolComponent, {
            backdropClass: 'dlg-unlock-wallet-backdrop',
            panelClass: ['dlg-unlock-wallet-panel'],
            scrollStrategy: new NoopScrollStrategy(),
        });

        dialogRef.disableClose = true;
        dialogRef.componentInstance.item = this.item;
        dialogRef.componentInstance.account = this.account;
        dialogRef.componentInstance.currentStakeAmount = this.currentStakeAmount;
        dialogRef.componentInstance.isDepositMode = false;

        dialogRef.afterClosed().subscribe(result => {
            this.getUserStake();
        });
    }

    openPoolDetail(poolAddress: string) {
      this._router.navigate(['/pool-detail'], { queryParams: { address: poolAddress } });
    }
}
