import { BehaviorSubject } from 'rxjs';
import { DealDTO } from 'src/service-proxies/service-proxies';

export interface DealConfigInterface {
    readonly results: BehaviorSubject<DealDTO[]>;
    readonly loading: BehaviorSubject<boolean>;
    readonly nothingFound: BehaviorSubject<boolean>;
}

export class DealConfig implements DealConfigInterface {
    public results = new BehaviorSubject<DealDTO[]>([]);
    public loading = new BehaviorSubject<boolean>(this.initialLoading);
    public nothingFound = new BehaviorSubject<boolean>(false);

    constructor(private readonly initialLoading: boolean) {}
}
