import { environment } from 'src/environments/environment';

export interface MenuItem {
    readonly label: string;
    readonly translationKey: string;
    readonly routePath: string[] | string;
    readonly supportedChains: number[];
    readonly icon?: string;
    readonly childItems?: MenuItem[];
    readonly isExternal?: boolean;
}

export const menu: MenuItem[] = [
    {
        icon: 'icomoon-dashboard',
        label: 'Dashboard',
        translationKey: 'dashboard',
        routePath: '/',
        supportedChains: [56, 97],
    },
    {
        icon: 'icomoon-address-book',
        label: 'Address Book',
        translationKey: 'addressBook',
        routePath: '/address-book',
        supportedChains: [56, 97],
    },
    {
        icon: "icomoon-users",
        label: "Referral",
        translationKey: 'referral',
        routePath: "/referral",
        supportedChains: [],
      },
    {
        icon: 'icomoon-kys',
        label: 'KYC',
        translationKey: 'kyc',
        routePath: '/kyc',
        supportedChains: [],
    },
    {
        icon: 'icomoon-market',
        label: 'Deals',
        translationKey: 'deals',
        routePath: ['/deal-detail', '/deals/0', '/deals/1', '/deals/2'],
        supportedChains: [56, 97],
        childItems: [
            {
                label: 'VC Deals',
                translationKey: 'vcDeals',
                routePath: '/deals/0',
                supportedChains: [56, 97],
            },
            {
                label: 'IDO Deals',
                translationKey: 'idoDeals',
                routePath: '/deals/1',
                supportedChains: [56, 97],
            },
            {
                label: 'INO Deals',
                translationKey: 'inoDeals',
                routePath: '/deals/2',
                supportedChains: [56, 97],
            },
        ],
    },
    // {
    //   icon: "icomoon-market",
    //   label: "NFTs",
    //   routePath: ["/nfts", "/nfts/0","/nfts/1"],
    //   supportedChains: [56, 97],
    //   childItems: [{
    //     label: "Redeem",
    //     routePath: "/nfts/0",
    //     supportedChains: [56, 97],
    //   },
    //   {
    //     label: "Owned NFTs",
    //     routePath: "/nfts/1",
    //     supportedChains: [56, 97],
    //   },
    //   ]
    // },
    {
        label: 'Pools',
        translationKey: 'pools',
        icon: 'icomoon-pools',
        routePath: ['/staking', '/farming', '/pool-detail'],
        supportedChains: [56, 97],
        childItems: [
            {
                label: 'Staking Pools',
                translationKey: 'stakingPools',
                routePath: '/staking',
                supportedChains: [56, 97],
            },
            {
                label: 'Farming Pools',
                translationKey: 'farmingPools',
                routePath: '/farming',
                supportedChains: [56, 97],
            },
        ],
    },
    {
        label: 'Claim NFT',
        translationKey: 'claimNft',
        icon: 'icomoon-market',
        routePath: '/claiming-nft',
        supportedChains: [56, 97],
    },
    {
        label: 'Bridge NFT',
        translationKey: 'bridgeNft',
        icon: 'icomoon-market',
        routePath: '/wanchain',
        supportedChains: [1, 5, 56, 97],
    },
    {
        label: 'Swap & Bridge',
        translationKey: 'swapBridge',
        icon: 'icomoon-market',
        routePath: '/swap-bridge',
        supportedChains: [],
    },
    {
        label: 'Portfolio',
        translationKey: 'portfolio',
        icon: 'icomoon-portfolio',
        routePath: '/portfolio',
        supportedChains: [56, 97],
    },
    {
      icon: 'icomoon-info',
      label: 'Voting',
      translationKey: 'voting',
      routePath: '/voting',
      supportedChains: [56, 97],
    },
    {
        label: 'Help Center',
        translationKey: 'helpCenter',
        icon: 'icomoon-help',
        isExternal: true,
        routePath: 'https://help.gamespad.io/',
        supportedChains: [],
    },
];
