import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BLPDealDetailComponent } from "./blp-deal-detail/blp-deal-detail.component";
import { ConfirmEmailPageComponent } from "./confirm-email/confirm-email.component";
import { DealDetailComponent } from "./deal-detail/deal-detail.component";
import { DealsComponent } from "./deals/deals.component";
import { KYCPageComponent } from "./KYC/kyc.component";
import { LoginPageComponent } from "./login/login.component";
import { MainPageComponent } from "./main/main.component";
import { PortfolioComponent } from "./portfolio/portfolio.component";
import { SignUpPageComponent } from "./sign-up/sign-up.component";
import { VestingComponent } from "./vesting/vesting.component";
import { StakingComponent } from "./staking/staking.component";
import { FarmingComponent } from "./farming/farming.component";
import { PoolDetailComponent } from "./pool-detail/pool-detail.component";
import { CreatePoolComponent } from "./create-pool/create-pool.component";
import { EditPoolComponent } from "./edit-pool/edit-pool.component";
import { ClaimingComponent } from "./claiming/claiming.component";
import { ClaimingDealDetailComponent } from "./claiming-deal-detail/claiming-deal-detail.component";
import { CreateClaimingDealComponent } from "./create-claiming-deal/create-claiming-deal.component";
import { EditClaimingDealComponent } from "./edit-claiming-deal/edit-claiming-deal.component";
import { AddressBookComponent } from "./address-book/address-book.component";
import { AuthGuard } from "src/internal/authorize.guard";
import { ClaimingNFTComponent } from "./claiming-nft/claiming-nft.component";
import { UserPollsComponent } from "./user-polls/user-polls.component";
import { WanchainBridgeComponent } from "./wanchain-bridge/wanchain-bridge.component";
import { SwingWidgetComponent } from "./swing-widget/swing.widget.component";
import { KycGuard } from "../internal/kyc.guard";
import { ReferralComponent } from "./referral/referral.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: MainPageComponent,
        canActivate: [],
      },
      {
        path: "deals/:dealTypeFilter",
        component: DealsComponent,
        canActivate: [],
      },
      {
        path: "portfolio",
        component: PortfolioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "deal-detail",
        component: DealDetailComponent,
        canActivate: [],
      },
      {
        path: "blp-deal",
        component: BLPDealDetailComponent,
        canActivate: [],
      },
      {
        path: "vesting/:address",
        component: VestingComponent,
        canActivate: [],
      },
      {
        path: "signup",
        component: SignUpPageComponent,
      },
      {
        path: "login",
        component: LoginPageComponent,
      },
      {
        path: "confirm-email",
        component: ConfirmEmailPageComponent,
      },
      {
        path: "kyc",
        component: KYCPageComponent,
        canActivate: [KycGuard],
      },
      {
        path: 'referral',
        component: ReferralComponent,
        canActivate: [AuthGuard]
      },
      {
        path: "staking",
        component: StakingComponent,
        canActivate: [],
      },
      {
        path: "farming",
        component: FarmingComponent,
        canActivate: [],
      },
      {
        path: "pool-detail",
        component: PoolDetailComponent,
        canActivate: [],
      },
      {
        path: "create-pool",
        component: CreatePoolComponent,
      },
      {
        path: "edit-pool",
        component: EditPoolComponent,
        canActivate: [],
      },
      {
        path: "claiming",
        component: ClaimingComponent,
      },
      {
        path: "claiming-deal-detail",
        component: ClaimingDealDetailComponent,
      },
      {
        path: "edit-claiming-deal",
        component: EditClaimingDealComponent,
      },
      {
        path: "create-claiming-deal",
        component: CreateClaimingDealComponent,
      },
      {
        path: "address-book",
        component: AddressBookComponent,
        canActivate: [],
      },
      {
        path: "claiming-nft",
        component: ClaimingNFTComponent,
        canActivate: [],
      },
      {
        path: "voting",
        component: UserPollsComponent,
        canActivate: [AuthGuard],
      },
      { path: "wanchain", component: WanchainBridgeComponent },
      { path: "swap-bridge", component: SwingWidgetComponent },
      { path: "**", redirectTo: "" },
      //{
      //  path: 'transfer',
      //  component: AppTransferComponent
      //}
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
