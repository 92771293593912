export default [
    {
        name: 'Ethereum',
        chainId: 1,
        chainIdHex: '0x1',
        img: '/assets/svg/eth.svg',
        imgBlack: '/assets/svg/eth_black.svg',
        imgModal: '/assets/svg/eth_modal.svg',
        networkParams: {
            chainId: '0x1',
            chainName: 'Etherium',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'eth',
                decimals: 18,
            },
            rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/eth/mainnet/'],
            blockExplorerUrls: ['https://etherscan.io'],
        },
    },
    {
        name: 'ETH goerli',
        chainId: 5,
        chainIdHex: '0x5',
        img: '/assets/svg/eth.svg',
        imgBlack: '/assets/svg/eth_black.svg',
        imgModal: '/assets/svg/eth_modal.svg',
        networkParams: {
            chainId: '0x5',
            chainName: 'ETH goerli',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'eth',
                decimals: 18,
            },
            rpcUrls: ['https://eth-goerli.public.blastapi.io/'],
            blockExplorerUrls: ['https://goerli.etherscan.io'],
        },
    },
    {
        name: 'Kovan',
        chainId: 42,
        chainIdHex: '0x2a',
        img: '/assets/svg/eth.svg',
        imgBlack: '/assets/svg/eth_black.svg',
        imgModal: '/assets/svg/eth_modal.svg',
        networkParams: {
            chainId: '0x2a',
            chainName: 'Kovan Testnet',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'eth',
                decimals: 18,
            },
            rpcUrls: ['https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161/'],
            blockExplorerUrls: ['https://kovan.etherscan.io'],
        },
    },
    {
        name: 'BNB chain',
        chainId: 56,
        chainIdHex: '0x38',
        img: '/assets/svg/bnb.svg',
        imgBlack: '/assets/svg/bnb.svg',
        imgModal: '/assets/svg/bsc_modal.svg',
        networkParams: {
            chainId: '0x38',
            chainName: 'BNB chain',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
            },
            rpcUrls: [
                'https://bsc-dataseed1.ninicoin.io/',
                'https://bsc-dataseed1.defibit.io/',
                'https://bsc-dataseed.binance.org/',
            ],
            blockExplorerUrls: ['https://bscscan.com/'],
        },
    },
    {
        name: 'BNB test chain',
        chainId: 97,
        chainIdHex: '0x61',
        img: '/assets/svg/bsc.svg',
        imgBlack: '/assets/svg/bsc.svg',
        imgModal: '/assets/svg/bsc_modal.svg',
        networkParams: {
            chainId: '0x61',
            chainName: 'BSC test chain',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
            },
            rpcUrls: [
                'https://data-seed-prebsc-1-s1.binance.org:8545/',
                'https://data-seed-prebsc-2-s1.binance.org:8545/',
                'https://data-seed-prebsc-1-s2.binance.org:8545/',
            ],
            blockExplorerUrls: ['https://testnet.bscscan.com/'],
        },
    },
    {
        name: 'Heco Testnet',
        chainId: 256,
        chainIdHex: '0x100',
        img: '/assets/svg/heco.svg',
        imgBlack: '/assets/svg/heco.svg',
        imgModal: '/assets/svg/heco.svg',
        networkParams: {
            chainId: '0x100',
            chainName: 'Heco Testnet',
            nativeCurrency: {
                name: 'HT',
                symbol: 'HT',
                decimals: 18,
            },
            rpcUrls: ['https://http-testnet.hecochain.com/'],
            blockExplorerUrls: ['https://testnet.hecoinfo.com/'],
        },
    },
    {
        name: 'Polygon',
        chainId: 137,
        chainIdHex: '0x89',
        img: '/assets/images/network/polygon.svg',
        imgBlack: '/assets/images/network/polygon.svg',
        imgModal: '/assets/images/network/polygon.svg',
        networkParams: {
            chainId: '0x89',
            chainName: 'Polygon',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
            },
            rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mainnet/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
        },
    },
    {
        name: 'Polygon Test',
        chainId: 80001,
        chainIdHex: '0x13881',
        img: '/assets/images/network/polygon.svg',
        imgBlack: '/assets/images/network/polygon.svg',
        imgModal: '/assets/images/network/polygon.svg',
        networkParams: {
            chainId: '0x13881',
            chainName: 'Polygon Test',
            nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
            },
            rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mumbai/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
        },
    },
    {
        name: 'Solana',
        chainId: -100,
        chainIdHex: '0x13881',
        img: '/assets/images/network/solana.svg',
        imgBlack: '/assets/images/network/solana.svg',
        imgModal: '/assets/images/network/solana.svg',
        networkParams: {
            chainId: '0x13881',
            chainName: 'Solana',
            nativeCurrency: {
                name: 'SOL',
                symbol: 'SOL',
                decimals: 18,
            },
            rpcUrls: ['https://speedy-nodes-nyc.moralis.io/fde6998ca86206597a0acf43/polygon/mumbai/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
        },
    },
    {
        name: 'Avalanche',
        chainId: 43114,
        chainIdHex: '0xa86a',
        img: '/assets/images/network/avalanche.svg',
        imgBlack: '/assets/images/network/avalanche.svg',
        imgModal: '/assets/images/network/avalanche.svg',
        networkParams: {
            chainId: '0xa86a',
            chainName: 'Avalanche',
            nativeCurrency: {
                name: 'AVAX',
                symbol: 'AVAX',
                decimals: 18,
            },
            rpcUrls: ['https://api.avax.network/ext/bc/C/rpc/'],
            blockExplorerUrls: ['https://snowtrace.io/'],
        },
    }
];
