import { Injectable, Injector } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ExtraModuleInjector {
    private static injector: any;

    public static get<T>(token: any): T {
        return ExtraModuleInjector.injector.get(token);
    }

    constructor(public injector: Injector) {
        ExtraModuleInjector.injector = injector;
    }
}
