import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import BigNumber from 'bignumber.js';
import { DlgSwitchNetworkComponent } from '../dlg-switch-network';

import { throwError, merge } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';

import { environment } from '../../environments/environment';
import networks from '../networks.data';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DealDTO,
    DealRegistrationDTO,
    DealServiceProxy,
    UserDTO,
    UsersServiceProxy,
} from '../../service-proxies/service-proxies';
import { NetworkNamePipe } from '../../shared/pipes/networkName.pipe';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './vesting.component.html',
    styleUrls: ['./vesting.component.scss'],
})
export class VestingComponent extends ComponentBase implements OnInit, OnDestroy {
    constructor(
        private _dialog: MatDialog,
        private _alertSrv: AlertService,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private route: ActivatedRoute,
        private readonly router: Router,
        public translate: TranslateService
    ) {
        super();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.now = Math.floor(Date.now() / 1000);

        this.updateTimeTimerId = setInterval(() => {
            this.now = Math.floor(Date.now() / 1000);
        }, 1000);
    }

    vestingAddress: string;
    waiting: boolean = false;
    account: string = '';

    now: number;
    tgeTime: number;
    vestingTGEPercent: number = 0;
    vestingStart: number;
    vestingInterval: number;
    vestingDuration: number;

    rewardTokenSymbol: string = 'GMPD';
    rewardDecimals: number = 18;

    updateTimeTimerId: NodeJS.Timeout;
    updateTimerId: NodeJS.Timeout;
    updateUserTimerId: NodeJS.Timeout;

    usersTgeBalance: number = 0;
    usersTgeReleased: number = 0;
    usersBalance: number = 0;
    usersReleased: number = 0;

    usersReleasableAmount: number = 0;

    async ngOnInit() {
        this.vestingAddress = this.route.snapshot.params.address;

        this.route.params.subscribe((routeParams: any) => {
            this.vestingAddress = routeParams.address;
            console.log(`vestingAddress: ${this.vestingAddress}`);
        });

        if (this.userSessionProvider.linkedWallet) {
            this.eventLogin(this.userSessionProvider.linkedWallet);
        }

        await this.web3Service.initWeb3();
        if (this.web3Service.web3) {
            this.updateVestingContractData();

            this.updateVestingContractData();
            this.updateTimerId = setInterval(() => {
                this.updateVestingContractData();
            }, this.expectedBlockTime);
        }

        this.eventBus.loginEvent.subscribe(result => {
            console.log('loginEvent subscription:' + result);
            this.eventLogin(result);
        });

        this.eventBus.logoutEvent.subscribe(result => {
            console.log('logoutEvent subscription:' + result);
            this.eventLogout();
        });
    }

    async eventLogin(username: string) {
        console.log('eventLogin');
        console.log(username);
        if (this.account != username) {
            this.account = username;

            this.updateUserData();

            this.updateUserTimerId = setInterval(() => {
                this.updateUserData();
            }, this.expectedBlockTime);
        }
    }

    eventLogout(): void {
        console.log('signOut');
        this.account = '';

        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
    }

    async claimVesingClick() {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.TGERelease(this.vestingAddress, this.account);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.updateUserData();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }
        this.waiting = false;
    }

    async releaseTGEClick() {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.TGEReleaseTGE(this.vestingAddress, this.account);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.updateUserData();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }
        this.waiting = false;
    }

    async updateVestingContractData() {
        console.log('updateVestingContractData');
        this.web3Service.getTGEVestingTgePercent(this.vestingAddress).then(resp => {
            if (resp) {
                this.vestingTGEPercent = parseFloat(resp);
            }
        });

        this.web3Service.getTGEVestingTgeTime(this.vestingAddress).then(resp => {
            this.tgeTime = parseInt(resp);
        });

        this.web3Service.getTGEVestingStart(this.vestingAddress).then(resp => {
            this.vestingStart = parseInt(resp);
        });

        this.web3Service.getTGEVestingInterval(this.vestingAddress).then(resp => {
            this.vestingInterval = parseInt(resp);
        });

        this.web3Service.getTGEVestingDuration(this.vestingAddress).then(resp => {
            this.vestingDuration = parseInt(resp);
        });
    }

    async updateUserData() {
        console.log('updateUserData');

        this.web3Service.getTGEVestingForUser(this.vestingAddress, this.account).then(userInfo => {
            console.log('userInfo', userInfo);
            if (!userInfo) return;

            this.usersTgeBalance = this.toNumberFromWei(userInfo.tgeBalance, this.rewardDecimals);
            this.usersTgeReleased = this.toNumberFromWei(userInfo.tgeReleased, this.rewardDecimals);
            this.usersBalance = this.toNumberFromWei(userInfo.balance, this.rewardDecimals);
            this.usersReleased = this.toNumberFromWei(userInfo.released, this.rewardDecimals);
        });

        this.web3Service.getTGEReleasableAmount(this.vestingAddress, this.account).then(resp => {
            if (resp) {
                this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
            } else {
                this.usersReleasableAmount = 0;
            }
        });
    }

    async ngOnDestroy() {
        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
        if (this.updateTimeTimerId) {
            clearInterval(this.updateTimeTimerId);
        }
        if (this.updateTimerId) {
            clearInterval(this.updateTimerId);
        }
    }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }
}
