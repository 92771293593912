import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { Web3Service } from 'src/shared/web3-service';
import { ComponentBase } from '../../shared/component-base';
import { environment } from 'src/environments/environment';
import { NetworkSwitcherService } from 'src/shared/networkSwitcher.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './wanchain-bridge.component.html',
    styleUrls: ['./wanchain-bridge.component.scss'],
})
export class WanchainBridgeComponent extends ComponentBase implements OnInit {
    public userNFTsData: { user: string; NFTsInfo: number[] };
    public availableToClaimNFT = false;
    public nftTokenID: number;
    public binanceRegularNFTContract: string;
    public selectedNFT: number = null;
    public nftType: number;
    public selectedNFTType: string;
    public checking: boolean;
    public completed: boolean;
    public fromNetwork: number = this.web3Service.chainIdNumber == 56 || this.web3Service.chainIdNumber == 97 ? 1 : 0;
    public toNetwork: number = this.web3Service.chainIdNumber == 56 || this.web3Service.chainIdNumber == 97 ? 0 : 1;
    public isVerifiedOwner: boolean = false;

    constructor(
      private readonly web3Service: Web3Service,
      private readonly networkSwitcher: NetworkSwitcherService,
      public translate: TranslateService
    ) {
      super();
      this.verifyNetworkForBridge();
    }

    public verifyNetworkForBridge() {
        if (environment.production) {
            if (this.web3Service.chainIdNumber != 56 && this.web3Service.chainIdNumber != 1) {
                this.showWarningModal(this.translate.instant('toUseTheBridgePleaseChangeTheNetworkToBsc'));
                this.networkSwitcher.switchToNetwork(56);
            }
        } else {
            if (this.web3Service.chainIdNumber != 97 && this.web3Service.chainIdNumber != 5) {
                this.showWarningModal(this.translate.instant('toUseTheBridgePleaseChangeTheNetworkToBscTest'));
                this.networkSwitcher.switchToNetwork(97);
            }
        }
    }

    // @KYC_required
    public checkNFT(): void {
        this.checking = true;
        this.web3Service
            .getUsers_GMDP_NFT_Info(this.nftTokenID)
            .pipe(
                first(),
                catchError((e, caught) => {
                    console.log('e', e);
                    console.log('caough', caught);
                    this.showWarningModal(e);
                    return of(undefined);
                }),
                finalize(() => {
                    this.checking = false;
                }),
            )
            .subscribe(isOwner => {
                console.log('RESULT', isOwner);
                if (!isOwner) {
                    this.showWarningModal(this.translate.instant('youArentOwnerOfThisNftToken'));
                    return;
                }
                this.isVerifiedOwner = true;
                this.availableToClaimNFT = true;
                this.selectedNFT = this.nftTokenID;
                this.selectedNFTChanged(this.selectedNFT);
            });
    }

    public async selectedNFTChanged(value: any): Promise<void> {
        this.nftType = await this.web3Service.getNFTTYpe(undefined, value);
        switch (Number(this.nftType)) {
            case 0:
                this.selectedNFTType = 'Bot';
                break;
            case 1:
                this.selectedNFTType = 'Cyborg';
                break;
            case 2:
                this.selectedNFTType = 'Android';
                break;
            case 3:
                this.selectedNFTType = 'Humanoid';
                break;
            case 4:
                this.selectedNFTType = 'Crypto Ninja';
                break;
        }
    }

    ngOnInit(): void {
        this.binanceRegularNFTContract = this.web3Service.binanceRegularNFTContract;
    }

    public async depositToEth() {
        this.checking = true;
        await this.web3Service
            .crossFromBscToETH(this.nftTokenID, environment.production ? 56 : 97)
            .then(() => this.showSuccessModal(this.translate.instant('crossFromBscToEthSuccess')))
            .catch(e => {
                this.showWarningModal(e.message);
            })
            .finally(() => (this.checking = false));
    }

    public async withdrawalFromEth() {
        this.checking = true;
        await this.web3Service
            .crossFromEthToBsc(this.nftTokenID, environment.production ? 1 : 5)
            .then(() => this.showSuccessModal(this.translate.instant('crossFromBscToEthSuccess')))
            .catch(e => {
                this.showWarningModal(e.message);
            })
            .finally(() => (this.checking = false));
    }

    public swapDirections() {
        let temp = this.toNetwork;
        this.toNetwork = this.fromNetwork;
        this.fromNetwork = temp;
        this.networkSwitcher.switchToNetwork(
            this.fromNetwork == 1 ? (environment.production ? 56 : 97) : environment.production ? 1 : 5,
        );
    }
}
