<div class="modal-header">
  <i class="icon icomoon-address-book"></i>
  <h5 class="modal-title"
      *ngIf="actionAddressMode=='add'">{{'addToAddressBook' | translate}}</h5>
  <h5 class="modal-title"
      *ngIf="actionAddressMode=='edit'">{{'changeAddress' | translate}}</h5>
  <h5 class="modal-title"
      *ngIf="actionAddressMode=='remove'">{{'deleteFromAddressBook' | translate}}</h5>
  <div type="button"
       class="btn-close"
       [mat-dialog-close]="true"></div>
</div>

<div class="modal-body">

  <mat-form-field appearance="fill"
                  hideRequiredMarker
                  class="icon-form-field mb-4"
                  *ngIf="actionAddressMode!='remove'">
    <img [src]="dto.networkId | imgNetworkPipe"
         alt="">
    <mat-label>{{dto.networkId | networkName}}</mat-label>
    <input matInput
           type="text"
           [(ngModel)]="emissionAddress"
           required>
    <mat-hint></mat-hint>
    <mat-error>{{'thisFieldIsRequired' | translate}}</mat-error>
  </mat-form-field>

  <div class="mb-4"
       *ngIf="actionAddressMode=='remove'">
    <div class="caption text-muted mb-1">{{dto.networkId | networkName}}</div>
    <div class="d-flex align-items-center">
      <img [src]="dto.networkId | imgNetworkPipe"
           alt="">
      <div class="__emission-address small-text ms-2">{{dto.emissionAddress}}</div>
    </div>
  </div>

  <div class="btn-group-justified">
    <button mat-stroked-button
            class="btn-icon btn-icon-left"
            [mat-dialog-close]="true">
      <i class="icon icomoon-close"></i>
      {{'cancel' | translate}}
    </button>

    <button mat-raised-button
            color="primary"
            class="btn-icon btn-icon-left"
            [mat-dialog-close]="true"
            [disabled]="waiting || !emissionAddress"
            (click)="saveAddressClick()">
      <i class="icon icomoon-checkmark"></i>
      {{'confirm' | translate}}
    </button>
  </div>
</div>
