import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { DlgContractService } from '../dlg-contract.service';
import { BigNumber } from 'bignumber.js';
import { AlertService } from '../shared-dlg.module';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DealDTO, DealServiceProxy } from '../../service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'deals',
    templateUrl: './deals.component.html',
    styleUrls: ['./deals.component.scss'],
})
export class DealsComponent extends ComponentBase implements OnInit {
    constructor(
        private _dialog: MatDialog,
        private _dlgContractSrv: DlgContractService,
        private _alertSrv: AlertService,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private dealService: DealServiceProxy,
        private activatedRoute: ActivatedRoute,
    ) {
        super();
    }

    public tabType: string = 'verified';
    public upcomingDeals: Array<DealDTO> = [];
    public inProgressDeals: Array<DealDTO> = [];
    public endedDeals: Array<DealDTO> = [];
    public allDealsFiltered: Array<DealDTO> = [];
    public allDeals: Array<DealDTO> = [];
    public nowTimeStamp: number = Math.floor(Date.now() / 1000);
    public dealTypeFilter = 0;

    waiting: boolean = false;
    waitingDeals: boolean = true;

    async ngOnInit() {
        this.dealTypeFilter = this.activatedRoute.snapshot.params.dealTypeFilter || 0;
        this.activatedRoute.params.subscribe((routeParams: any) => {
            this.dealTypeFilter = routeParams.dealTypeFilter;
            this.filterDeals();
        });
        await this.web3Service.initWeb3();
        window.dispatchEvent(new Event('resize'));
        //this.eventBus.loginEvent.subscribe(result => {
        //  console.log('loginEvent subscription:' + result);
        //  this.eventLogin(result);
        //});

        //this.eventBus.logoutEvent.subscribe(result => {
        //  console.log('logoutEvent subscription:' + result);
        //  this.eventLogout();
        //});

        //this.eventBus.outputNetworkChanged.subscribe(result => {
        //  console.log('outputNetworkChanged subscription:' + result);
        //  this.setToNetworkAndPairTo(result);
        //});

        //this.eventBus.fromPairChanged.subscribe(result => {
        //  console.log('fromPairChanged subscription:' + result);
        //  this.setPairFrom(result);
        //});

        this.dealService.getForChain(this.web3Service.chainIdNumber).subscribe(
            result => {
                this.allDeals = result;
                this.waitingDeals = false;
                this.filterDeals();
            },
            error => {
                console.error(error);
            },
        );
    }

    selectVC() {
        this.dealTypeFilter = 0;
        this.filterDeals();
    }

    selectIDO() {
        this.dealTypeFilter = 1;
        this.filterDeals();
    }

    selectINO() {
        this.dealTypeFilter = 2;
        this.filterDeals();
    }

    filterDeals() {
        this.allDealsFiltered = this.allDeals.filter(p => this.filterDealCondition(p));
        this.upcomingDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp < p.startTime);
        this.inProgressDeals = this.allDealsFiltered.filter(
            p => this.nowTimeStamp >= p.startTime && this.nowTimeStamp <= p.finishTime,
        );
        this.endedDeals = this.allDealsFiltered.filter(p => this.nowTimeStamp > p.finishTime);
    }

    filterDealCondition(p: DealDTO): boolean {
        return p.dealType == this.dealTypeFilter;
    }

    public getTotalRaiseETH(deal: DealDTO): number {
        return new BigNumber(deal.maxDistributedTokenAmount)
            .shiftedBy(-deal.rewardDecimal)
            .multipliedBy(deal.tokenPrice)
            .shiftedBy(-deal.paymentDecimal)
            .toNumber();
    }

    public getRatioPer1ETH(deal: DealDTO): number {
        return new BigNumber(1).shiftedBy(deal.paymentDecimal).dividedBy(deal.tokenPrice).toNumber();
    }
}
