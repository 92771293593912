<div class="dashboard-wrap">
  <app-mobile-header></app-mobile-header>
  <app-aside></app-aside>

  <div class="dashboard-content">
    <app-dashboard-header></app-dashboard-header>
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </div>
</div>
