import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentBase } from '../../shared/component-base';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import BigNumber from 'bignumber.js';
import { DlgSwitchNetworkComponent } from '../dlg-switch-network';

import { throwError, merge } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { DlgContractService } from '../dlg-contract.service';

import { AlertService } from '../shared-dlg.module';

import { environment } from '../../environments/environment';
import networks from '../networks.data';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import {
    DealDTO,
    DealRegistrationDTO,
    DealServiceProxy,
    UserDTO,
    UsersServiceProxy,
} from '../../service-proxies/service-proxies';
import { NetworkNamePipe } from '../../shared/pipes/networkName.pipe';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './blp-deal-detail.component.html',
    styleUrls: ['./blp-deal-detail.component.scss'],
})
export class BLPDealDetailComponent extends ComponentBase implements OnInit, OnDestroy {
    constructor(
        private _dialog: MatDialog,
        private _dlgContractSrv: DlgContractService,
        private _alertSrv: AlertService,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private route: ActivatedRoute,
        private router: Router,
        private dealService: DealServiceProxy,
        private usersService: UsersServiceProxy,
        public translate: TranslateService
    ) {
        super();

        this.now = Math.floor(Date.now() / 1000);

        this.updateTimeTimerId = setInterval(() => {
            this.now = Math.floor(Date.now() / 1000);

            var diffStart = 0;
            if (this.isUpcoming) var diffStart = this.startTimestamp - this.now;
            else if (this.isStarted) var diffStart = this.finishTimestamp - this.now;
            if (diffStart > 0) {
                this.timerViewDays = Math.floor(diffStart / (3600 * 24));
                this.timerViewHours = Math.floor((diffStart % (3600 * 24)) / 3600);
                this.timerViewMin = Math.floor((diffStart % 3600) / 60);
                this.timerViewSec = Math.floor(diffStart % 60);
            } else {
                this.timerViewDays = 0;
                this.timerViewHours = 0;
                this.timerViewMin = 0;
                this.timerViewSec = 0;
            }
        }, 1000);
    }

    deal: DealDTO;
    userInfo: UserDTO;
    dealRegistration: DealRegistrationDTO;

    kycConfirmed: boolean = false;

    waiting: boolean = false;
    account: string = '';
    dealAddress: string = '';

    //#region contract data
    paymentToken: string;

    tokenPrice: string;
    rewardToken: string;
    rewardDecimals: number;
    startTimestamp: number;
    finishTimestamp: number;
    startClaimTimestamp: number;
    maxDistributedTokenAmount: string;
    totalRaise: string; // Sum of all payments (in payment token)
    tokensForDistribution: string;
    minimumRaise: string;
    distributedTokens: string;
    allowRefund: boolean;

    vestingPercent: number;
    vestingStart: number;
    vestingInterval: number;
    vestingDuration: number;

    //#end region contract data
    paymentDecimal: number;
    paymentTokenSymbol: string;
    rewardTokenSymbol: string;
    rewardTokenName: string;
    tokensPerETH: number;

    userTier: any;
    userTierIndex: number = -1;
    allTiers: any[] = new Array();

    updateTimeTimerId: NodeJS.Timeout;
    updateUserTimerId: NodeJS.Timeout;
    updateDealTimerId: NodeJS.Timeout;

    now: number;

    timerViewDays: number;
    timerViewHours: number;
    timerViewMin: number;
    timerViewSec: number;

    usersDebtAmount: number;
    usersTotalPaymentAmount: number;
    usersClaimedTokensAmount: number;
    usersReleasableAmount: number;
    usersVestedAmount: number;
    usersVestedReleasedAmount: number;

    public get allowClaim(): boolean {
        if (!this.startClaimTimestamp || this.startClaimTimestamp == 0 || this.deal?.hideTgeDate) {
          return false;
        }
        return this.now > this.startClaimTimestamp;
    }

    public get isUpcoming(): boolean {
        return this.now < this.startTimestamp;
    }

    public get isStarted(): boolean {
        return this.now > this.startTimestamp && this.now < this.finishTimestamp;
    }

    public get isFinished(): boolean {
        return this.now > this.finishTimestamp;
    }

    public get allowPay(): boolean {
        if (!this.startTimestamp) return false;
        if (!this.finishTimestamp) return false;

        return this.now > this.startTimestamp && this.now < this.finishTimestamp;
    }

    public get getDistributedPercent(): number {
        if (this.maxDistributedTokenAmount && this.tokensForDistribution)
            return new BigNumber(this.tokensForDistribution)
                .div(this.maxDistributedTokenAmount)
                .multipliedBy(100)
                .toNumber();
        return 0;
    }

    async ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.dealAddress = params['address'];
            console.log(`deal address: ${this.dealAddress}`);
            this.dealService.getByAddress(this.dealAddress).subscribe(
                result => {
                    this.deal = result;
                    this.paymentTokenSymbol = result.paymentTokenSymbol;
                    console.log(this.deal);

                    if (this.web3Service.chainIdNumber != this.deal.chainId) {
                        let chainName = new NetworkNamePipe().transform(this.deal.chainId);
                        this.showErrorModal(this.translate.instant('selectNetworkInMetamask', { chainName }));
                        this.router.navigate(['/deals']);
                    }
                },
                error => {
                    console.error(error);
                },
            );
        });

        if (this.userSessionProvider.isAuthorized) {
            this.usersService.getMe().subscribe(
                result => {
                    this.userInfo = result;
                    this.kycConfirmed = result.kycConfirmed;
                },
                error => {
                    this.processServiceError(error);
                },
            );

            this.dealService.getRegistration(this.dealAddress).subscribe(
                result => {
                    this.dealRegistration = result;
                },
                error => {
                    this.processServiceError(error);
                },
            );
        }

        if (this.userSessionProvider.linkedWallet) {
            this.eventLogin(this.userSessionProvider.linkedWallet);
        }

        await this.web3Service.initWeb3();
        if (this.web3Service.web3) {
            this.updateDealContractData();

            this.updateDealDataInterval();
            this.updateDealTimerId = setInterval(() => {
                this.updateDealDataInterval();
            }, this.expectedBlockTime);
        }

        this.eventBus.loginEvent.subscribe(result => {
            console.log('loginEvent subscription:' + result);
            this.eventLogin(result);
        });

        this.eventBus.logoutEvent.subscribe(result => {
            console.log('logoutEvent subscription:' + result);
            this.eventLogout();
        });
    }

    async eventLogin(username: string) {
        console.log('eventLogin');
        console.log(username);
        if (this.account != username) {
            this.account = username;

            //TODO:HACK call early than updateDealContractData
            this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));
            this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
            if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
                this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
            } else {
                this.paymentDecimal = 18;
            }

            this.updateUserData();

            this.updateUserTimerId = setInterval(() => {
                this.updateUserData();
            }, this.expectedBlockTime);

            //this.getTokensDebts(username);
        }
    }

    eventLogout(): void {
        console.log('signOut');
        this.account = '';

        //this.tokenDebt = 0;
        //this.totalInvestedETH = 0;
        //this.ethBalance = 0;
        //this.totalStakeETH = null;
        //this.totalBuyToken = null;
        //this.myClaimedTokensAmount = null;
        //this.tokensDebt = new Array();

        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
    }

    async payClick() {
        if (!this.dealRegistration) {
            this.showWarningModal(this.translate.instant('clickJoinDeal'));
            return;
        }
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.dealPay(
            this.dealAddress,
            this.account,
            this.userTier.ticketSize,
            this.dealRegistration.signature,
            this.deal.paymentToken == '0x0000000000000000000000000000000000000000',
        );

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.updateUserData();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }
        this.waiting = false;
    }

    async claimClick() {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.dealClaim(this.dealAddress, this.account);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.updateUserData();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }
        this.waiting = false;
    }

    async claimVesingClick() {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.blpDealRelease(this.dealAddress, this.account);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.updateUserData();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }
        this.waiting = false;
    }

    async updateDealContractData() {
        console.log('Get contract data');
        this.paymentToken = await this.web3Service.getDealPaymentToken(this.dealAddress);
        if (this.paymentToken != '0x0000000000000000000000000000000000000000') {
            this.web3Service.GetContractSymbol(this.paymentToken).then(resp => {
                this.paymentTokenSymbol = resp;
            });
            this.paymentDecimal = parseInt(await this.web3Service.GetDecimals(this.paymentToken));
        } else {
            //this.paymentTokenSymbol = get from DTO
            this.paymentDecimal = 18;
        }
        this.tokenPrice = await this.web3Service.getDealTokenPrice(this.dealAddress);
        this.rewardToken = await this.web3Service.getDealRewardToken(this.dealAddress);

        this.web3Service.GetContractSymbol(this.rewardToken).then(resp => {
            this.rewardTokenSymbol = resp;
        });
        this.web3Service.GetContractName(this.rewardToken).then(resp => {
            this.rewardTokenName = resp;
        });

        this.rewardDecimals = parseInt(await this.web3Service.getDealDecimals(this.dealAddress));

        this.tokensPerETH = new BigNumber(1).shiftedBy(this.paymentDecimal).dividedBy(this.tokenPrice).toNumber();

        this.startTimestamp = parseInt(await this.web3Service.getDealStartTimestamp(this.dealAddress));
        this.finishTimestamp = parseInt(await this.web3Service.getDealFinishTimestamp(this.dealAddress));
        this.startClaimTimestamp = parseInt(await this.web3Service.getDealStartClaimTimestamp(this.dealAddress));

        this.web3Service.getDealMaxDistributedTokenAmount(this.dealAddress).then(resp => {
            this.maxDistributedTokenAmount = resp;
        });

        this.web3Service.getDealMinimumRaise(this.dealAddress).then(resp => {
            this.minimumRaise = resp;
        });

        this.web3Service.getDealAllowRefund(this.dealAddress).then(resp => {
            this.allowRefund = Boolean(resp);
        });

        let tiesrLenght = parseInt(await this.web3Service.getDealTiersLength(this.dealAddress));
        for (let i = 0; i < tiesrLenght; i++) {
            let tier = await this.web3Service.getDealTiers(this.dealAddress, i);
            console.log(tier);
            this.allTiers.push(tier);
        }

        this.web3Service.getVestingPercent(this.dealAddress).then(resp => {
            this.vestingPercent = parseInt(resp);
        });

        this.web3Service.getBLPVestingStart(this.dealAddress).then(resp => {
            this.vestingStart = parseInt(resp);
        });

        this.web3Service.getBLPVestingInterval(this.dealAddress).then(resp => {
            this.vestingInterval = parseInt(resp);
        });

        this.web3Service.getBLPVestingDuration(this.dealAddress).then(resp => {
            this.vestingDuration = parseInt(resp);
        });
    }

    async updateDealDataInterval() {
        this.web3Service.getDealTokensForDistribution(this.dealAddress).then(resp => {
            this.tokensForDistribution = resp;
        });

        this.web3Service.getDealTotalRaise(this.dealAddress).then(resp => {
            this.totalRaise = resp;
        });

        this.web3Service.getDealTotalRaise(this.dealAddress).then(resp => {
            this.distributedTokens = resp;
        });

        this.web3Service.getDealDistributedTokens(this.dealAddress).then(resp => {
            this.distributedTokens = resp;
        });
    }

    async updateUserData() {
        console.log('updateUserData');
        this.web3Service.getDealUserInfo(this.dealAddress, this.account).then(userInfo => {
            console.log(userInfo);
            console.log('userInfo');
            this.usersDebtAmount = this.toNumberFromWei(userInfo.debt, this.rewardDecimals);
            this.usersTotalPaymentAmount = this.toNumberFromWei(userInfo.totalPayment, this.paymentDecimal);
            this.usersClaimedTokensAmount = this.toNumberFromWei(
                new BigNumber(userInfo.total).minus(userInfo.debt).toString(),
                this.rewardDecimals,
            );
        });

        this.web3Service.getBLPReleasableAmount(this.dealAddress, this.account).then(resp => {
            this.usersReleasableAmount = this.toNumberFromWei(resp, this.rewardDecimals);
        });

        this.web3Service.getBLPVestingForUser(this.dealAddress, this.account).then(resp => {
            this.usersVestedAmount = this.toNumberFromWei(resp[0], this.rewardDecimals);
            this.usersVestedReleasedAmount = this.toNumberFromWei(resp[1], this.rewardDecimals);
        });

        this.web3Service.getDealUsersTierIndex(this.dealAddress, this.account).then(async resp => {
            //TODO: check response success
            console.log('getDealUsersTierIndex');
            console.log(resp);
            if (Boolean(resp[0])) {
                this.userTierIndex = parseInt(resp[1]);
                this.userTier = await this.web3Service.getDealTiers(this.dealAddress, this.userTierIndex);
                console.log('userTier');
                console.log(this.userTier);
            } else {
                console.error(resp);
                console.error(`Tier not found for user: ${this.account}`);
            }
        });
    }

    joinDealClick() {
        if (this.userSessionProvider.isAuthorized) {
            if (this.kycConfirmed) {
                this.dealService.registrate(this.dealAddress).subscribe(
                    result => {
                        this.dealRegistration = result;
                    },
                    error => {
                        this.processServiceError(error);
                    },
                );
            } else {
                this.navigateToKYC();
            }
        } else {
            this.navigateToLogin();
        }
    }

    processServiceError(error: any) {
        if (error.status == 401) {
            console.error('401');
            this.userSessionProvider.finishAuth();
            this.navigateToLogin();
        } else this._alertSrv.show(JSON.parse(error.response).message, 'error');
    }

    navigateToLogin(): void {
        this.router.navigate(['/login']);
    }

    navigateToKYC(): void {
        this.router.navigate(['/kyc']);
    }

    async ngOnDestroy() {
        if (this.updateUserTimerId) {
            clearInterval(this.updateUserTimerId);
        }
        if (this.updateDealTimerId) {
            clearInterval(this.updateDealTimerId);
        }
        if (this.updateTimeTimerId) {
            clearInterval(this.updateTimeTimerId);
        }
    }

  getTranslatedTier(tierIndex: number) {
    if (tierIndex == -1) return this.translate.instant('none');
    if (tierIndex == 0) return this.translate.instant('bot');
    if (tierIndex == 1) return this.translate.instant('cyborg');
    if (tierIndex == 2) return this.translate.instant('android');
    if (tierIndex == 3) return this.translate.instant('humanoid');
    if (tierIndex == 4) return this.translate.instant('cryptoNinja');
    return 'None';
  }

  getTranslatedShowPeriod(value: number): string {
    const timerViewDays = Math.floor(value / (3600 * 24));
    const timerViewHours = Math.floor(value % (3600 * 24) / 3600);
    const timerViewMin = Math.floor(value % 3600 / 60);
    const timerViewSec = Math.floor(value % 60);
    let stringData = "";
    if (timerViewDays)
      stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
    if (timerViewHours)
      stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
    if (timerViewMin)
      stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
    if (timerViewSec)
      stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
    return stringData;
  }
}
