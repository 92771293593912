<section class="dashboard-section p-40">
  <div class="row">
    <div class="col-lg-9 mb-4 mb-lg-0 dashboard-section-grid">
      <div class="card p-4">
        <div class="subtitle-1 mb-4 d-flex align-items-center">
          <i class="icon icomoon-wallet me-12 text-muted"></i>
          {{'nftBridge' | translate}}
        </div>
        <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
          <mat-label>TokenID</mat-label>
          <img src="/assets/images/icons/Bot.png" *ngIf="nftType == 0" alt="">
          <img src="/assets/images/icons/cyborg.png" *ngIf="nftType == 1" alt="">
          <img src="/assets/images/icons/android.png" *ngIf="nftType == 2" alt="">
          <img src="/assets/images/icons/humanoid.png" *ngIf="nftType == 3" alt="">
          <img src="/assets/images/icons/ninja.png" *ngIf="nftType == 4" alt="">
          <input matInput type="number" [(ngModel)]="nftTokenID"  required>
        </mat-form-field>

        <a *ngIf="!isVerifiedOwner && !checking" mat-raised-button color="primary"
          class="w-100 wallet-btn buy_gmpd_main" (click)="checkNFT()" style="width: 100%;">
          {{'verifyOwner' | translate}}
        </a>

        <div class="nft_loader_placeholder" *ngIf="checking">
          <mat-spinner class="__loading" diameter="40"></mat-spinner>
        </div>

        <div class="row" *ngIf="isVerifiedOwner && !checking">
          <div class="col-lg-5 col-md-5">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4 col-lg-6 col-md-6">
              <mat-label>{{'from' | translate}}</mat-label>
              <img src="/assets/images/network/eth.svg" *ngIf="fromNetwork == 0">
              <img src="/assets/images/network/bsc.svg" *ngIf="fromNetwork == 1">
              <input matInput value="{{fromNetwork == 0 ? 'ETH' : 'BSC'}}"/>
            </mat-form-field>
          </div>
          <div class="col-lg-2 col-md-2" style="margin-top: 4px;">
            <div class="swap-direction mb-4" (click)="swapDirections()"></div>
          </div>
          <div class="col-lg-5 col-md-5">
            <mat-form-field appearance="fill" hideRequiredMarker class="icon-form-field mb-4">
              <mat-label>{{'to' | translate}}</mat-label>
              <img src="/assets/images/network/eth.svg" *ngIf="toNetwork == 0">
              <img src="/assets/images/network/bsc.svg" *ngIf="toNetwork == 1">
              <input matInput value="{{toNetwork == 0 ? 'ETH' : 'BSC'}}"/>
            </mat-form-field>
          </div>
        </div>

        <div class="row " *ngIf="isVerifiedOwner && !checking">
          <div class="col-lg-5 col-md-5">
            <a mat-raised-button color="primary" class="w-100 wallet-btn buy_gmpd_main mb-4" (click)="fromNetwork != 0 && depositToEth()" [disabled]="fromNetwork == 0">
              {{'depositToEthChain' | translate}}
            </a>
          </div>
          <div class="offset-lg-2 offset-md-2 col-lg-5 col-md-5">
            <a mat-raised-button color="primary" class="w-100 wallet-btn buy_nft_main mb-4" (click)="fromNetwork != 1 && withdrawalFromEth()" [disabled]="fromNetwork == 1">
              {{'withdrawalFromEthChain' | translate}}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="nft-image-card" *ngIf="isVerifiedOwner && !checking">
        <p class="nft-image-card-title">NFT</p>

        <p class="nft-image-type">
          <img *ngIf="fromNetwork == 0" src="assets/images/svg/eth.svg" alt="eth">
          <img *ngIf="fromNetwork == 1" src="assets/images/svg/bsc.svg" alt="bsc">
          <span *ngIf="selectedNFTType === 'Bot'">{{'bot' | translate}}</span>
          <span *ngIf="selectedNFTType === 'Cyborg'">{{'cyborg' | translate}}</span>
          <span *ngIf="selectedNFTType === 'Android'">{{'android' | translate}}</span>
          <span *ngIf="selectedNFTType === 'Humanoid'">{{'humanoid' | translate}}</span>
          <span *ngIf="selectedNFTType === 'Crypto Ninja'">{{'cryptoNinja' | translate}}</span>
        </p>

        <div class="nft-image">
          <video *ngIf="selectedNFTType === 'Bot'" src="assets/nft-video/bot.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"></video>
          <video *ngIf="selectedNFTType === 'Cyborg'" src="assets/nft-video/cyborg.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"></video>
          <video *ngIf="selectedNFTType === 'Android'" src="assets/nft-video/android.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"></video>
          <video *ngIf="selectedNFTType === 'Humanoid'" src="assets/nft-video/humanoid.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"></video>
          <video *ngIf="selectedNFTType === 'Crypto Ninja'" src="assets/nft-video/crypto_ninja.mp4" playsinline="" autoplay="autoplay" loop="loop" muted="muted"></video>
        </div>

        <p class="nft-image-id">{{selectedNFT}}</p>
      </div>
    </div>

  </div>
</section>
