import { Injectable } from '@angular/core';

import { from, Observable, Subject, throwError } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import BigNumber from 'bignumber.js';

import IERC20Json from '../assets/abi/IERC20.json';
import ERC20BasicJson from '../assets/abi/ERC20Basic.json';
import DealJson from '../assets/abi/Deal.json';
import DealOldJson from '../assets/abi/DealOld.json';
import DealVestingJson from '../assets/abi/DealVesting.json';
import DealCollectWalletJson from '../assets/abi/DealCollectWallet.json';
import GMPDDealJson from '../assets/abi/GMPDDeal.json';
import LockerJson from '../assets/abi/Locker.json';
import DealLockupsJson from '../assets/abi/DealLockups.json';
import TGETokenVestingJson from '../assets/abi/TGETokenVesting.json';
import StakingPoolJson from '../assets/abi/StakingPool.json';
import StakeMasterJson from '../assets/abi/StakeMaster.json';
import StakingPenaltyPoolJson from '../assets/abi/StakingPenaltyPool.json';
import PancakeRouterJson from '../assets/abi/PancakeRouter.json';
import OldLockerJson from '../assets/abi/OldLockerAbi.json';
import TierCalculatorJson from '../assets/abi/TierCalculator.json';
import MerkleDistributorJson from '../assets/abi/MerkleDistributor.json';
import BonusJson from '../assets/abi/Bonus.json'
import rewardsJson from '../assets/abi/Rewards.json'
import { CatchWrapper } from './tryCatchWrapper';
import NFTStakingJson from '../assets/abi/ENFTStaking.json';
import GMPD_NFT_ABI from '../assets/abi/GMPD_NFT_ABI.json';
import BNFTJson from '../assets/abi/bnft.json';
import SWAP from '../assets/abi/swap.json';
import { environment } from '../environments/environment';
import { EventBus } from './event-bus';
import { UserSessionProvider } from './user-session-provider';
import { NetworkNamePipe } from './pipes/networkName.pipe';
import swal from "sweetalert2"
import networks from '../app/networks.data';
import crossAbi from '../app/wanchain-bridge/crossAbi.json';
import axios from 'axios';
declare const window: any;

const swapAddress = '0x689371af253d86ba92f7d521fd69b79fa1627f1c'; // Swap contract address
const bnftAddress = '0x1dDB2C0897daF18632662E71fdD2dbDC0eB3a9Ec'; // BNFT contract address

const GMDP_NFT_ADDRESS = {
    56: "0x6c9ef421b37392b8fdf19e877588181aa551e87d", // bsc
    97: "0x9b9492466f70e0da0f4ef0ac27a53550b0769232", // bsc-test // 0xe31431b4e3d0876046f11e2fd36822f35357ab43
    1: "",  // eth
    5: "0x3a17a676fe4f2464e84e57962956c99290b42f0d",  // eth-test (goerli)
}

const checkNftOwner = async (chainId:WanchainSupportedNetworks, tokenId: number) => {
    const contractInfo = {address: GMDP_NFT_ADDRESS[chainId], abi: GMPD_NFT_ABI};
    if (window.ethereum) {
        await window.ethereum.enable();
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(contractInfo.abi as any, contractInfo.address);
        const owner = await contract.methods.ownerOf(tokenId).call(); // Get nft owner
        return owner.toLowerCase();
    }
    return undefined;
}

const userTokens = async (address: string) => {
    const contractInfo = {address: bnftAddress, abi: BNFTJson.abi};
    const res: any[] = [];
    if (window.ethereum) {
        // Check if ethereum is available on the page
        await window.ethereum.enable();

        const web3 = new Web3(window.ethereum); // Create instance of Web3

        const contract = new web3.eth.Contract(contractInfo.abi as any, contractInfo.address);
        const balance = await contract.methods.balanceOf(address).call(); // Get users balance
        if (!balance) {
            return res;
        }
        for (let i = 0; i < balance; i++) {
            // Iterate through user tokens set
            const tokenId: number = await contract.methods.tokenOfOwnerByIndex(address, i).call(); // Get users token id
            res.push(tokenId);
        }
    }
    return res;
};

const swapTokens = async (tokenId: number) => {
      // Check if ethereum is available on the page
      await window.ethereum.enable();
      // tslint:disable-next-line:max-line-length
      const userAddress = window.ethereum.selectedAddress; // Get current user address from provider

      const web3 = new Web3(window.ethereum); // Create instance of Web3

      const bnftContract = new web3.eth.Contract(BNFTJson.abi as any, bnftAddress);
      // tslint:disable-next-line:max-line-length
      const approved = await bnftContract.methods.isApprovedForAll(userAddress, swapAddress).call(); // Check if the user approved to move his tokens
      if (!approved) {
          // If not - call approve method
          await bnftContract.methods.setApprovalForAll(swapAddress, true).send({ from: userAddress, maxPriorityFeePerGas: null, maxFeePerGas: null });
      }
      const swapContract = new web3.eth.Contract(SWAP as any, swapAddress);
      await swapContract.methods.swap(String(tokenId)).send({ from: userAddress, maxPriorityFeePerGas: null, maxFeePerGas: null }); // Call swap method
};

export class ChainError extends Error {
    constructor(message: any) {
        super(message);
        this.name = 'ChainError';
    }
}

enum WanchainSupportedNetworks{
    ETH = 1,
    ETH_TEST = 5,
    BSC = 56,
    BSC_TEST = 97
}
@Injectable({
    providedIn: 'root',
})
export class Web3Service {
    public MetamaskName: string = 'metamask';
    public WalletconnectName: string = 'walletconnect';
    public OkxWalletName: string = "okx";
    public binanceRegularNFTContract: string = bnftAddress;
    private readonly IERC20Abi: any;
    private readonly ERC20BasicAbi: any;
    public readonly DealAbi: any;
    public readonly DealOldAbi: any;
    public readonly DealVestingAbi: any;
    public readonly DealCollectWalletAbi: any;
    public readonly GMPDDealAbi: any;
    public readonly TGETokenVestingAbi: any;
    public readonly StakingPoolAbi: any;
    public readonly StakeMasterAbi: any;
    public readonly StakingPenaltyPoolAbi: any;
    public readonly PancakeRouterAbi: any;
    public readonly OldLockerAbi: any;
    public readonly TierCalculatorAbi: any;
    public readonly MerkleDistributorAbi: any;
    private readonly LockerAbi: any;
    private readonly DealLockupsAbi: any;
    private readonly StakingAbi: any;
    private readonly BonusAbi: any;
    private readonly rewardsAbi: any;

    public web3: Web3 = new Web3();

    private walletConnectProvider: WalletConnectProvider = new WalletConnectProvider({
        rpc: {
            1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
            5: 'https://maximum-shy-needle.ethereum-goerli.quiknode.pro/5aa8bdca199ee59f6729345db51f6fa76478962a/',
            42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
            //BSC mainnet 56
            56: 'https://bsc-dataseed.binance.org/',
            //BSC testnet
            97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
            //Heco testnet
            256: 'https://http-testnet.hecochain.com',
        },
    });

    private ethereumProvider: any;

    public getUsers_GMDP_NFT_Info(tokenID:number): Observable<boolean>{
      const userAddress = window.ethereum.selectedAddress.toLowerCase();
      const observable = from(checkNftOwner(parseInt(window.ethereum.chainId), tokenID)).pipe(
        map(ownerAddress => {
            console.log('NFTsInfo', ownerAddress);
            return ownerAddress ==  userAddress
            }
        )
      );
      return observable;
    }

    public getUsersBNFTInfo(): Observable<{ user: string; NFTsInfo: number[] }> {
        if (this.chainIdNumber !== 56){
          throwError('Main net is required');
        }
        const userAddress = window.ethereum.selectedAddress; // window.ethereum.selectedAddress
        const observable = from(userTokens(userAddress)).pipe(
          map(NFTsInfo => ({ user: userAddress, NFTsInfo }))
        );
        return observable;
    }

    private tokenPairID = 287; // static for GMPD NFT
    private scAddr = {
        97: '0xb12513cfcb13b7be59ba431c040b7206b0a211b9', //bsc-testnet
        5: '0xb8460eeaa06bc6668dad9fd42b661c0b96b3be57', //goerli
        1: '0xfceaaaeb8d564a9d0e71ef36f027b9d162bc334e', //ethereum
        56: '0xc3711bdbe7e3063bf6c22e7fed42f782ac82baee', //bsc
    };

    public async crossFromBscToETH(tokenID:number, toChainId:WanchainSupportedNetworks) {
        const smgUrl = environment.smgAPI.endpoint;
        const ret = await axios.get(smgUrl);
        const smgID = await ret.data.smgID;

        const crossTokenIDs = [tokenID];
        const values = [1]; // GMPD NFT amount, static 1 for 721
        const chainId = toChainId;

        const fee = 0.11; // fee for cross, get from bridge page, fee=baseFee + baseFee*(n-1)*10%

        const userAddress = window.ethereum.selectedAddress;
        const web3 = new Web3(window.ethereum);
        const crossSc = new web3.eth.Contract(crossAbi as any, this.scAddr[chainId]);
        const nftSc = new web3.eth.Contract(GMPD_NFT_ABI as any, GMDP_NFT_ADDRESS[chainId]);
        await nftSc.methods.setApprovalForAll(this.scAddr[chainId], true).send(this.params({ from: userAddress }));
        await crossSc.methods.userLockNFT(smgID, this.tokenPairID, crossTokenIDs, values, userAddress).send(this.params({ from: userAddress, value: new BigNumber(fee).shiftedBy(18).toString()}));
    }

    public async crossFromEthToBsc(tokenID:number, toChainId: WanchainSupportedNetworks){
        const smgUrl = environment.smgAPI.endpoint;
        const ret = await axios.get(smgUrl);
        const smgID = await ret.data.smgID;

        const crossTokenIDs = [tokenID];
        const values = [1]; // GMPD NFT amount, static 1 for 721
        const chainId = toChainId;

        const fee = 0; // fee for cross, get from bridge page, fee=baseFee + baseFee*(n-1)*10%

        const userAddress = window.ethereum.selectedAddress;
        const web3 = new Web3(window.ethereum);

        const crossSc = new web3.eth.Contract(crossAbi as any, this.scAddr[chainId]);
        const nftSc = new web3.eth.Contract(GMPD_NFT_ABI as any, GMDP_NFT_ADDRESS[chainId]);

        await nftSc.methods.setApprovalForAll(this.scAddr[chainId], true).send(this.params({ from: userAddress }));
        await crossSc.methods.userBurnNFT(smgID, this.tokenPairID, crossTokenIDs, values, GMDP_NFT_ADDRESS[chainId], userAddress).send(this.params({ from: userAddress, value: new BigNumber(fee).shiftedBy(18).toString()}));
    }

    public async swapBNFTtoGMDP(tokenId: number): Promise<any> {
        return await swapTokens(tokenId);
    }

    public get chainIdNumber(): number {
        return this.userSessionProvider.getChainId();
    }

    public get blpAddress(): string {
        return environment.tokenInfo.address;

        ////                              testnet kovan
        //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
        //  return environment.eth.blpAddress;
        //}
        ////                                    testnet bsc
        //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
        //  return environment.bsc.blpAddress;
        //}
        //throw new Error('Unsupported chain');
    }

    public get getStackingAddresses(): string[] {
        return environment.bsc.stackingAddress;
    }

    public get getFarmingAddress(): string{
      return environment.bsc.farmingAddress;
    }

    public get dealLockupsAddress(): string {
        return environment.bsc.dealLockupsAddress;
        ////                              testnet kovan
        //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
        //  return environment.eth.dealLockupsAddress;
        //}
        ////                                    testnet bsc
        //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
        //  return environment.bsc.dealLockupsAddress;
        //}
        //throw new Error('Unsupported chain');
    }

    public get dealCreatorAddress(): string {
        return environment.bsc.dealCreatorAddress;
        ////                              testnet kovan
        //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
        //  return environment.eth.dealCreatorAddress;
        //}
        ////                                    testnet bsc
        //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
        //  return environment.bsc.dealCreatorAddress;
        //}
        //throw new Error('Unsupported chain');
    }

    public get stakingPoolMasterAddress(): string {
        return environment.bsc.stakingPoolMasterAddress;
    }

    public get pancakeRouterAddress(): string {
        return environment.bsc.pancakeRouterAddress;
    }

    // public get oldLockerAddress(): string {
    //     return environment.bsc.oldLockerAddress;
    // }

    public get tierCalculatorAddress(): string {
        return environment.bsc.tierCalculatorAddress;
    }

    public get lockerAddress(): string {
        return environment.bsc.lockerAddress;

        ////                              testnet kovan
        //if (this.chainIdNumber === 1 || this.chainIdNumber === 42) {
        //  return environment.eth.lockerAddress;
        //}
        ////                                    testnet bsc
        //else if (this.chainIdNumber === 56 || this.chainIdNumber === 97) {
        //  return environment.bsc.lockerAddress;
        //}
        //throw new Error('Unsupported chain');
    }

    public get bonusAddress(): string {
      return environment.bsc.bonusAddress;
    }

    public get nftStakingAddress(): string {
        return environment.eth.nftStakingAddress;
    }

    public params(data: any): any {
        return { ...data, maxPriorityFeePerGas: null, maxFeePerGas: null };
    }

    constructor(private eventBus: EventBus, private userSessionProvider: UserSessionProvider) {
        console.log('Web3Service constructor');
        this.IERC20Abi = IERC20Json.abi;
        this.ERC20BasicAbi = ERC20BasicJson.abi;
        this.DealAbi = DealJson;
        this.DealOldAbi = DealOldJson;
        this.DealVestingAbi = DealVestingJson;
        this.DealCollectWalletAbi = DealCollectWalletJson;
        this.GMPDDealAbi = GMPDDealJson.abi;
        this.LockerAbi = LockerJson;
        this.DealLockupsAbi = DealLockupsJson;
        this.TGETokenVestingAbi = TGETokenVestingJson.abi;
        this.StakingPoolAbi = StakingPoolJson;
        this.StakeMasterAbi = StakeMasterJson;
        this.StakingPenaltyPoolAbi = StakingPenaltyPoolJson;
        this.PancakeRouterAbi = PancakeRouterJson;
        this.OldLockerAbi = OldLockerJson;
        this.TierCalculatorAbi = TierCalculatorJson;
        this.MerkleDistributorAbi = MerkleDistributorJson;
        this.StakingAbi = NFTStakingJson;
        this.BonusAbi = BonusJson;
        this.rewardsAbi = rewardsJson;
        //this.initWeb3();
    }

    async initWeb3(chainId: number = 0) {
        console.log('initWeb3');
        if (chainId != 0) {
            this.setWeb3OnCustomRPC(chainId);
            return;
        }
        this.ethereumProvider = window.ethereum;
        if (this.ethereumProvider) {
            this.web3 = new Web3(this.ethereumProvider);

            var metamaskChainId = this.convertChainIdToHex(await this.web3.eth.getChainId());
            // await window.ethereum.request({ method: 'eth_chainId' });
            console.log('matamask chainId: ' + metamaskChainId);
            if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
                this.setWeb3OnCustomRPC();
            }
            // TODO: that = this;
            // Reload when chain was changed in metamask (without connect wallet)
            var that = this;
            if (window.ethereum) {
                window.ethereum.on('chainChanged', function (chainId: string) {
                    console.log(`chainChanged: ${chainId}`);
                    let chainIdNumber = parseInt(chainId, 16);
                    console.log('chainIdNumber: ' + chainIdNumber);
                    if (chainIdNumber != that.chainIdNumber) {
                        if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
                            that.userSessionProvider.setChainId(chainIdNumber);
                        } else {
                            console.log('finishSession unsupported chain');
                            // swal.fire({
                            //     text: "Network was changed, please switch to BSC",
                            //     icon: 'warning',
                            //     onClose: () => {location.reload()}
                            // });
                            that.userSessionProvider.finishSession();
                            // that.userSessionProvider.setChainId(-1);
                            return;
                        }
                    }
                    if (typeof window.ethereum !== 'undefined' && window.ethereum.isTrust) {
                      console.log('User is using Trust Wallet');
                    } else {
                      location.reload();
                    }
                });
            }
            if (window.okxwallet) {
                window.okxwallet.on('chainChanged', function (chainId: string) {
                  console.log(`chainChanged: ${chainId}`);
                  let chainIdNumber = parseInt(chainId, 16);
                  console.log('chainIdNumber: ' + chainIdNumber);
                  if (chainIdNumber != that.chainIdNumber) {
                    if (environment.supportedChains.indexOf(chainIdNumber) >= 0) {
                      that.userSessionProvider.setChainId(chainIdNumber);
                    }
                    else {
                      console.log('finishSession unsupported chain');
                    //   swal.fire({
                    //     text: "Network was changed, please switch to BSC",
                    //     icon: 'warning',
                    //     onClose: () => {location.reload()}
                    //   });
                      that.userSessionProvider.finishSession();
                      return;
                    }
                  }
                });
            }
            return;
        } else {
            //this.isWeb3Disabled = true;
            if (!this.web3 || !this.web3.currentProvider) {
                this.setWeb3OnCustomRPC();
            }
        }

        //await this.updateChanId();

        //this.web3 = new Web3("https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b");
        //this.chainId = '0x2a';

        //this.chainId = '0x01';

        //await this.WalletConnect();
    }

    private setWeb3OnCustomRPC(chainId: number = 0) {
        console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
        let chainIdNumber = this.chainIdNumber;
        if (chainId != 0) chainIdNumber = chainId;
        //ETH Mainnet
        if (chainIdNumber == 1) {
            this.web3 = new Web3('https://mainnet.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898');
        }
         //Goerli
        if (chainIdNumber == 5) {
            this.web3 = new Web3('https://maximum-shy-needle.ethereum-goerli.quiknode.pro/5aa8bdca199ee59f6729345db51f6fa76478962a/');
        }
        //Kovan
        else if (chainIdNumber == 42) {
            this.web3 = new Web3('https://kovan.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898');
        }
        //BSC
        else if (chainIdNumber == 56) {
            this.web3 = new Web3('https://bsc-dataseed.binance.org/');
        }
        //BSC Testnet
        else if (chainIdNumber == 97) {
            this.web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
        }
        //Heco Testnet
        else if (chainIdNumber == 256) {
            this.web3 = new Web3('https://http-testnet.hecochain.com');
        }
    }

    //#region unlock wallet

    //async unlockWallet(): Promise<void> {
    public async unlockWalletconnect(reload = false): Promise<string> {
        var data: any = await this.WalletConnect();
        //this.account = data[0];
        this.userSessionProvider.linkWallet(data[0], this.WalletconnectName);
        this.eventBus.loginEvent.emit(data[0]);

        if (reload) {
            location.reload();
        }
        return data[0];
    }
    public async unlockOkx(reload = false) {
        if (typeof window.okxwallet == 'undefined') {
          throw new ChainError('OKX must be installed');
        }
        this.web3 = new Web3(window.okxwallet as any);

        await window.okxwallet.request({ method: 'eth_requestAccounts' });
        let chainId = await window.okxwallet.request({ method: 'eth_chainId' });
        let chainIdNumber = parseInt(chainId, 16);

        if (this.chainIdNumber != chainIdNumber) {
          var toNetwork = networks.find(n => n.chainId == this.chainIdNumber);
          if (toNetwork.networkParams) {
            try {
              await window.okxwallet.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: toNetwork.networkParams.chainId }],
              })
              return true
            } catch (switchError: any) {
              if (switchError.code === 4902) {
                try {
                  await window.okxwallet.request({
                    method: 'wallet_addEthereumChain',
                    params: [toNetwork.networkParams],
                  });
                } catch (addError) {
                  console.error(addError);
                  this.userSessionProvider.finishSession();
                }
              }
            }
          }
          else{
            this.userSessionProvider.finishSession();
            throw new ChainError(`Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`);
          }
        }

        window.okxwallet.enable().then((data: any) => {
          if (data.length > 0) {
            this.userSessionProvider.linkWallet(data[0], this.OkxWalletName);
            this.eventBus.loginEvent.emit(data[0]);
            var that = this;
            if (window.okxwallet) {
              window.okxwallet.on('accountsChanged', function (accounts: string[]) {
                console.log('accountsChanged');
                console.log(accounts);
                if (that.userSessionProvider.authEth && accounts.length > 0 && that.userSessionProvider.authEth != accounts[0]
                  || accounts.length == 0) {
                  that.userSessionProvider.finishAuth();
                }
                location.reload();
              })
            }
            if (reload) {
              location.reload();
            }
          }
        }, (reason: any) => {
          console.log("My Permission to connect to Okx was denied");
          console.log(reason);
        })
        return true;
      }

    public async unlockMetamask(reload = false) {
        console.log('unlockMetamask');
        if (typeof window.ethereum == 'undefined') {
            //this.translate.get('MetaMask must be installed').subscribe((langResp: string) => {
            throw new ChainError('MetaMask must be installed');
            //});
            return false;
        }

        await window.ethereum.request({ method: 'eth_requestAccounts' });
        let chainId = await window.ethereum.request({ method: 'eth_chainId' });
        ////  Get Chain Id
        //TODO: check is this work in wallets
        //var walletChainIdNumber = await this.web3.eth.getChainId();

        let chainIdNumber = parseInt(chainId, 16);
        console.log('chainId: ' + chainId);
        console.log('chainIdNumber: ' + chainIdNumber);
        console.log('web3Service chainId: ' + this.chainIdNumber);

        if (this.chainIdNumber != chainIdNumber) {
            var toNetwork = networks.find(n => n.chainId == this.chainIdNumber);
            if (toNetwork.networkParams) {
                try {
                    // @ts-ignore
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: toNetwork.networkParams.chainId }],
                    });
                    return true;
                } catch (switchError: any) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [toNetwork.networkParams],
                            });
                        } catch (addError) {
                            console.error(addError);
                            this.userSessionProvider.finishSession();
                        }
                    }
                }
            } else {
                this.userSessionProvider.finishSession();
                throw new ChainError(
                    `Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`,
                );
            }
        }

        //if (environment.production) {
        //    if (chainId != '0x01' && chainId != '0x1' && chainId != '0x38') {
        //        this.showErrorModal(`Select Mainnet or BSC Network in MetaMask.`);
        //        //this.translate.get('select_right_metamask_network').subscribe((langResp: string) => {
        //        //    this.showErrorModal(langResp);
        //        //});
        //        return false;
        //    }
        //}
        //else {
        //    console.log(chainId);
        //    if (chainId != '0x2a' && chainId != '0x61') {
        //        this.showErrorModal(`Select Kovan or BSC Test Network in MetaMask.`);
        //        return false;
        //    }
        //}

        window.ethereum.enable().then(
            (data: any) => {
                console.log('enabled');
                console.log(data);

                if (data.length > 0) {
                    //this.account = data[0];
                    this.userSessionProvider.linkWallet(data[0], this.MetamaskName);
                    this.eventBus.loginEvent.emit(data[0]);

                    //TOOD: that = this;
                    var that = this;
                    if (window.ethereum) {
                        window.ethereum.on('accountsChanged', function (accounts: string[]) {
                            console.log('accountsChanged');
                            console.log(accounts);
                            if (
                                (that.userSessionProvider.authEth &&
                                    accounts.length > 0 &&
                                    that.userSessionProvider.authEth != accounts[0]) ||
                                accounts.length == 0
                            ) {
                                that.userSessionProvider.finishAuth();
                            }
                            location.reload();
                        });
                        //window.ethereum.on('chainChanged', function (chainId: string) {
                        //  console.log('chainChanged');
                        //  console.log(chainId);
                        //  if (chainId === "0x1")
                        //    chainId = "0x01";
                        //  if (chainId != that.chainId) {
                        //    //if new chain is Ethereum
                        //    if (chainId === '0x01' || chainId === '0x2a') {
                        //      that.userSessionProvider.setETHNetwork();
                        //    }
                        //    //if new chain is BSC
                        //    else if (chainId === '0x38' || chainId === '0x61') {
                        //      that.userSessionProvider.setBSCNetwork();
                        //    }
                        //  }

                        //  location.reload();
                        //})
                    }

                    //TODO: remove reload, add eventBus
                    if (reload) {
                        location.reload();
                    }
                }
            },
            (reason: any) => {
                console.log('My Permission to connect to Metamask was denied');
                console.log(reason);
            },
        );

        return true;
    }

    //#endregion

    async WalletConnect() {
        console.log('WalletConnect');
        //  Create WalletConnect Provider
        this.walletConnectProvider = new WalletConnectProvider({
            rpc: {
                1: 'https://mainnet.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
                5: 'https://maximum-shy-needle.ethereum-goerli.quiknode.pro/5aa8bdca199ee59f6729345db51f6fa76478962a/',
                42: 'https://kovan.infura.io/v3/28f992e48bb54bb5a7e2d3db074ce96b',
                // BSC mainnet 56
                56: 'https://bsc-dataseed.binance.org/',
                // BSC testnet
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                // heco testnet
                256: 'https://http-testnet.hecochain.com',
            },
        });
        console.log(`chainIdNumber: ${this.chainIdNumber}`);
        this.walletConnectProvider.chainId = this.chainIdNumber;

        //  Enable session (triggers QR Code modal)
        let addresses = await this.walletConnectProvider.enable();
        console.log(addresses);

        //  Create Web3
        this.web3 = new Web3(this.walletConnectProvider as any);

        //  Get Chain Id
        const walletChainIdNumber = await this.web3.eth.getChainId();
        console.log('Wallet connect chainId: ' + walletChainIdNumber);
        if (this.chainIdNumber != walletChainIdNumber) {
            throw new ChainError(
                `Select ${new NetworkNamePipe().transform(this.chainIdNumber)} Network in your wallet.`,
            );
            //this.userSessionProvider.finishSession();
        }

        // Subscribe to accounts change
        this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
            console.log('accountsChanged ' + accounts);
            if (
                (this.userSessionProvider.authEth &&
                    accounts.length > 0 &&
                    this.userSessionProvider.authEth != accounts[0]) ||
                accounts.length == 0
            ) {
                this.userSessionProvider.finishAuth();
            }
            location.reload();
            this.eventBus.accountsChanged.emit(accounts);
        });

        // Subscribe to chainId change
        this.walletConnectProvider.on('chainChanged', (chainId: number) => {
            console.log('chainChanged' + chainId);

            this.eventBus.chainChanged.emit(this.convertChainIdToHex(chainId));
        });

        // Subscribe to session connection
        this.walletConnectProvider.on('connect', () => {
            console.log('connect');
            this.eventBus.walletConnect.emit('');
        });

        // Subscribe to session disconnection
        this.walletConnectProvider.on('disconnect', (code: number, reason: string) => {
            console.log(code, reason);
            this.eventBus.walletDisconnect.emit(reason);
        });
        //console.log(this.web3);
        return addresses;
    }

    public convertChainIdToHex(value: number): string {
        var hexChainId = '0x' + value.toString(16);
        if (hexChainId === '0x1') hexChainId = '0x01';
        return hexChainId;
    }

    async WalletDisconnect() {
        if (this.walletConnectProvider) {
            // Close provider session
            await this.walletConnectProvider.disconnect();
        }
    }

    async PersonalSign(dataToSign: string, address: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.web3.eth.personal.sign(dataToSign, address, '', (error, resp) => {
                if (error) {
                    console.log(error);
                }
                console.log(resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    //#region web3

    //#region ERC20BasicAbi
    async GetTransactionReceipt(tx: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.web3.eth.getTransactionReceipt(tx, (error, resp) => {
                console.log(resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetDecimals(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
            contract.methods.decimals().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetTotalSupply(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, contractAddress);
            contract.methods.totalSupply().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetAllowance(account: string, tokenForspend: string, forContractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenForspend);
            contract.methods.allowance(account, forContractAddress).call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetTokenBalance(account: string, tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
            contract.methods.balanceOf(account).call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetContractSymbol(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
            contract.methods.symbol().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async GetContractName(tokenAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.ERC20BasicAbi, tokenAddress);
            contract.methods.name().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    approve(account: string, tokenForspend: string, forContractAddress: string) {
        // new contract
        // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
        let contract = new this.web3.eth.Contract(this.IERC20Abi, tokenForspend);

        let contractEventSource = contract.methods
            .approve(forContractAddress, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
            .send(this.params({ from: account }));

        return contractEventSourceToObserves(contractEventSource);
    }

    approveForDeal(account: string, tokenForSpend: string, forContractAddress: string, usersTicketSize: any) {
      // new contract
      // DOC: https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#new-contract
      const contract = new this.web3.eth.Contract(this.IERC20Abi, tokenForSpend);
      return contract.methods
        .approve(
          forContractAddress,
          usersTicketSize
        )
        .send(this.params({ from: account }));
    }


  async getEthBalance(customerAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.web3.eth.getBalance(customerAddress, (error, balance) => {
                resolve(balance);
            });
        }) as Promise<any>;
    }

    //#endregion ERC20BasicAbi

    //#region LockerAbi

    async getLockedTokenAmount(contractAddress: string, user: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const contract = new this.web3.eth.Contract(this.LockerAbi, contractAddress);
            contract.methods.getLockedGMPD(user).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getLockedNFT(user: string): Promise<number>{
      return new Promise((resolve, reject) => {
        const contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
        contract.methods.userNFT(user).call({}, (error: any, resp: number) => {
            resolve(resp);
        });
    }) as Promise<number>;
    }

    async getLockerPenaltyBP(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.LockerAbi, contractAddress);
            contract.methods.getPenaltyBP(userAddress).call({}, (error: any, resp: string) => {
                console.log(`getPenaltyBP`);
                console.log(resp);
                resolve(resp);
            });
        }) as Promise<any>;
    }

    getNFTTYpe(account: string, tokenID: number): any {
      return new Promise((resolve, reject) => {
        const contract = new this.web3.eth.Contract(GMPD_NFT_ABI as any, environment.nftInfo.address);
        contract.methods.nftTypes(tokenID).call({}, (error: any, resp: string) => {
            console.log(`getTokenNFTTYpe`, resp);
            resolve(resp);
        });
    }) as Promise<any>;
    }

    lockerDeposit(account: string, tokenAmount: number, decimal: number) {
        let stringTokenAmount = '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
        const contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
        const contractEventSource = contract.methods.deposit(stringTokenAmount).send(this.params({ from: account }));
        return contractEventSourceToObserves(contractEventSource);
    }

    lockerWithdraw(account: string, tokenAmount: number, decimal: number) {
        let stringTokenAmount = '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
        const contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
        const contractEventSource = contract.methods.withdraw(stringTokenAmount).send(this.params({ from: account }));
        return contractEventSourceToObserves(contractEventSource);
    }

    async approveDepositNFT(account: string, tokenID: number){
      const contract = new this.web3.eth.Contract(GMPD_NFT_ABI as any, environment.nftInfo.address);

      // tslint:disable-next-line:max-line-length
      const approved = await contract.methods.isApprovedForAll(account, this.lockerAddress).call(); // Check if the user approved to move his tokens
      if (!approved) {
          // If not - call approve method
          await contract.methods.setApprovalForAll(this.lockerAddress, true).send(this.params({ from: account }));
      }
    }

    async lockerDepositNFT(account: string, tokenID: number){
      await this.approveDepositNFT(account, tokenID);
      const contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
      const contractEventSource = contract.methods.depositNFT(tokenID).send(this.params({ from: account }));
      return contractEventSourceToObserves(contractEventSource);
    }

    lockerWithdrawNFT(account: string){
      const contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
      const contractEventSource = contract.methods.withdrawNFT().send(this.params({ from: account }));
      return contractEventSourceToObserves(contractEventSource);
    }


    getTokenOwner(tokenID: number){
      return new Promise((resolve, reject) => {
        const contract = new this.web3.eth.Contract(GMPD_NFT_ABI as any, environment.nftInfo.address);
        contract.methods.ownerOf(tokenID).call({}, (error: any, resp: string) => {
          resolve(resp.toLowerCase());
      });
    }) as Promise<string>;
    }


    // lockerOldWithdraw(account: string, tokenAmount: number, decimal: number) {
    //     let stringTokenAmount = '0x' + new BigNumber(tokenAmount).shiftedBy(decimal).toString(16);
    //     const contract = new this.web3.eth.Contract(this.OldLockerAbi, this.oldLockerAddress);

    //     const contractEventSource = contract.methods.withdraw(stringTokenAmount).send(this.params({ from: account }));

    //     return contractEventSourceToObserves(contractEventSource);
    // }

    async getLockerPenalties(index: number): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.LockerAbi, this.lockerAddress);
            contract.methods.allPenalties(index).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    //#endregion LockerAbi

    //#region TierCalculator
    async getLockedTokenAmountTierCalculator(contractAddress: string, user: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, contractAddress);
            contract.methods.getLockedTokens(user).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getUserLockingStart(user: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, this.tierCalculatorAddress);
            contract.methods.userLockingStarts(user).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealUsersTierIndex(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TierCalculatorAbi, this.tierCalculatorAddress);
            contract.methods.getTierIndex(userAddress, contractAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    @CatchWrapper
    async getDealUsersBonusTierIndex(
      contractAddress: string,
      userAddress: string
    ): Promise<any> {
      return new Promise((resolve, reject) => {
        const contract = new this.web3.eth.Contract(
          this.TierCalculatorAbi,
          this.tierCalculatorAddress
        );
        contract.methods
          .getBonusTierIndex(userAddress, contractAddress)
          .call()
          .then((resp: any) => resolve(resp));
      }) as Promise<any>;
    }

    @CatchWrapper
    async getBonusInterval(): Promise<string> {
      return new this.web3.eth.Contract(
        this.TierCalculatorAbi,
        this.tierCalculatorAddress
      ).methods
        .bonusInterval()
        .call();
    }

    //#endregion TierCalculator

    //#region PancakeRouterAbi
    async getAmountsOut(amountIn: number, path: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            let stringAmountIn = '0x' + new BigNumber(amountIn).toString(16);
            let contract = new this.web3.eth.Contract(this.PancakeRouterAbi, this.pancakeRouterAddress);
            contract.methods.getAmountsOut(stringAmountIn, path).call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async WETH(): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.PancakeRouterAbi, this.pancakeRouterAddress);
            contract.methods.WETH().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }
    //#endregion PancakeRouterAbi

    //#region StakeMasterAbi

    async GetStakeMasterFeeAmount(): Promise<number> {
        return new Promise((resolve, reject) => {
            console.log(this.stakingPoolMasterAddress);
            let contract = new this.web3.eth.Contract(this.StakeMasterAbi, this.stakingPoolMasterAddress);
            contract.methods.feeAmount().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<number>;
    }

    async GetStakeMasterFeeToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakeMasterAbi, this.stakingPoolMasterAddress);
            contract.methods.feeToken().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    createStakingPool(
        userAddress: string,
        stakingToken: string,
        poolToken: string,
        startTime: number,
        finishTime: number,
        poolTokenAmount: number,
        poolTokenDecimals: number,
        hasWhiteListing: boolean,
        depositFeeBP: number,
        feeTo: string,
        msgValue: number,
    ) {
        let masterContract = new this.web3.eth.Contract(this.StakeMasterAbi, this.stakingPoolMasterAddress);

        let stringStartTime = '0x' + new BigNumber(startTime).toString(16);
        let stringFinishTime = '0x' + new BigNumber(finishTime).toString(16);
        // let stringPoolTokenAmount = "0x" + new BigNumber(poolTokenAmount).toString(16);
        let stringDepositFeeBP = '0x' + new BigNumber(depositFeeBP).toString(16);

        let stringPoolTokenAmount = '0x' + new BigNumber(poolTokenAmount).shiftedBy(18).toString(16);

        //   function createStakingPool(
        //     IERC20 _stakingToken,
        //     IERC20 _poolToken,
        //     uint256 _startTime,
        //     uint256 _finishTime,
        //     uint256 _poolTokenAmount,
        //     bool _hasWhitelisting,
        //     uint256 _depositFeeBP,
        //     address _feeTo
        // )

        const contractEventSource = masterContract.methods
            .createStakingPool(
                stakingToken,
                poolToken,
                stringStartTime,
                stringFinishTime,
                stringPoolTokenAmount,
                hasWhiteListing,
                stringDepositFeeBP,
                feeTo,
            )
            .send(this.params({ from: userAddress, value: msgValue }));

        return contractEventSourceToObserves(contractEventSource);
    }
    //#endregion StakeMasterAbi

    //#region StakingPoolAbi

    async rewardPerSec(address: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
            contract.methods.rewardPerSec().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async allStakedAmount(address: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingPoolAbi, address);
            contract.methods.allStakedAmount().call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    isWhitelisted(userAddress: string, poolAddress: string) {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
            contract.methods.isWhitelisted(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    depositToPool(userAddress: string, tokenAmount: number, tokenDecimals: number, poolAddress: string, referrerAddress: string = '0x0000000000000000000000000000000000000000') {
        let poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
        let bnTokenAmount = '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
        console.log(bnTokenAmount);
        const contractEventsSource = poolContract.methods.stakeTokens(bnTokenAmount, referrerAddress).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventsSource);
    }

    withdrawFromPool(userAddress: string, tokenAmount: number, tokenDecimals: number, poolAddress: string) {
        let poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
        let bnTokenAmount = '0x' + new BigNumber(tokenAmount).shiftedBy(tokenDecimals).toString(16);
        const contractEventsSource = poolContract.methods.withdrawStake(bnTokenAmount).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventsSource);
    }

    async getPoolUserInfo(userAddress: string, poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const poolContract = new this.web3.eth.Contract(this.StakingPoolAbi, poolAddress);
            poolContract.methods.getUserInfo(userAddress).call({}, (error: any, resp: any) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getPoolPendingReward(contractAddress: string, user: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingPoolAbi, contractAddress);
            contract.methods.pendingReward(user).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getPoolPenaltyBP(startTime: number, poolAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
          let contract = new this.web3.eth.Contract(this.StakingPenaltyPoolAbi, poolAddress);
          contract.methods.getPenaltyBP(startTime).call({}, (error: any, resp: any) => {
            resolve(resp);
          });
        }) as Promise<any>;
      }

    async getPoolPenalties(index: number, poolAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingPenaltyPoolAbi, poolAddress);
            contract.methods.allPenalties(index).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    //#endregion StakingPoolAbi

    //#region DealLockupsAbi
    async getDealLockupsTiersLength(): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockupsAddress);
            contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealLockupsTiers(index: number): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockupsAddress);
            contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getLastParticipations(userAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealLockupsAbi, this.dealLockupsAddress);
            contract.methods.lastParticipations(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }
    //#endregion DealLockupsAbi

    //#region DealAbi

    async getDealPaymentToken(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.paymentToken().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealTokenPrice(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.tokenPrice().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealRewardToken(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.rewardToken().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealDecimals(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.decimals().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealStartTimestamp(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.startTimestamp().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealFinishTimestamp(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.finishTimestamp().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealStartClaimTimestamp(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.startClaimTimestamp().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealMaxDistributedTokenAmount(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.maxDistributedTokenAmount().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealTotalRaise(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.totalRaise().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealTokensForDistribution(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.tokensForDistribution().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealMinimumRaise(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.minimumRaise().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealDistributedTokens(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.distributedTokens().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealAllowRefund(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.allowRefund().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealTiersLength(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.getTiersLength().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getDealTiers(contractAddress: string, index: number): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getDealUserCustomRaise(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.customRaises(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }
    
    async getOldDealTiers(contractAddress: string, index: number): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealOldAbi, contractAddress);
            contract.methods.allTiers(index).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getVestingPercent(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.vestingPercent().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getDealVestingAddress(dealContractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, dealContractAddress);
            contract.methods.dealVesting().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getDealUserInfo(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);
            contract.methods.userInfo(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    dealPay(
        contractAddress: string,
        userAddress: string,
        amountWithDecimals: string,
        signature: string,
        payByETH: boolean,
    ) {
        //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
        let wei = payByETH ? amountWithDecimals : 0;

        let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

        //pay(uint256 _amount, bytes memory _signature) payable
        const contractEventSource = contract.methods
            .pay(amountWithDecimals, signature)
            .send(this.params({ value: wei, from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    dealClaim(contractAddress: string, userAddress: string) {
        let contract = new this.web3.eth.Contract(this.DealAbi, contractAddress);

        const contractEventSource = contract.methods.claim().send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    //#endregion DealAbi

    //#region DealCollectWalletAbi
    dealPayWithEmissionAddress(
        contractAddress: string,
        userAddress: string,
        emissionAddress: string,
        amountWithDecimals: string,
        signature: string,
        payByETH: boolean,
    ) {
        //let stringTokenAmount = "0x" + new BigNumber(amount).shiftedBy(decimals).toString(16);
        let wei = payByETH ? amountWithDecimals : 0;

        let contract = new this.web3.eth.Contract(this.DealCollectWalletAbi, contractAddress);

        //pay(uint256 _amount, string memory _wallet, bytes memory _signature)
        const contractEventSource = contract.methods
            .pay(amountWithDecimals, emissionAddress, signature)
            .send(this.params({ value: wei, from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }
    //#endregion DealCollectWalletAbi

    //#region DealVestingAbi

    async getVVestingStart(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
            contract.methods.vestingStart().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getVVestingInterval(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
            contract.methods.vestingInterval().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getVVestingDuration(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
            contract.methods.vestingDuration().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getVestingReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
            contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);
            contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    vestingRelease(contractAddress: string, userAddress: string) {
        let contract = new this.web3.eth.Contract(this.DealVestingAbi, contractAddress);

        const contractEventSource = contract.methods.release(userAddress).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    //#endregion DealVestingAbi

    //#region GMPDDealAbi

    async getBLPVestingStart(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);
            contract.methods.vestingStart().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getBLPVestingInterval(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);
            contract.methods.vestingInterval().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getBLPVestingDuration(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);
            contract.methods.vestingDuration().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getBLPReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);
            contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getBLPVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);
            contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    blpDealRelease(contractAddress: string, userAddress: string) {
        let contract = new this.web3.eth.Contract(this.GMPDDealAbi, contractAddress);

        const contractEventSource = contract.methods.release(userAddress).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    //#endregion GMPDDealAbi

    //#region TGETokenVestingAbi

    async getTGEVestingStart(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.start().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEVestingInterval(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.interval().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEVestingDuration(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.duration().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEVestingTgeTime(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.tgeTime().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEVestingTgePercent(contractAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.tgePercent().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEReleasableAmount(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.releasableAmount(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    async getTGEVestingForUser(contractAddress: string, userAddress: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);
            contract.methods.vestings(userAddress).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<any>;
    }

    TGEReleaseTGE(contractAddress: string, userAddress: string) {
        let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);

        const contractEventSource = contract.methods.releaseTGE(userAddress).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    TGERelease(contractAddress: string, userAddress: string) {
        let contract = new this.web3.eth.Contract(this.TGETokenVestingAbi, contractAddress);

        const contractEventSource = contract.methods.release(userAddress).send(this.params({ from: userAddress }));

        return contractEventSourceToObserves(contractEventSource);
    }

    //#endregion TGETokenVestingAbi

    //#region MerkleDistributorAbi

    async getClaimingDealToken(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
            contract.methods.token().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async isPausedClaimingDeal(contractAddress: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
            contract.methods.paused().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    claimTokensClaimingDeal(
        account: string,
        contractAddress: string,
        index: number,
        emissionAddress: string,
        amount: string,
        merkleProofs: string[],
    ) {
        let masterContract = new this.web3.eth.Contract(this.MerkleDistributorAbi, contractAddress);
        const contractEventSource = masterContract.methods
            .claim(index, emissionAddress, amount, merkleProofs)
            .send(this.params({ from: account }));
        return contractEventSourceToObserves(contractEventSource);
    }
    //#endregion MerkleDistributorAbi

    //#region BonusAbi

    @CatchWrapper
    async getBonusAmount(
      contractAddress: string,
      user: string
    ): Promise<string> {
      return new this.web3.eth.Contract(this.BonusAbi, contractAddress).methods
        .balances(user)
        .call();
    }

  //#endregion BonusAbi


  //#endregion web3
  @CatchWrapper
  public async claimRefRewards(account: string) {
    return new this.web3.eth.Contract(this.rewardsAbi, environment.bsc.refRewardsAddress).methods
      .withdrawReward()
      .send(this.params({ from: account }));
  }

  @CatchWrapper
  public async getRewardsAmount(account: string): Promise<BigNumber> {
    console.log('getRewardsAmount', account);
    return new this.web3.eth.Contract(this.rewardsAbi, environment.bsc.refRewardsAddress).methods
      .balances(account)
      .call();
  }
}

function contractEventSourceToObserves(contractEventSource: any) {
    const transactionHashSbj: Subject<string> = new Subject();
    const receiptSbj: Subject<any> = new Subject();
    const errorSbj: Subject<{
        error: any;
        receipt: any;
    }> = new Subject();

    contractEventSource
        .on('error', function (error: any, receipt: any) {
            console.log('contractEventSource error');
            console.log(error);
            errorSbj.error({ error, receipt });
            errorSbj.complete();
        })
        .on('transactionHash', function (hash: any) {
          console.log('transactionHash');
            transactionHashSbj.next(hash);
            transactionHashSbj.complete();
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt');
            receiptSbj.next(receipt);
            receiptSbj.complete();
        });

    const transactionHash$ = transactionHashSbj.asObservable().pipe(takeUntil(errorSbj));

    const receipt$ = receiptSbj.asObservable().pipe(takeUntil(errorSbj));

    const error$ = errorSbj.asObservable();

    return {
        transactionHash$,
        receipt$,
        error$,
    };
}
