<div class="modal-header">
    <h5 class="modal-title">
        <i class="icon icomoon-lock me-12 text-muted"></i>
      {{'stakingNft' | translate}}
    </h5>
    <button class="btn-close" [mat-dialog-close]></button>
</div>
<div class="modal-body">
    <div class="mb-4 progress-group">
        <div class="text-muted">
          {{'byStakingNftYouWillReceiveEllsTokens' | translate}}
        </div>
    </div>
    <button mat-raised-button color="primary" class="w-100" [mat-dialog-close]="true" (click)="confirm()">
      {{'gotIt' | translate}}
    </button>
</div>
