import { Injectable } from '@angular/core';
import { Web3Service } from './web3-service';
import networks from '../app/networks.data';
import { UserSessionProvider } from './user-session-provider';

@Injectable({
    providedIn: 'root',
})
export class NetworkSwitcherService {
    constructor(private web3: Web3Service) {
        
    }

    public async switchToNetwork(chainId: number) : Promise<boolean> {
        if (this.web3.chainIdNumber == chainId) {
            return true;
        }

        var toNetwork = networks.find(n => n.chainId == chainId);
        console.log('to network', toNetwork, chainId)
        const provider = (window as any).ethereum;
        
        if (provider) {
            if (toNetwork.networkParams) {
                try {
                    // @ts-ignore
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [toNetwork.networkParams],
                    });
                    return true;
                } catch (error) {
                    console.error(error);
                    return false;
                }
            } else {
                //this._alertSrv.show(`Select ${toNetwork.name} Network in your wallet.`, 'error');
                return false;
            }
        } else {
            //this.userSessionProvider.setChainId(toNetwork.chainId);
            location.reload();
            return false;
        }
    }
}