<info-join-deals></info-join-deals>
<section class="dashboard-section p-40 __pool-header">
  <div class="card">
    <div class="card-header">
      <div class="token-name">
        <div class="token-name__img">
          <img src="../../assets/images/deals/logo-deal.png" />
        </div>
        <div class="token-name__info">
          <div class="token-title">{{item?.name}}</div>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="pool-progress">
        <div class="progress progress-sm">
          <div class="progress-bar"
               role="progressbar"
               [style.width.%]="getProgressPercent">
          </div>
        </div>
        <div class="progress-legend d-flex justify-content-between">
          <div class="progress-legend__percent">{{getProgressPercent| number: '1.0-2'}}%</div>
          <div class="progress-legend__value">
            {{getCurrentPoolTokenAmount| number: '1.0-2'}} /
            {{item?.poolTokenAmount| shiftDecimals:-item.poolTokenDecimals| number: '1.0-2'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section mb-40">
  <div class="row">
    <div class="col-lg-4 mb-3 mb-lg-0">
      <div class="card p-4">
        <div class="subtitle-1 mb-1">{{'yourStake' | translate}}</div>
        <div class="h6">
          {{currentStakeAmount| shiftDecimals:-item?.stakingTokenDecimals| number: '1.0-2'}} {{item?.stakingTokenSymbol}}
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-3 mb-lg-0">
      <div class="card p-4">
        <div class="subtitle-1 mb-1">{{'yourReward' | translate}}</div>
        <div class="h6">
          {{currentPendingReward| shiftDecimals:-item?.poolTokenDecimals| number: '1.0-2'}} {{item?.poolTokenSymbol}}
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="card p-4">
        <div class="subtitle-1 mb-1">{{'yourPenalty' | translate}}</div>
        <div class="h6">
          {{penaltyBP/100}}%
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section">
  <div class="row">
    <div class="col-lg-8 mb-3 mb-lg-0">
      <div class="card min-h-100 p-4 pb-0">
        <div class="row">
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'stakingToken' | translate}}</div>
            <div class="small-text">
              {{item?.stakingTokenSymbol}}
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'rewardToken' | translate}}</div>
            <div class="small-text">
              {{item?.poolTokenSymbol}}
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'rewardTokenSupply' | translate}}</div>
            <div class="small-text">
              {{item?.poolTokenAmount| shiftDecimals:-item?.poolTokenDecimals| number: '1.0-2'}}
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'startDate' | translate}}</div>
            <div class="small-text">
              {{item?.startTime* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}
            </div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'endDate' | translate}}</div>
            <div class="small-text">
              {{item?.finishTime* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}
            </div>
          </div>
          <!--<div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">Locking Start Date</div>
            <div class="small-text" *ngIf="userLockingStartTimestamp != 0">
              {{userLockingStartTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}
            </div>
            <div class="small-text" *ngIf="userLockingStartTimestamp == 0">
              -
            </div>
          </div>-->
          <!--<div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">APY</div>
            <div class="small-text">-</div>
          </div>-->
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">{{'depositFee' | translate}}</div>
            <div class="small-text">{{item?.depositFeeBP}}%</div>
          </div>
          <div class="col-lg-4 mb-4">
            <div class="caption text-muted mb-1">APR</div>
            <div class="small-text">{{apy| number: '1.0-2'}}%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card p-4 min-h-100">
        <div class="subtitle-1 mb-4">{{'deposit' | translate}}</div>

        <mat-form-field appearance="fill">
          <div class="mat-form-field-img"><img src="/assets/images/icons/GMPD.svg" alt=""></div>
          <mat-label>{{item?.stakingTokenSymbol}}</mat-label>
          <input matInput
                 type="number"
                 [(ngModel)]="amountToDeposit"
                 min="0"
                 max="{{getIntegerPart(balance)}}">
          <button mat-button
                  color="primary"
                  class="max-btn"
                  (click)="setMaxDepositAmount()">MAX</button>
          <mat-hint class="text-right">Max: {{getIntegerPart(balance)}}</mat-hint>
        </mat-form-field>

        <div class="btn-group-justified checkmark-detail mt-4">
          <button mat-stroked-button
                  class="btn-icon approve-deposit"
                  *ngIf="step==1 && staked"
                  [disabled]="waiting"
                  (click)="withdrawDialog()">
            <i class="icon icomoon-down icomoon-down-transorm"></i>
            {{'withdraw' | translate}}
          </button>

          <button mat-stroked-button color="primary" class="btn-icon icon-opaciti-1" *ngIf="step==1" [disabled]="waiting"
            (click)="depositDialog()">
            <i class="icon icomoon-down"></i>
            {{'deposit' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="dashboard-section p-40">
  <div class="row">
    <div class="col-lg-8 mb-3 mb-lg-0">
      <div class="card min-h-100">
        <div class="card-header d-flex align-items-center">
          <div class="subtitle-1">
            <i class="icon icomoon-chart-down me-2 text-muted"></i>
            {{'penalties' | translate}}
          </div>

          <span class="info-tooltip icon icomoon-info ms-auto"
                matTooltipPosition="above"
                matTooltip="{{'thereArePenaltiesYouUnlock' | translate}}">
          </span>
        </div>
        <div class="card-content p-0">
          <ng-container *ngIf="stakingPoolAddress == '0x9c940c112a0e5412df3c0903f7fb87f3206a0e35'">
            <table class="table penalties-table mb-0">
              <tr>
                <th>{{ 'untilDate1' | translate }}</th>
                <td>30%</td>
              </tr>
            </table>
          </ng-container>
          <ng-container *ngIf="stakingPoolAddress == '0xdccb9e14d17f3f7e29490fc72d4e135a2bc8b937'">
            <table class="table penalties-table mb-0">
              <tr>
                <th>{{ 'untilDate2' | translate }}</th>
                <td>30%</td>
              </tr>
            </table>
          </ng-container>
          <ng-container *ngIf="stakingPoolAddress != '0x9c940c112a0e5412df3c0903f7fb87f3206a0e35' && stakingPoolAddress != '0xdccb9e14d17f3f7e29490fc72d4e135a2bc8b937'">
            <table class="table penalties-table mb-0">
              <tr *ngFor="let currentPenalty of allPenalties; index as i;">
                <th>{{'lessThan' | translate}} {{getTranslatedShowPeriod(currentPenalty.duration)}}</th>
                <td>{{currentPenalty.penaltyBP/100}}%</td>
              </tr>
              <tr *ngIf="allPenalties.length==5">
                <th>{{'after' | translate}} {{getTranslatedShowPeriod(allPenalties[4].duration)}}</th>
                <td>0%</td>
              </tr>
            </table>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card min-h-100 p-4">
        <div class="subtitle-1 mb-3">{{'description' | translate}}</div>
        <div class="small-text opacit">{{item?.description}}</div>
      </div>
    </div>
  </div>
</section>
