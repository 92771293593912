import { Component, OnInit } from "@angular/core";
import { PollsServiceProxy } from "../../service-proxies/service-proxies";
import { ComponentBase } from "../../shared/component-base";
import { Router } from "@angular/router";
import { UserSessionProvider } from "../../shared/user-session-provider";
import { BigNumber } from "bignumber.js";
import { Web3Service } from "../../shared/web3-service";
import { DatePipe } from "@angular/common";
import { StakingService } from "src/shared/staking-service";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: 'app-user-polls',
  templateUrl: './user-polls.component.html',
  styleUrls: ['./user-polls.component.scss'],
})

export class UserPollsComponent extends ComponentBase implements OnInit {
  constructor(private pollsService: PollsServiceProxy,
              private router: Router,
              private userSessionProvider: UserSessionProvider,
              private web3Service: Web3Service,
              private stakingService: StakingService,
              private datePipe: DatePipe,
              public translate: TranslateService) {
    super();
  }

  public get canVote(): boolean {
    return this.userSessionProvider.linkedWallet && this.userTierIndex >= 0;
  }

  public polls: any = [];
  public userTierIndex: number = -1;
  public nftType: number = -1;
  allTiers: any[] = new Array();

  pollsWithUserVotedProperty: any;

  async ngOnInit(): Promise<void> {
    let nftId = await this.web3Service.getLockedNFT(this.userSessionProvider.linkedWallet);
    this.nftType = nftId > 0 ? await this.web3Service.getNFTTYpe(this.userSessionProvider.linkedWallet, nftId) : -1;
    console.log('>>> has NFT: ', this.nftType);

    this.updateUserTier();
    await this.getPolls();
  }

  async getPolls(): Promise<void> {
    this.polls = await this.pollsService.getPolls().toPromise();
    this.polls = this.polls.filter((poll: any) => poll.isVisible === true);
    this.pollsWithUserVotedProperty = this.addHasUserVotedPropertyToPolls(this.polls, this.userSessionProvider.linkedWallet);
    this.pollsWithUserVotedProperty.forEach((poll: any) => {
      const totalVotes = poll.votes.length;
      poll.options.forEach((option: any) => {
        const votesForOption = poll.votes.filter((vote: any) => vote.pollOptionId === option.id).length;
        option.percentage = totalVotes > 0 ? parseFloat(String((votesForOption / totalVotes) * 100)).toFixed(0) : 0;
      });
    });
    this.pollsWithUserVotedProperty.sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
  }

  makeVote(option: any): void {
    const params = {
      pollId: option.pollId,
      optionId: option.id,
      userWallet: this.userSessionProvider.linkedWallet,
    };
    this.pollsService.makeVote(params).subscribe(
      res => {
        this.getPolls();
      },
      error => {
        console.log(error);
      },
    );
  }

  isUserVoted(votes: any[], userWallet: string): boolean {
    return votes.some(vote => vote.userWallet === userWallet);
  }

  addHasUserVotedPropertyToPolls(polls: any[], userWallet: string): any[] {
    return polls.map(poll => {
      const hasUserVoted = this.isUserVoted(poll.votes, userWallet);
      return { ...poll, userVoted: hasUserVoted };
    });
  }

  optionUserVotedFor(optionId: number): boolean {
    return this.polls.some((poll: any) => poll.votes.some((vote: any) => vote.pollOptionId === optionId && vote.userWallet?.toLowerCase() === this.userSessionProvider.linkedWallet?.toLowerCase()));
  }

  async updateUserTier(): Promise<void> {
    const account = this.userSessionProvider.linkedWallet;
    if (!account) {
      return;
    }

    const tiesrLenght = parseInt(await this.web3Service.getDealLockupsTiersLength());
    for (let i = 0; i < tiesrLenght; i++) {
      const tier = await this.web3Service.getDealLockupsTiers(i);
      this.allTiers.push(tier);
    }

    const lockedTotalBalance = await this.web3Service.getLockedTokenAmountTierCalculator(this.web3Service.tierCalculatorAddress, account);
    const lockedBalanceBn = new BigNumber(lockedTotalBalance);

    const tierIndex = this.getUserTier(lockedBalanceBn);
    const nftTierIndex = this.getUserNftTier(lockedBalanceBn);

    if (nftTierIndex >= tierIndex) {
        this.userTierIndex = nftTierIndex;
    } else {
        this.userTierIndex = tierIndex;
    }

    console.log(' >>> userTierIndex', this.userTierIndex);
  }

  private getUserTier(balance: BigNumber): number {
    let tierIndex = 0;
    let maxTier = 0;
    let success = false;

    for (let i = 0; i < this.allTiers.length; i++) {
        maxTier = this.allTiers[i].gmpdOnlyAmount;
        if (
            this.allTiers[i] &&
            balance.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i]?.gmpdOnlyAmount)) &&
            // Check that next level is higher than previous
            new BigNumber(this.allTiers[i].gmpdOnlyAmount).isGreaterThanOrEqualTo(
                new BigNumber(this.allTiers[tierIndex].gmpdOnlyAmount),
            )
        ) {
            tierIndex = i;
            success = true;
        }
    }

    return success ? tierIndex : -1;
  }

  private getUserNftTier(balance: BigNumber): number {
      if (this.nftType < 0) {
          return -1;
      }

      let tierIndex = 0;
      let maxTier = 0;
      let success = false;

      for (let i = 0; i < this.allTiers.length; i++) {
          maxTier = this.allTiers[i].gmpdAmount;
          if (
              this.allTiers[i] &&
              balance.isGreaterThanOrEqualTo(new BigNumber(this.allTiers[i]?.gmpdAmount)) &&
              // Check that next level is higher than previous
              new BigNumber(this.allTiers[i].gmpdAmount).isGreaterThanOrEqualTo(
                  new BigNumber(this.allTiers[tierIndex].gmpdAmount),
              )
          ) {
              tierIndex = i;
              success = true;
          }
      }

      if (success) {
          return this.nftType < tierIndex ? this.nftType : tierIndex;
      } else {
          return -1;
      }
  }

  private async getStackingBalance(): Promise<any[]>{
    return await Promise.all(this.web3Service.getStackingAddresses.map(async poolAddress => await this.getPoolBalance(poolAddress)));
  }

  private async getPoolBalance(poolAddress: string ): Promise<string>{
    const poolBalanceData = await this.web3Service.getPoolUserInfo(this.userSessionProvider.linkedWallet, poolAddress);
    return poolBalanceData?.[0] || 0;
  }

  showLocalDate(date: any): string {
    const utc = date + 'Z';
    return this.datePipe.transform(new Date(utc), 'dd MMM, H:mm');
  }
}
