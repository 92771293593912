<main class="dashboard-main">
  <section class="dashboard-section section-alert">
    <div class="alert alert-warning align-items-lg-center"
         role="alert">
      <div class="alert-icon icomoon-plumpy-lock"></div>
      <div class="alert-heading">
        {{'dealIsLocked' | translate}}
      </div>
      <div class="alert-button ms-auto">
        <button class="btn btn-outline-secondary btn-icon btn-icon-left">
          <i class="icon icomoon-plumpy-key"></i>
          {{'unlockDeal' | translate}}
        </button>
      </div>
    </div>
  </section>

  <section class="pool-detail-section">
    <div class="row">
      <div class="col-xl-6">
        <div class="pool-card mb-20">
          <div class="pool-card__header d-flex align-items-center">
            <img src="../../assets/images/deals/logo-deal.png"
                 alt=""
                 width="44"
                 height="44"
                 class="rounded-circle">

            <div class="col ps-3">
              <div class="d-flex align-items-center">
                <span class="pool-title">Cryptonovae <strong>YAE</strong></span>

                <div class="deal-network ms-auto">
                  <span class="deal-network__name">ETH</span>
                  <img class="deal-network__img"
                       src="../../assets/images/network/eth.svg"
                       alt="">
                </div>
              </div>

              <div class="wallet-address">
                <span class="overflow-ellipsis">0x52b00be38b70B2384bd48eFDa71c45a49467cFEA</span>
              </div>
            </div>
          </div>
          <div class="pool-card__footer">
            <div class="row">
              <div class="col-md-6 mb-4 mb-md-0">
                <div class="label">{{'claimableAmount' | translate}}</div>
                <div class="value">100,000 YAE</div>
              </div>
              <div class="col-md-6">
                <div class="label">{{'claimingProgress' | translate}}</div>
                <div class="pool-progress">
                  <div class="progress progress-sm progress-primary">
                    <div class="progress-bar"
                         role="progressbar"
                         [style.width.%]="87">
                    </div>
                  </div>
                  <div class="progress-legend d-flex justify-content-between">
                    <div class="progress-legend__percent">87%</div>
                    <div class="progress-legend__value">87,352 / 100,000</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-group-justified mt-4">
              <button class="btn btn-primary">{{'claimRewardTokens' | translate}}</button>
              <button class="btn btn-primary">{{'claimAvailableTokens' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="pool-detail-description white-card p-4">
          <div class="text-muted __description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
          </div>
          <a href="#"
             class="btn btn-outline-secondary w-100">{{'originalDealPage' | translate}}</a>
        </div>
      </div>
    </div>
  </section>

</main>
