<section class="redeem-section p-40"
         *ngIf="!availableToClaimNFT">
    <div class="redeem_block">
        <div class="redeem-item">
            <div class="redeem-item-top">
                <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
                         alt="">GMPD NFT</div>
                <div class="redeem-item-status"></div>
            </div>
            <div class="redeem-item-img">
                <img src="/assets/images/redeem/redeem-item.svg"
                     alt="">
            </div>
            <div class="redeem-item-info">
                <!-- <span>
          <input type="text"
                 class="form-control"
                 [(ngModel)]="nftTokenID"
                 placeholder="Token ID">
        </span> -->
            </div>
            <button (click)="checkNFT()"
                    *ngIf="!checking">{{'checkNftToClaim' | translate}}</button>
            <div class="nft_loader_placeholder" *ngIf="checking">
                <mat-spinner class="__loading"
                             diameter="40"></mat-spinner>
            </div>

        </div>

        <!-- <div class="redeem-item">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg" alt="">Cybirg</div>
        <div class="redeem-item-status">Ticket</div>
      </div>
      <div class="redeem-item-img">
        <img src="/assets/images/redeem/redeem-item.svg" alt="">
      </div>
      <div class="redeem-item-info">
        <p>#1273651</p>
        <span>0x67...uqu7</span>
      </div>
      <button>Redeem</button>
    </div>

    <div class="redeem-item">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg" alt="">Android</div>
        <div class="redeem-item-status">Ticket</div>
      </div>
      <div class="redeem-item-img">
        <img src="/assets/images/redeem/redeem-item.svg" alt="">
      </div>
      <div class="redeem-item-info">
        <p>#1273651</p>
        <span>0x67...uqu7</span>
      </div>
      <button>Redeem</button>
    </div> -->
    </div>

    <!-- <section class="redeem-section p-40">
  <div class="redeem_block redeem_owned">
    <div class="redeem-item">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
               alt="">Bot</div>
      </div>
      <div class="redeem-item-img">
        <img src="/assets/images/redeem/redeem-item.svg"
             alt="">
      </div>
      <div class="redeem-item-info">
        <p>#1273651</p>
        <span>0x67...uqu7</span>
      </div>
    </div>

    <div class="redeem-item">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
               alt="">Cybirg</div>
      </div>
      <div class="redeem-item-img">
        <img src="/assets/images/redeem/redeem-item.svg"
             alt="">
      </div>
      <div class="redeem-item-info">
        <p>#1273651</p>
        <span>0x67...uqu7</span>
      </div>
    </div>

    <div class="redeem-item">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
               alt="">Android</div>
      </div>
      <div class="redeem-item-img">
        <img src="/assets/images/redeem/redeem-item.svg"
             alt="">
      </div>
      <div class="redeem-item-info">
        <p>#1273651</p>
        <span>0x67...uqu7</span>
      </div>
    </div>
  </div>
</section> -->
</section>
<section class="redeem-section redeem_ticket p-40"
         *ngIf="availableToClaimNFT && !completed">
    <div class="redeem_block">
        <div class="redeem-item">
            <div class="redeem-item-top">
                <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
                         alt="">{{'binanceVoucher' | translate}} (BRNFT)</div>
                <!-- <div class="redeem-item-status">Ticket</div> -->
            </div>
            <div class="redeem-item-img">
                <img src="/assets/images/redeem/redeem-item.svg"
                     alt="">
            </div>
            <div class="redeem-item-info"
                 *ngIf="selectedNFT" style="justify-content: center;">
                <p>TokenID: {{selectedNFT}}</p>
                <!-- <span>{{ binanceRegularNFTContract | shortAddress }}</span> -->
            </div>
            <div class="redeem-item-info" style="justify-content: center;"
                 *ngIf="userNFTsData.NFTsInfo.length > 1 && !selectedNFT">
                <select [(ngModel)]="selectedNFT" style="padding: 8px 50px 8px 16px;
                color: #FFFFFF;
                border-radius: 80px;
                text-align: left;
                background: #353E51;"
                        (ngModelChange)="selectedNFTChanged($event)">
                    <option disabled="true"
                            value="null"
                            selected="true">{{'selectToken' | translate}}</option>
                    <option *ngFor="let item of userNFTsData?.NFTsInfo; index as i">{{item}}</option>
                </select>
                <!-- {{token/{{binanceRegularNFTContract}}?a=userNFTsData.NFTsInfo[0]}}
        <a [href]=""><span>{{ binanceRegularNFTContract | shortAddress }}</span></a> -->
            </div>
        </div>

        <div class="take"></div>

        <div class="redeem-item redeem-item-in"
             *ngIf="selectedNFT">
            <div class="redeem-item-top">
                <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
                         alt="">GMPD {{selectedNFTType}}</div>
            </div>
            <div class="redeem-item-img no-afer">
                <img src="/assets/images/redeem/redeem-item.svg"
                     alt="">
            </div>
            <div class="redeem-item-info">
            </div>
        </div>

        <div class="ticket"
             *ngIf="selectedNFT">
            <div class="rt_info">
                <h3>{{'redeemTicket' | translate}}</h3>
                <ul>
                    <li>{{'theAboveTicketBinanceExchangedForNft' | translate}}</li>
                    <li>{{'theNftTransferredToUserConnectedWallet' | translate}}</li>
                    <li>{{'thisProcessIrreversible' | translate}}</li>
                </ul>
            </div>

            <div class="rt-nav">
                <button class="prev"
                        *ngIf="userNFTsData.NFTsInfo.length > 1"
                        (click)="backActionClick()">{{'back' | translate}}</button>
                <button class="next"
                        (click)="confirmSwap()">{{'confirm' | translate}}</button>
            </div>
        </div>
    </div>
</section>


<section class="redeem-section redeem_ticket_success p-40" *ngIf="completed">
  <div class="redeem_block">
    <div class="redeem-item redeem-item-in">
      <div class="redeem-item-top">
        <div class="redeem-item-title"><img src="/assets/images/binance-new.svg"
               alt="">GMPD {{selectedNFTType}}</div>
      </div>
      <div class="redeem-item-img no-afer">
        <img src="/assets/images/redeem/redeem-item.svg"
             alt="">
      </div>
      <div class="redeem-item-info">
      </div>
    </div>

    <div class="success">
      <div class="info">
        <p>{{'youHaveSuccessfullyRedeemedNft' | translate}}</p>
      </div>
      <!-- <div class="rt-nav">
        <button class="prev">Back to Redeem</button>
        <button class="next">My NFTs</button>
      </div> -->
    </div>

  </div>
</section>
