import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.PaybackEventsServiceProxy,
        ApiServiceProxies.TransitEventServiceProxy,
        ApiServiceProxies.DealServiceProxy,
        ApiServiceProxies.ClaimingDealServiceProxy,
        ApiServiceProxies.AddressBookServiceProxy,
        ApiServiceProxies.StakingPoolServiceProxy,
        ApiServiceProxies.UsersServiceProxy,
        ApiServiceProxies.UserMessagesServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
    ],
})
export class ServiceProxyModule {}
