import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StakingPoolDTO, StakingPoolServiceProxy, StakingPoolUpdateDTO } from 'src/service-proxies/service-proxies';
import { ComponentBase } from 'src/shared/component-base';
import { EventBus } from 'src/shared/event-bus';
import { NetworkNamePipe } from 'src/shared/pipes/networkName.pipe';
import { UserSessionProvider } from 'src/shared/user-session-provider';
import { Web3Service } from 'src/shared/web3-service';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './edit-pool.component.html',
    styleUrls: ['./edit-pool.component.scss'],
})
export class EditPoolComponent extends ComponentBase implements OnInit {
    constructor(
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private stakingPoolService: StakingPoolServiceProxy,
        private router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
    ) {
        super();
    }

    waiting: boolean;
    item: StakingPoolDTO;
    poolAddress: string;
    account: string;

    poolName: string;
    poolDescription: string;
    startTime: number;
    finishTime: number;
    hasWhiteListing: boolean;
    poolTokenAmount: string;
    depositFeeBP: number;
    feeTo: string;
    visibleForUsers: boolean;

    feeAmount: number;
    feeToken: string;

    stakingToken: string;
    poolToken: string;

    async ngOnInit(): Promise<void> {
        await this.web3Service.initWeb3();

        this.route.queryParams.subscribe(params => {
            this.poolAddress = params['address'];
            console.log(`staking pool address: ${this.poolAddress}`);
            this.getStakingPoolDTO();
            this.getFeeAmount();
        });

        this.eventBus.loginEvent.subscribe(result => {
            console.log('loginEvent subscription:' + result);
            this.eventLogin(result);
        });

        this.eventBus.logoutEvent.subscribe(result => {
            console.log('logoutEvent subscription:' + result);
            this.eventLogout();
        });

        //this.eventBus.outputNetworkChanged.subscribe(result => {
        //  console.log('outputNetworkChanged subscription:' + result);
        //  this.setToNetworkAndPairTo(result);
        //});

        //this.eventBus.fromPairChanged.subscribe(result => {
        //  console.log('fromPairChanged subscription:' + result);
        //  this.setPairFrom(result);
        //});
        this.account = this.userSessionProvider.linkedWallet;
    }

    async getStakingPoolDTO(): Promise<void> {
        this.stakingPoolService.getByAddress(this.poolAddress).subscribe(
            result => {
                this.item = result;
                this.depositFeeBP = result.depositFeeBP;
                this.startTime = result.startTime;
                this.finishTime = result.finishTime;
                this.hasWhiteListing = result.hasWhitelisting;
                this.stakingToken = result.stakingToken;
                this.poolToken = result.poolToken;
                this.poolTokenAmount = (parseInt(result.poolTokenAmount) / 10 ** 18).toString();
                this.poolName = result.name;
                this.poolDescription = result.description;
                // this.visibleForUsers = result.visibleForUsers;

                console.log(this.item);
                // if (this.web3Service.chainIdNumber != this.item.chainId) {
                //   let chainName = new NetworkNamePipe().transform(this.item.chainId);
                //   this.showErrorModal(`Select ${chainName} network in metamask!`);
                //   this.router.navigate(["/staking"]);
                // }
            },
            error => {
                this.processServiceError(error);
            },
        );
    }

    async updateDealDb(): Promise<void> {
        this.waiting = true;

        var data: StakingPoolUpdateDTO = new StakingPoolUpdateDTO();
        data.poolAddress = this.poolAddress;
        data.name = this.poolName;
        data.description = this.poolDescription;
        data.visibleForUsers = this.visibleForUsers;

        this.waiting = true;

        this.stakingPoolService.update(data).subscribe(
            result => {
                this.waiting = false;
                this.showInfoModal(this.translate.instant('saved'));
            },
            error => {
                this.waiting = false;
                this.processServiceError(error);
            },
        );
    }

    async getFeeAmount(): Promise<void> {
        this.feeAmount = (await this.web3Service.GetStakeMasterFeeAmount()) / 10 ** 18;
        console.log(this.feeAmount);
    }

    eventLogin(username: string): void {
        console.log('eventLogin');
        console.log(username);
        this.account = username;
    }

    eventLogout(): void {
        this.account = '';
    }

    processServiceError(error: any) {
        if (error.status == 401) {
            console.error('401');
            this.userSessionProvider.finishAuth();
            this.navigateToLogin();
        } else {
            console.error(error);
            this.showErrorModal(JSON.parse(error.response).message);
        }
    }

    navigateToLogin(): void {
        this.router.navigate(['/login']);
    }
}
