export const environment = {
    production: false,
    tokenInfo: {
        address: '0xf4949D4f888641399ADb83b2b25f44b276c8E94B',
        symbol: 'GMPD',
    },
    nftInfo: {
        address: '0xe31431b4e3d0876046f11e2fd36822f35357ab43',
    },
    smgAPI:{
        endpoint: "https://smg-api.vercel.app/api/testnet/smgID",
    },
    bsc: {
        dealCreatorAddress: '0x0A6083251F3b5fa1086b1968DF81568B2Ba4a8c4',
        dealCollectWalletCreatorAddress: '0x200B8a294f2252d2125EAafAE1a3C8FA70066fA1',
        lockerAddress: '0x5C3A34DC25896e914120b8E52E0eB2505a16BfA2',
        dealLockupsAddress: '0x8DeEe0a35f2270d251ba9d3bB291c2BF43766114',
        vestingStrategicAddress: '0xaeeca8a94298769f5afb33f21909255ad5888e1d',
        vestingPrivateAddress: '0xcf439554b7b5e8bfff932b9c3454d0d92627700c',
        stakingPoolMasterAddress: '0x651178bb671A7d49a878c5556b1276Cb1438f51D',
        pancakeRouterAddress: '0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3',
        tierCalculatorAddress: '0x2524AaE0FAD286E1e03C8C7eddBF8e5716127FEE',
        // tslint:disable-next-line:max-line-length
        stackingAddress: ['0xffe8787731df4a11f8f373136f050b416f6d1f32'], // '0x5f3762f245fdaf36f743e6f8e25c76ad88adca30',
        farmingAddress: '0x73e10693f02af2a295aaa778de72d87a81f0f251', // '0xb56a36C0AbF08324F395D8c724E8Ad10F247827C',
        bonusAddress: '0x97BbcB9809771f903fda94Cec9ADAe7d5ac25D9c',
        refRewardsAddress: '0x4aD03272cd0E31E4f5fe938E0f6432d7DE56DB29',
    },
    eth: {
        nftStakingAddress: '0x2c6743FC917EBF0820C03dEb70b2A791cc13a805',
        nft: '0x3a17a676FE4f2464e84E57962956C99290B42f0d',
        chainId: 5
    },
    networkTypes: [
        { id: 1, label: 'ETH', env: 'prod' },
        { id: 5, label: 'ETH goerli', env: 'dev' },
        { id: 56, label: 'BNB chain', env: 'prod' },
        { id: 137, label: 'Polygon', env: 'prod' },
        { id: -100, label: 'Solana', env: 'prod' },
        { id: -200, label: 'Tezos'},
        { id: 97, label: 'BSC Testnet', env: 'dev' },
        { id: 43114, label: 'Avalanche', evn: 'dev' },
        { id: 250, label: 'Fantom' },
        { id: 321, label: 'KCC'},
        { id: 80001, label: 'Polygon Testnet' },
        // { id: 322, label: 'KCCTest'},
        { id: -9999100, label: 'Elrond' },
        { id: -9999101, label: 'Cardano' },
        // { id: -9999102, label: 'Other' },
        { id: -9999103, label: 'Polkadot' },
        { id: -9999104, label: 'Cosmos' },
        { id: -9999105, label: 'Flow' },
        { id: -9999106, label: 'Near' },
        { id: 344435, label: 'Skale'},
        { id: 888, label: 'Wanchain'},
        { id: 1666600000, label: 'Harmony'},
        { id: 66, label: 'OKC'},
        { id: 42161, label: 'Arbitrum'},
        { id: -9999107, label: 'Sui'},
        { id: -9999108, label: 'Bitcoin'},
        { id: -9999109, label: 'Aptos'},
        { id: 10, label: 'Optimism'},
        { id: 8453, label: 'Base'},
        { id: 1284, label: 'Moonbeam'},
        { id: 592, label: 'Astar'},
        { id: 1890, label: 'Lightlink'},
        { id: -9999110, label: 'TON'},
        { id: 81457, label: 'Blast'},
        { id: -9999111, label: 'Injective'},
    ],
    extendedPools: [
      { address: '', previousAmount: 0, previousFinishTime: 0 },
      { address: '', previousAmount: 0, previousFinishTime: 0 }
    ],
    remoteServiceBaseUrl: 'https://clientapi-dev.gpkit.link',
    authServiceBaseUrl: 'https://bullauth.gpkit.link',
    defaultChainId: 97,
    supportedChains: [1, 5, 56, 137, 97, 42, 80001, 43114],
    captchaSiteKey: '6LfnBTceAAAAAMB1qOZza4EKnQjC_QMHJeGwJUoA',
    defaultClaimingUrl: "https://bullclaimer.com",
    referralProgramVideoUrl: "https://www.youtube.com/embed/VbjYc7Zn-cQ"
};
