<section class="dashboard-section p-40">
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="subtitle">{{'vestingSchedule' | translate}}</div>
        </div>
        <div class="card-content p-0">
          <table class="table w-100">
            <tr>
              <td>{{'tgeTime' | translate}}</td>
              <td>{{tgeTime* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
            </tr>
            <tr>
              <td>{{'tgeBalance' | translate}}</td>
              <td>{{usersTgeBalance | number: '1.0-4'}} {{rewardTokenSymbol}} </td>
            </tr>
            <tr>
              <td>{{'tgeReleased' | translate}}</td>
              <td>{{usersTgeReleased | number: '1.0-4'}} {{rewardTokenSymbol}} </td>
            </tr>
            <tr *ngIf="usersTgeBalance && now >tgeTime">
              <td colspan="2">
                <button mat-raised-button
                        color="primary"
                        class="w-100 btn-icon btn-icon-left"
                        (click)="releaseTGEClick()"
                        [ngClass]="{'btn-loading': waiting}"
                        [disabled]="waiting">
                  <i class="icon icomoon-claim"></i>
                  {{'claimTge' | translate}}
                </button>
              </td>
            </tr>
            <tr>
              <td>{{'vestedAmount' | translate}}</td>
              <td>{{usersBalance | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr>
              <td>{{'releasedAmount' | translate}}</td>
              <td>{{usersReleased | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr>
              <td>{{'releasableAmount' | translate}}</td>
              <td>{{usersReleasableAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="usersReleasableAmount">
              <td colspan="2">
                <button mat-raised-button
                        color="primary"
                        class="w-100 btn-icon btn-icon-left"
                        (click)="claimVesingClick()"
                        [ngClass]="{'btn-loading': waiting}"
                        [disabled]="waiting">
                  <i class="icon icomoon-claim"></i>
                  {{'claimYourVestingTokens' | translate}}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <div class="subtitle">{{'vestingInformation' | translate}}</div>
        </div>
        <div class="card-content p-0">
          <table class="table w-100">
            <tr>
              <td>{{'tgeVesting' | translate}} %</td>
              <td>{{vestingTGEPercent}}%</td>
            </tr>
            <tr>
              <td>{{'tgeTime' | translate}}</td>
              <td>{{tgeTime* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
            </tr>
            <tr>
              <td>{{'vestingStartDate' | translate}}</td>
              <td>{{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
            </tr>
            <tr>
              <td>{{'vestingDuration' | translate}}</td>
              <td>{{getTranslatedShowPeriod(vestingDuration)}}</td>
            </tr>
            <tr>
              <td>{{'vestingInterval' | translate}}</td>
              <td> {{getTranslatedShowPeriod(vestingInterval)}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
