import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import { UsersServiceProxy, KycaidDTO } from '../../service-proxies/service-proxies';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

declare var ga: any;

@Component({
    templateUrl: './kyc.component.html',
    styleUrls: ['./kyc.component.scss'],
})
export class KYCPageComponent extends ComponentBase implements OnInit {
    constructor(
        private _alertSrv: AlertService,
        private usersService: UsersServiceProxy,
        private userSessionProvider: UserSessionProvider,
        private sanitizer: DomSanitizer,
        private router: Router
    ) {
        super();
    }

    kycaidSettings: KycaidDTO;
    kycaidFormUrl: SafeUrl;
    waiting: boolean = false;
    
    async ngOnInit() {
        if (this.userSessionProvider.authToken) {
            this.getMyParams();
        } else {
            console.error('Not Auth. redirect to login page');
            this.router.navigate(['/login']);
        }

      ga.getAll().forEach( (tracker: any) => {
        const id = tracker.get('clientId');
        if (this.userSessionProvider.linkedWallet){
          this.usersService.saveCid(id, 'kyc_request', this.userSessionProvider.linkedWallet)
            .subscribe();
        }
      });
    }

    getMyParams(): void {
        this.usersService.getKycaidSettings().subscribe(
            result => {
                this.kycaidSettings = result;
                this.kycaidFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(result.formUrl);
            },
            error => {
                if (error.status == 401) {
                    this.userSessionProvider.finishAuth();
                    console.error('401');
                    this.logout();
                } else this._alertSrv.show(JSON.parse(error.response).message, 'error');
                //this.showErrorModal(JSON.parse(error.response).message);
            },
        );
    }

    logout(): void {
        console.error('logout');
        this.router.navigate(['/login']);
    }

    initKycaidIframe(): void {
        // let snsWebSdkInstance = snsWebSdk.init(
        //     this.sumSupSettings.accessToken,
        //     () => Promise.resolve(this.sumSupSettings.accessToken)
        // )
        //     .withConf({
        //         lang: 'en',
        //         email: this.sumSupSettings.userEmail,
        //         uiConf: {
        //             customCssStr: ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}'
        //         }
        //     })
        //     .on('idCheck.onError', (error) => {
        //         console.log('onError', error);
        //     })
        //     .onMessage((type, payload) => {
        //         console.log('onMessage', type, payload);
        //     })
        //     .build();

        // snsWebSdkInstance.launch('#sumsub-websdk-container');
    }
}
