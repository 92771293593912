export function CatchWrapper (
    target: any,
    propertyKey: string, 
    descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>
  ) 
  {
    const fn = descriptor.value;
    descriptor.value = async function (...args: any) {
          return fn.apply(this, args).then(r=>r).catch(e=>{
          console.log('error', fn.name, e);
          return undefined;
          //reject(undefined)
        });
      //}) as Promise<any>;
    }
    return descriptor;
  }