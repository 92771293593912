<section class="section-alert"
         *ngIf="1<0">
    <div class="alert alert-warning"
         role="alert">
        <div class="alert-icon icomoon-info"></div>
        <div class="alert-heading">
          {{'warningTextAdmins' | translate}}
        </div>
    </div>
</section>

<info-join-deals></info-join-deals>

<section class="dashboard-section p-40">
    <div class="row mb-40 ">
        <div class="col-lg-5 mb-4 mb-lg-0 dashboard-section-grid">
            <div class="card p-4 mb-20">
                <div class="subtitle-1 mb-4 d-flex align-items-center">
                    <i class="icon icomoon-wallet me-12 text-muted"></i>
                  {{'wallet' | translate}}
                </div>
                <div class="h5 mb-4">{{userWalletBalance| shiftDecimals:-18| number: '1.0-2'}} {{tokenInfo.symbol}}</div>

                <button mat-stroked-button
                        class="w-100 mb-2"
                        (click)="showWarningInfoAndLockTokenClick()">
                  {{'lockInTokens' | translate}}
                </button>

                <a routerLink="/staking"
                   mat-raised-button
                   color="primary"
                   class="w-100 mb-2">
                  {{'stakeTokens' | translate}}
                </a>

                <a routerLink="/swap-bridge"
                   mat-raised-button
                   color="primary"
                   class="w-100 wallet-btn buy_gmpd_main">
                  {{'buy' | translate}} {{tokenInfo.symbol}}
                </a>

                <a href="https://marketplace.gamespad.io"
                   target="_blank"
                   mat-raised-button
                   color="primary"
                   class="w-100 wallet-btn buy_nft_main">
                    {{'buy' | translate}} NFT
                </a>

              <a routerLink="/swap-bridge"
                 mat-stroked-button
                 class="w-100 mt-2">
                {{'swap' | translate}}
              </a>
            </div>

            <div class="card p-4">
                <div class="subtitle-1 mb-4 d-flex align-items-center">
                    <i class="icon icomoon-lock me-12 text-muted"></i>
                  {{'lockedInTokens' | translate}}
                </div>
                <div class="h5 mb-4">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} {{tokenInfo.symbol}}</div>

                <div class="__unlock-group mb-4 d-flex align-items-center">
                    <button mat-stroked-button
                            class="__unlock-tokens unlock_tokens_main"
                            (click)="unlockTokenClick()">
                      {{'unlockTokens' | translate}}
                    </button>
                    <button mat-icon-button
                            class="__unlock-info"
                            (click)="openUnlockInfoDlg()">
                        <i class="icomoon-info"></i>
                    </button>
                </div>
                <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted">
                    <div>{{'lockingStartDate' | translate}}</div>
                    <div *ngIf="userLockingStartTimestamp != 0">{{userLockingStartTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
                    <div *ngIf="userLockingStartTimestamp == 0">-</div>
                </div>
                <div class="__unlock-date d-lg-flex align-items-center justify-content-between text-muted"
                     *ngIf="0<needToWaitTime">
                    <div>{{'timeLockupEnds' | translate}}</div>
                    <div>{{getTranslatedShowPeriod(needToWaitTime)}}</div>
                </div>
            </div>
          <div class="card p-4 mt-4" *ngIf="+bonusBalance > 0">
            <div class="subtitle-1 mb-4 d-flex align-items-center">
              <i class="icon icomoon-wallet me-12 text-muted"></i>
              {{'specialTokens' | translate}}
            </div>
            <div class="h5 mb-4">{{bonusBalance| shiftDecimals:-18| number: '1.0-20'}} GMPD</div>
          </div>
        </div>

        <div class="col-lg-7">
            <div class="card min-h-100">
                <div class="card-header">
                    <div class="subtitle-1 d-flex align-items-center">
                        <i class="icon icomoon-chart-pie me-12 text-muted"></i>
                      {{'summary' | translate}}
                    </div>
                </div>

                <div class="card-content">
                    <div class="caption text-muted mb-1">{{'currentTier' | translate}}</div>
                    <div class="__current-tier">
                      <img [src]="userTierIndex | imgTierPipe" alt="" />
                      <span class="subtitle-1">{{getTranslatedTier(userTierIndex)}}</span>
                    </div>

                    <div class="progress-group mb-1">
                      <div class="progress-legend">
                        <div class="progress-legend__value">
                          <span *ngIf="!isMaxTier">{{nextTierNeedValue | shiftDecimals:-18| number: '1.0-2'}} GMPD {{'forNextTier' | translate}}</span>
                        </div>
                      </div>
                      <div class="progress progress-xs">
                        <div class="progress-bar"
                             role="progressbar"
                             [style.width.%]="currentTierProgress">
                        </div>
                      </div>
                      <div class="progress-legend">
                        <div class="progress-legend__percent">{{totalUserGmpdBalance| shiftDecimals:-18| number: '1.0-2'}} GMPD</div>
                        <div class="progress-legend__value">{{ nextTierValue | shiftDecimals:-18| number: '1.0-2'}} GMPD</div>
                      </div>
                    </div>
                </div>

                <div class="card-content-new">
                    <div class="grid-content-item">
                        <div class="caption text-muted mb-1">{{'nextTier' | translate}}</div>
                        <div class="__current-tier">
                          <img [src]="userTierIndex+1 | imgTierPipe"
                               alt="" />
                          <span class="subtitle-1">{{getTranslatedTier(userTierIndex+1)}}</span>
                      </div>
                    </div>

                    <div class="grid-content-item">
                        <div class="caption text-muted mb-1">GMPD {{'tokens' | translate}}</div>
                        <div class="__current-tier">
                            <div class="progress-tocens"></div>
                            <span class="subtitle-1">{{lockerUserBalance| shiftDecimals:-18| number: '1.0-2'}} <span>/ {{ nextTierValue | shiftDecimals:-18| number: '1.0-2'}}</span></span>
                        </div>
                    </div>

                    <div *ngIf="isNFTLocked" class="grid-content-item">
                        <div class="caption text-muted mb-1">{{getTranslatedTier(userTierIndex+1)}} NFTs</div>
                        <div class="__current-tier">
                            <span class="subtitle-1">0/1</span>
                        </div>
                    </div>

                  <div *ngIf="+bonusBalance > 0" class="grid-content-item">
                        <div class="caption text-muted mb-1">{{ 'specialTokens' | translate }}</div>
                        <div class="__current-tier">
                            <span class="subtitle-1">{{bonusBalance| shiftDecimals:-18| number: '1.0-20'}} GMPD</span>
                        </div>
                    </div>
                </div>

                <div class="card-footer">

                    <div class="__graph pt-3">
                        <div class="__graph__donut d-none d-lg-block">
                            <chart *ngIf="isAuthorized && chartData"
                                   type="doughnut"
                                   [data]="chartData"
                                   [options]="chartOptions"></chart>
                            <div class="dm-donut --placeholder"
                                 *ngIf="!isAuthorized || !chartData"></div>
                        </div>
                        <div class="__graph__legend">
                            <div>
                                <div class="legend-item">
                                    <span class="legend-item__point"
                                          style="background:#131BCB"></span>
                                    <span class="legend-item__title"><a routerLink="staking">{{'staking' | translate}} {{stakingPercent}}%</a></span>
                                    <span class="legend-item__blp">{{stakingUserBalance | shiftDecimals:-18| number: '1.0-2'}} GMPD</span>
                                </div>
                                <div class="legend-item">
                                    <span class="legend-item__point"
                                          style="background:#b3b04f"></span>
                                    <span class="legend-item__title"><a routerLink="farming">{{'farming' | translate}} {{farmingPercent}}%</a></span>
                                    <span class="legend-item__blp">{{farmingUserBalance | shiftDecimals:-18| number: '1.0-2'}} GMPD</span>
                                </div>
                                <div class="legend-item">
                                    <span class="legend-item__point"
                                          style="background:#31C2B9"></span>
                                    <span class="legend-item__title">{{'lockedIn' | translate}} {{lockedBalancePercent}}%</span>
                                    <span class="legend-item__blp">{{lockedWithBonusValue| shiftDecimals:-18| number: '1.0-2'}} GMPD</span>
                                </div>
                            </div>
                            <div class="legend-item">
                                <span class="legend-item__title">{{'total' | translate}}</span>
                                <span class="legend-item__blp">{{totalUserLockedBalance| shiftDecimals:-18| number: '1.0-2'}} GMPD</span>
                            </div>
                        </div>
                    </div>
                </div>

              <div class="card-footer" *ngIf="+bonusBalance > 0">
                <div class="card min-h-100">
                  <div class="subtitle-1 mb-4 d-flex align-items-center">
                    <i class="icon icomoon-wallet me-12 text-muted"></i>
                    {{ 'specialTierInstructions' | translate }}
                  </div>
                  <div class="bonus-tokens-block">
                    <div class="bonus-tokens-items">
                      <div class="bonus-token-item">
                        {{ 'specialTokensItem1' | translate }}
                      </div>
                      <div class="bonus-token-item">
                        {{ 'specialTokensItem2' | translate: {bonusInterval} }}
                      </div>
                      <div class="bonus-token-item">
                        {{ 'specialTokensItem3' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>

    <!--<div class="row mb-40" *ngIf="bonusBalance">
      <div class="col-lg-7 mb-4 mb-lg-0 order-lg-2">
        <div class="card p-4 min-h-100">
          <div class="subtitle-1 mb-4 d-flex align-items-center">
            <i class="icon icomoon-wallet me-12 text-muted"></i>
            {{ 'specialTierInstructions' | translate }}
          </div>
          <div class="bonus-tokens-block">
            <div class="bonus-tokens-items">
              <div class="bonus-token-item">
                {{ 'specialTokensItem1' | translate }}
              </div>
              <div class="bonus-token-item">
                {{ 'specialTokensItem2' | translate: {bonusInterval} }}
              </div>
              <div class="bonus-token-item">
                {{ 'specialTokensItem3' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

    <div class="row mb-40">
        <div class="col-lg-5 mb-4 mb-lg-0 order-lg-2 grid-d">

            <div class="card">
                <div class="card-header">
                    <div class="subtitle-1 d-flex align-items-center">
                      {{'nfts' | translate}}
                    </div>
                </div>
                <div class="card-content" *ngIf="!isAuthorized">

                  <div class="__tiers-list nfts__tiers-list">

                      <div class="nfts-block" style="display: flex;">
                          <div class="__tiers-list-item">
                              <div class="icon"><img src="/assets/images/icons/nft-c.svg"
                                       alt=""></div>
                              <img src="/assets/images/icons/Bot.png"
                                   alt="">
                              <div class="info">{{'bot' | translate}}</div>
                          </div>

                          <div class="__tiers-list-item ">
                              <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                       alt=""></div>
                              <img src="/assets/images/icons/cyborg.png"
                                   alt="">
                              <div class="info">{{'cyborg' | translate}}</div>
                          </div>

                          <div class="__tiers-list-item ">
                              <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                       alt=""></div>
                              <img src="/assets/images/icons/android.png"
                                   alt="">
                              <div class="info">{{'android' | translate}}</div>
                          </div>

                          <div class="__tiers-list-item ">
                              <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                       alt=""></div>
                              <img src="/assets/images/icons/humanoid.png"
                                   alt="">
                              <div class="info">{{'humanoid' | translate}}</div>
                          </div>

                          <div class="__tiers-list-item ">
                              <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                       alt=""></div>
                              <img src="/assets/images/icons/ninja.png"
                                   alt="">
                              <div class="info">{{'cryptoNinja' | translate}}</div>
                          </div>
                      </div>
                  </div>
              </div>
                <div class="card-content" *ngIf="isAuthorized">
                    <div class="nft_loader_placeholder"
                         *ngIf="isLoadingNFT">
                        <mat-spinner class="__loading"
                                     diameter="40"></mat-spinner>
                    </div>

                    <div class="__tiers-list nfts__tiers-list"
                         *ngIf="!isLoadingNFT">

                        <div class="nfts-block"
                             *ngIf="isNFTLocked || isNFTStaked">
                            <div class="__tiers-list-item activ"
                                 *ngIf="nftType == 0">
                                <!-- <div class="icon"><img src="/assets/images/icons/nft-c.svg"
                                         alt=""></div>
                                <img src="/assets/images/icons/Bot.png"
                                     alt="">
                                <div class="info">Bot</div> -->
                                <img src="/assets/images/nfts/bot.png" alt="Bot">
                            </div>

                            <div class="__tiers-list-item activ"
                                 *ngIf="nftType == 1">
                                <!-- <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                         alt=""></div>
                                <img src="/assets/images/icons/cyborg.png"
                                     alt="">
                                <div class="info">Cyborg</div> -->
                                <img src="/assets/images/nfts/cyborg.png" alt="Cyborg">
                            </div>

                            <div class="__tiers-list-item activ"
                                 *ngIf="nftType == 2">
                                <!-- <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                         alt=""></div>
                                <img src="/assets/images/icons/android.png"
                                     alt="">
                                <div class="info">Android</div> -->
                                <img src="/assets/images/nfts/android.png" alt="Android">
                            </div>

                            <div class="__tiers-list-item activ"
                                 *ngIf="nftType == 3">
                                <!-- <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                         alt=""></div>
                                <img src="/assets/images/icons/humanoid.png"
                                     alt="">
                                <div class="info">Humanoid</div> -->
                                <img src="/assets/images/nfts/humanoid.png" alt="Humanoid">
                            </div>

                            <div class="__tiers-list-item activ"
                                 *ngIf="nftType == 4">
                                <!-- <div class="icon"><img src="/assets/images/icons/nft-n.svg"
                                         alt=""></div>
                                <img src="/assets/images/icons/ninja.png"
                                     alt="">
                                <div class="info">Crypto Ninja</div> -->
                                <img src="/assets/images/nfts/crypto_ninja.png" alt="Crypto Ninja">
                            </div>
                        </div>

                        <div class="nfts-bottom">
                            <div *ngIf="!(isNFTLocked || isNFTStaked)">
                                <form action=""
                                      class="token_id">
                                    <input type="text"
                                           name="tokenID"
                                           [(ngModel)]="tokenID"
                                           placeholder="{{'token' | translate}} ID">
                                    <!-- <button (click)="checkIfNFTOwned()">Check</button> -->
                                </form>
                                <button (click)="lockNFTToken()" class="lock-btn">{{'lock' | translate}} NFT</button>
                                <!-- <button (click)="showNFTStakingDlg()">Stake NFT</button> -->
                            </div>
                            <button (click)="unlockNft()"
                                    *ngIf="isNFTLocked">{{'unLock' | translate}} NFT</button>
                            <button (click)="unstakeNft()" *ngIf="isNFTStaked">{{'unstake' | translate}} NFT</button>
                            <div *ngIf="isNFTStaked" class="unlock-date">
                                <div>{{'unlockDate' | translate}}:</div>
                                <div>{{userUnstakeTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-7 mb-4 mb-lg-0 order-lg-2">
            <div class="card min-h-100">
                <div class="card-header">
                    <div class="subtitle-1 d-flex align-items-center">
                        <i class="icon icomoon-chart me-12 text-muted"></i>
                      {{'tierProgress' | translate}}
                    </div>
                </div>
                <div class="__tiers-list">
                    <div class="__tiers-list-item"
                         *ngFor="let tier of allTiers; index as i">
                        <div class="__tier-card"
                             [ngClass]="{'closed': !isAuthorized || i > nextTierIndex, 'activ': userTierIndex == i && lockerUserBalance >= (isNFTLocked ? tier.gmpdAmount : tier.gmpdOnlyAmount)}"
                             (click)="showTierInfoDlg(i)"
                             matTooltipClass="tooltip-md"
                             matTooltipPosition="above"
                             matTooltip="{{getTranslatedTier(i)}} {{'details' | translate}}">
                            <div class="tiers-list-top">
                                <div class="__tier-card-logo">
                                    <img [src]="i | imgTierPipe"
                                         alt="" />
                                </div>
                                <div class="__tier-card-name">{{getTranslatedTier(i)}}</div>
                            </div>
                            <div class="__tier-card-progress">
                                <div class="card-progress-title">GMPD</div>
                                <div class="progress progress-xs">
                                    <div class="progress-bar"
                                         role="progressbar"
                                         [style.width.%]="getTierPercent(i, (isNFTLocked ? tier.gmpdAmount : tier.gmpdOnlyAmount))">
                                    </div>
                                </div>
                                <div class="card-progress-info"><span></span><span class="next-lv"></span>{{(isNFTLocked ? tier.gmpdAmount : tier.gmpdOnlyAmount) | shiftDecimals:-18}}</div>
                            </div>

                            <div class="close-pr">
                                <div *ngIf="isNFTLocked" class="t">{{getTranslatedTier(i)}} NFT</div>
                                <div class="info">
                                    <button *ngIf="userTierIndex != i"><img src="/assets/images/icons/close.svg"
                                             alt=""></button>
                                    <button *ngIf="userTierIndex == i"
                                            style="opacity: 1;"><img src="/assets/images/icons/check.svg"
                                             alt=""></button>
                                </div>
                            </div>
                            <!-- <div class="__tier-card-checked" *ngIf="i <= userTierIndex">
                <i class="icon icon-xs icomoon-checked text-primary"></i>
              </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <div class="row penalties-row">
    <div class="col-lg-5">
      <div class="card benefits-card mb-2">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'lockerBenefits' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-award text-muted"></i>
            <div class="subtitle-1">{{'stakingBenefits' | translate}}</div>
          </div>
        </div>

        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
              <td>{{'joinOurVcIdoInoDeals' | translate}}</td>
            </tr>
            <tr>
              <td>{{'noRewardsForLocking' | translate}}</td>
              <td>{{'upToAprForStaking' | translate: {number: '19'} }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="card penalties-card flex-grow-1">
        <div class="card-header">
          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'lockerPenalty' | translate}}</div>
          </div>

          <div class="card-header-title">
            <i class="icon icomoon-warning-triangle text-muted"></i>
            <div class="subtitle-1">{{'stakingPenalty' | translate}}</div>
          </div>
        </div>
        <div class="card-content p-0">
          <table class="table benefits-table">
            <tr>
              <td>{{'penaltyForEarlyUnlock' | translate}} <br/> {{'daysSinceLastJoinedDeal' | translate}}</td>
              <td>{{'penaltyForEarlyWithdraw' | translate}} <br/> {{'untilEndDateOfStakingPool' | translate}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
