<section class="dashboard-section p-40">
  <div class="__address-book">

    <div class="left-addresses">
      <div class="__address-line"
           *ngFor="let item of leftAddresses; index as i">
        <div class="__address-line__status">
        <span class="__status icomoon-checked"
              *ngIf="item.emissionAddress"></span>
          <span class="__status icomoon-info text-error"
                matTooltip="{{'addressesParticipationError' | translate:{networkName: item.networkId | networkName} }}"
                matTooltipPosition="above"
                *ngIf="!item.emissionAddress">
        </span>
        </div>
        <div class="__address-line__network">
          <img [src]="item.networkId | imgNetworkPipe"
               alt=""
               class="logo-24">
        </div>
        <div class="__address-line__wallet">
          <div class="caption text-muted">{{item.networkId | networkName}}</div>
          <div *ngIf="item.emissionAddress"  class="__wallet small-text mt-1">{{item.emissionAddress}}</div>
        </div>
        <div class="__address-line__actions">
          <button mat-raised-button
                  color="primary"
                  class="btn-icon btn-icon-left"
                  (click)="openAddAddressDlg(item)"
                  *ngIf="!item.emissionAddress">
            <i class="icon icomoon-plus"></i>
            {{'add' | translate}}
          </button>
          <button mat-icon-button
                  [matMenuTriggerFor]="addressBookMenu"
                  *ngIf="item.emissionAddress">
            <i class="icomoon-more"></i>
          </button>
          <mat-menu #addressBookMenu="matMenu"
                    xPosition="before"
                    class="more-menu">
            <button mat-menu-item
                    (click)="openEditAddressDlg(item)"
                    *ngIf="!item.hasPendingRequest">
              <i class="icon icomoon-edit text-muted"></i>
              <span>{{'change' | translate}}</span>
            </button>
            <button mat-menu-item
                    *ngIf="item.hasPendingRequest">
              <i class="icon icomoon-edit text-muted"></i>
              <span>{{'requestPending' | translate}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="right-addresses">
      <div class="__address-line"
           *ngFor="let item of rightAddresses; index as i">
        <div class="__address-line__status">
        <span class="__status icomoon-checked"
              *ngIf="item.emissionAddress"></span>
          <span class="__status icomoon-info text-error"
                matTooltip="{{'addressesParticipationError' | translate:{networkName: item.networkId | networkName} }}"
                matTooltipPosition="above"
                *ngIf="!item.emissionAddress">
        </span>
        </div>
        <div class="__address-line__network">
          <img [src]="item.networkId | imgNetworkPipe"
               alt=""
               class="logo-24">
        </div>
        <div class="__address-line__wallet">
          <div class="caption text-muted">{{item.networkId | networkName}}</div>
          <div *ngIf="item.emissionAddress"  class="__wallet small-text mt-1">{{item.emissionAddress}}</div>
        </div>
        <div class="__address-line__actions">
          <button mat-raised-button
                  color="primary"
                  class="btn-icon btn-icon-left"
                  (click)="openAddAddressDlg(item)"
                  *ngIf="!item.emissionAddress">
            <i class="icon icomoon-plus"></i>
            {{'add' | translate}}
          </button>
          <button mat-icon-button
                  [matMenuTriggerFor]="addressBookMenu"
                  *ngIf="item.emissionAddress">
            <i class="icomoon-more"></i>
          </button>
          <mat-menu #addressBookMenu="matMenu"
                    xPosition="before"
                    class="more-menu">
            <button mat-menu-item
                    (click)="openEditAddressDlg(item)"
                    *ngIf="!item.hasPendingRequest">
              <i class="icon icomoon-edit text-muted"></i>
              <span>{{'change' | translate}}</span>
            </button>
            <button mat-menu-item
                    *ngIf="item.hasPendingRequest">
              <i class="icon icomoon-edit text-muted"></i>
              <span>{{'requestPending' | translate}}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="__history dashboard-section">
  <div class="card">
    <div class="card-header pb-1 border-none">
      <div class="subtitle-1">{{'walletChangeHistory' | translate}}</div>
    </div>
    <div class="card-body">
      <div class="__address-book">
        <div class="__address-line"
             *ngFor="let item of dbAddressChangeRequests; index as i">
          <div class="__address-line__network">
            <img [src]="item.networkId | imgNetworkPipe"
                 alt=""
                 class="logo-24">
          </div>
          <div class="__address-line__wallet">
            <div class="caption text-muted mb-1">{{item.networkId | networkName}}</div>
            <div class="__wallet small-text">{{item.emissionAddress}}</div>
          </div>
          <div class="__address-line__actions">
            <div class="__history-status text-warning"
                 *ngIf="item.status == enumStatus.Pending">
              <i class="icon icomoon-staking"></i>
              {{'pending' | translate}}
            </div>
            <div class="__history-status text-muted"
                 *ngIf="item.status == enumStatus.Declined">
              <i class="icon icomoon-close"></i>
              {{'decline' | translate}}
            </div>
            <div class="__history-status text-success"
                 *ngIf="item.status == enumStatus.Accepted">
              <i class="icon icomoon-checkmark"></i>
              {{'approved' | translate}}
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="card not-found"
         *ngIf="dbAddressChangeRequests.length == 0">
      <div class="icon icomoon-box"></div>
      <div class="not-found__text">{{'noHistoryYet' | translate}}</div>
    </div>
  </div>
</section>
