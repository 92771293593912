import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "languageName"
})
export class LanguageNamePipe implements PipeTransform {
  transform(lang: string): string {
    if (lang == 'en')
      return "English"
    else if (lang == 'es')
      return "Español"
    else if (lang == 'tr')
      return "Türkçe"
    else if (lang == 'ru')
      return "Русский"
    else if (lang == 'hi')
      return "हिन्दी"
    else if (lang == 'zh')
      return "中文"
    else if (lang == 'ja')
      return "日本語"
    else if (lang == 'vi')
      return "Tiếng Việt"
    return 'English'
  }
}
