import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { KYC_required } from 'src/internal/kyc.decorator';
import { Web3Service } from 'src/shared/web3-service';
import { ComponentBase } from '../../shared/component-base';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './claiming-nft.component.html',
    styleUrls: ['./claiming-nft.component.scss'],
})
export class ClaimingNFTComponent extends ComponentBase implements OnInit {
    constructor(private readonly web3Service: Web3Service, public translate: TranslateService) {
        super();
    }
    public userNFTsData: { user: string; NFTsInfo: number[] };
    public availableToClaimNFT = false;
    public nftTokenID: string;
    public binanceRegularNFTContract: string;
    public selectedNFT: number = null;
    public selectedNFTType: string;
    public checking: boolean;
    public completed: boolean;
    public validateTokenID(): void {
        this.showWarningModal(this.translate.instant('validationErrorYourAddressDoesntHave', { nftTokenId: this.nftTokenID }));
    }

    @KYC_required
    public checkNFT(): void {
        this.checking = true;
        this.web3Service
            .getUsersBNFTInfo()
            .pipe(
                first(),
                catchError((e, caught) => {
                    this.showWarningModal(e);
                    return of(undefined);
                }),
                finalize(() => {
                    this.checking = false;
                }),
            )
            .subscribe(userNFTsData => {
                if (!userNFTsData) {
                  return;
                }
                this.userNFTsData = userNFTsData;
                if (this.userNFTsData.NFTsInfo.length === 0) {
                    this.showWarningModal(this.translate.instant('youDontHaveNftsToClaim'));
                } else {
                    this.availableToClaimNFT = true;
                    if (this.userNFTsData.NFTsInfo.length === 1) {
                        this.selectedNFT = this.userNFTsData.NFTsInfo[0];
                        this.selectedNFTChanged(this.selectedNFT);
                    }
                }
            });
    }

    public backActionClick(): void {
        this.selectedNFT = null;
    }

    public selectedNFTChanged(value: any): void {
        this.selectedNFTType = this.getNFTType(value);
    }

    public async confirmSwap(): Promise<void> {
        this.web3Service
            .swapBNFTtoGMDP(this.selectedNFT)
            .then(() => {
                this.completed = true;
            })
            .catch((e: any) => {
                // this.completed = true; // uncomment just to check the logic
                console.log('confirmSwap error', e);
                this.showWarningModal(`${e.message || this.translate.instant('somethingWentWrong')}`);
            });
    }

    private getNFTType(tokenId: number): string {
        if (this.isInRange(tokenId, 100300474083, 100300474092)) {
            return this.translate.instant('android');
        } else if (this.isInRange(tokenId, 100300474093, 100300474392)) {
            return this.translate.instant('bot');
        } else if (this.isInRange(tokenId, 100300474393, 100300474582)) {
            return this.translate.instant('cyborg');
        }
        console.log(`Can't identify token type ${tokenId}`);
        return this.translate.instant('testAndroid');
    }

    ngOnInit(): void {
        this.binanceRegularNFTContract = this.web3Service.binanceRegularNFTContract;
    }

    private isInRange(value: number, min: number, max: number): boolean {
        return value >= min && value <= max;
    }
}
