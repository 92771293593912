import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './claiming-deal-detail.component.html',
    styleUrls: ['./claiming-deal-detail.component.scss'],
})
export class ClaimingDealDetailComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
