import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  templateUrl: './dlg-disclaimer.component.html',
  styleUrls: ['./dlg-disclaimer.component.scss']
})
export class DlgDisclaimerComponent implements OnInit, AfterViewInit {
  @ViewChild('disclaimerTextBlock') disclaimerTextBlock: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<DlgDisclaimerComponent>,
    private sanitizer: DomSanitizer
  ) {}

  @Input() disclaimer: string = '';
  @Input() disclaimerText: string = '';

  public disclaimerUrl: SafeUrl;

  ngOnInit() {
    this.disclaimerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.disclaimer + '?embedded=true');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.disclaimerTextBlock.nativeElement.scrollTo(0, 0);
    }, 1000);
  }
}
