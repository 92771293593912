import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgChainId',
})
export class ImgChainIdPipe implements PipeTransform {
    transform(chainId: number, isBlack: boolean = false): string {
        if (chainId == 56 || chainId == 97) return '/assets/svg/bnb.svg';
        if (chainId == 1 || chainId == 42 || chainId == 5) {
            return '/assets/images/chain/ethereum.svg';
        }
        if (chainId == 256 || chainId == 128) return '/assets/svg/heco.svg';
        return '';
    }
}
