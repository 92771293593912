<div class="wallet"
     *ngIf="account">
  <img [src]="web3Service.chainIdNumber | imgNetworkPipe"
       alt="" />

  <div class="wallet-balance">
    {{ ethBalance }} {{ web3Service.chainIdNumber | networkSymbol }}
  </div>

  <button class="wallet-address __address"
          (click)="showWalletDlg()"
          title="Log out">
    {{ account | shortAddress }}
  </button>
</div>

<button *ngIf="!account"
        (click)="unlockWalletClick()"
        mat-raised-button
        color="primary"
        class="__unlock btn-icon btn-icon-left wallet_connect">
  <i class="icon icomoon-wallet"></i>
  {{'connectWallet' | translate}}
</button>
