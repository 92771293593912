import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './create-claiming-deal.component.html',
    styleUrls: ['./create-claiming-deal.component.scss'],
})
export class CreateClaimingDealComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
