import { Pipe, PipeTransform } from '@angular/core';
import { BigNumber } from 'bignumber.js';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'showPeriod',
})
export class ShowPeriod implements PipeTransform {
  constructor(public translate: TranslateService) {}
    transform(value: number): string {
        var timerViewDays = Math.floor(value / (3600 * 24));
        var timerViewHours = Math.floor((value % (3600 * 24)) / 3600);
        var timerViewMin = Math.floor((value % 3600) / 60);
        var timerViewSec = Math.floor(value % 60);
        var stringData = '';
        if (timerViewDays) stringData += `${timerViewDays} ${this.translate.instant('time.day')} `;
        if (timerViewHours) stringData += `${timerViewHours} ${this.translate.instant('time.hours')} `;
        if (timerViewMin) stringData += `${timerViewMin} ${this.translate.instant('time.min')} `;
        if (timerViewSec) stringData += `${timerViewSec} ${this.translate.instant('time.ss')} `;
        return stringData;
    }
}
