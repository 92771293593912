<footer class="__footer">
  <div class="__footer-text"></div>
  <div class="__social-network">


    <!--<a class="__social-network-item" href="#" target="_blank">
    <img src="../../assets/svg/some_social_link.svg" />
  </a>
  <a class="__social-network-item" href="#" target="_blank">
    <img src="../../assets/svg/medium.svg" />
  </a>-->

  </div>
  <app-wallet></app-wallet>
</footer>
