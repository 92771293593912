import { Injectable } from '@angular/core';
import * as solanaWeb3 from '@solana/web3.js';

@Injectable({
    providedIn: 'root',
})
export class SolanaWeb3Service {
    constructor() {}

    public isValidAddress(address: string): boolean {
        try {
            const key = new solanaWeb3.PublicKey(address);
            return !!key;
            //return solanaWeb3.PublicKey.isOnCurve(key.toBuffer());
        } catch (e) {
            return false;
        }
    }
}
