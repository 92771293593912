import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../environments/environment';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { Web3Service } from '../../shared/web3-service';
import { AlertService } from '../shared-dlg.module';
import networks from '../networks.data';
import { ComponentBase } from '../../shared/component-base';
import { tap } from 'rxjs/operators';
import { DlgContractService } from '../dlg-contract.service';
import { EventBus } from '../../shared/event-bus';
import { KYC_required } from 'src/internal/kyc.decorator';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'dlg-locker-modal',
    templateUrl: './dlg-locker-modal.component.html',
    // styleUrls: ['./dlg-switch-network.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DlgLockerModalComponent extends ComponentBase implements OnInit {
    // {penalty: days}
    private penaltyTable: any = {
        5: 90,
        10: 60,
        20: 30,
        25: 20,
        30: 10,
    };

    @Input() IsLockedMode: boolean; //false if unlockMode
    @Input() isOldLocker: boolean = false;

    account: string | null;
    blpBlance: string;
    lockedBlance: string;
    //public isApprovedBLP: boolean = false;
    public userLockingStartTimestamp: number;
    public penaltyDays: number = 0;

    waiting: boolean = false;
    selectedAmount: number = 0;
    allowance: number;
    penaltyBP: number;

    public get isApprovedBLP(): boolean {
        return this.allowance >= this.selectedAmount;
    }

    constructor(
        public web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private _alertSrv: AlertService,
        private _dialog: MatDialog,
        private eventBus: EventBus,
        private _dlgContractSrv: DlgContractService,
        public translate: TranslateService
    ) {
        super();
    }

    async ngOnInit() {
        //await this.web3Service.initWeb3();
        this.account = this.userSessionProvider.linkedWallet;
        if (this.account) {
            this.updateUserData();
        }
    }

    async updateUserData() {
        this.blpBlance = await this.web3Service.GetTokenBalance(this.account, this.web3Service.blpAddress);
        this.lockedBlance = await this.web3Service.getLockedTokenAmount(this.web3Service.lockerAddress, this.account);
        this.userLockingStartTimestamp = parseInt(await this.web3Service.getUserLockingStart(this.account));
        this.updateAllowanceBLP();
        await this.getPenaltyBP();
    }

    async getPenaltyBP(): Promise<void> {
        this.web3Service.getLockerPenaltyBP(this.web3Service.lockerAddress, this.account).then(penalty => {
            this.penaltyBP = penalty;
            this.penaltyDays = this.penaltyTable[penalty / 100];
        });
    }

    updateAllowanceBLP(): void {
        //Проверяем разрешение тратить pool token в размере tokenSupply
        this.web3Service
            .GetAllowance(this.account, this.web3Service.blpAddress, this.web3Service.lockerAddress)
            .then(resp => {
                console.log(`GetAllowance blp ${resp}`);
                this.allowance = this.toNumberFromWei(resp, 18);
            });
    }

    async setMaxBalance() {
        if (this.IsLockedMode) {
            this.selectedAmount = this.toNumberFromWeiFixed(this.blpBlance, 18, 8);
        } else {
            this.selectedAmount = this.toNumberFromWeiFixed(this.lockedBlance, 18, 8);
        }
    }

    public get currentPenalty(): number {
        return (this.selectedAmount * this.penaltyBP) / 10000;
    }

    async approveClick(): Promise<void> {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.approve(
            this.account,
            this.web3Service.blpAddress,
            this.web3Service.lockerAddress,
        );

        contractEventsSource.transactionHash$.subscribe(val => {
            /*this.waiting = false;*/
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            this.waiting = false;
            this.updateAllowanceBLP();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }

        this.waiting = false;
    }

    @KYC_required
    async lockClick() {
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.lockerDeposit(this.account, this.selectedAmount, 18);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            console.log('emit needUpdateUsersInfo');
            this.eventBus.needUpdateUsersInfo.emit();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }

        this.waiting = false;
    }

    async unlockClick() {
        // if (this.isOldLocker) {
        //     return this.unlockOldClick();
        // }
        this.waiting = true;

        //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

        const contractEventsSource = this.web3Service.lockerWithdraw(this.account, this.selectedAmount, 18);

        contractEventsSource.transactionHash$.subscribe(val => {
            this.waiting = false;
            this._dialog.closeAll();
            console.log(`transactionHash$ ${val}`);
            //this._dlgContractSrv.showSubmitted({ tx: val });
            this._alertSrv.show(this.translate.instant('transactionSubmitted'));
        });

        try {
            await contractEventsSource.receipt$.toPromise();

            //dialogRef.close();
            this._alertSrv.show(this.translate.instant('confirmedTransaction'));
            console.log('emit needUpdateUsersInfo');
            this.eventBus.needUpdateUsersInfo.emit();
        } catch (err) {
            //dialogRef.close();
            console.info('catch');
            console.info(err);
        }

        this.waiting = false;
    }

    // async unlockOldClick() {
    //     this.waiting = true;

    //     //const dialogRef = this._dlgContractSrv.showWaitingConfirmation();

    //     const contractEventsSource = this.web3Service.lockerOldWithdraw(this.account, this.selectedAmount, 18);

    //     contractEventsSource.transactionHash$.subscribe(val => {
    //         this.waiting = false;
    //         this._dialog.closeAll();
    //         console.log(`transactionHash$ ${val}`);
    //         //this._dlgContractSrv.showSubmitted({ tx: val });
    //         this._alertSrv.show('Transaction submitted.');
    //     });

    //     try {
    //         await contractEventsSource.receipt$.toPromise();

    //         //dialogRef.close();
    //         this._alertSrv.show('Confirmed transaction');
    //         console.log('emit needUpdateUsersInfo');
    //         this.eventBus.needUpdateUsersInfo.emit();
    //     } catch (err) {
    //         //dialogRef.close();
    //         console.info('catch');
    //         console.info(err);
    //     }

    //     this.waiting = false;
    // }
}
