import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StakingPoolServiceProxy, UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { ComponentBase } from '../../shared/component-base';
import { Web3Service } from '../../shared/web3-service';
import { StakingPoolDTO } from '../../service-proxies/service-proxies';
import { EventBus } from '../../shared/event-bus';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { DlgContractService } from '../dlg-contract.service';

@Component({
    templateUrl: './staking.component.html',
    styleUrls: ['./staking.component.scss'],
})
export class StakingComponent extends ComponentBase implements OnInit {
    constructor(
        private _dialog: MatDialog,
        private _dlgContractSrv: DlgContractService,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private userSessionProvider: UserSessionProvider,
        private stakingPoolService: StakingPoolServiceProxy,
        private router: Router,
        private usersService: UsersServiceProxy,
    ) {
        super();
    }

    allStakingPools: Array<StakingPoolDTO> = [];
    verifiedStakingPools: Array<StakingPoolDTO> = [];
    closedStakingPools: Array<StakingPoolDTO> = [];
    publicStakingPools: Array<StakingPoolDTO> = [];

    userInfo: UserDTO;
    kycConfirmed: boolean;
    waitingPools: boolean = true;

    async ngOnInit() {
        await this.web3Service.initWeb3();

        // fix active tab bug n load
        window.dispatchEvent(new Event('resize'));

        if (this.userSessionProvider.isAuthorized) {
            this.usersService.getMe().subscribe(
                result => {
                    this.userInfo = result;
                    this.kycConfirmed = result.kycConfirmed;
                },
                error => {
                    this.processServiceError(error);
                },
            );
        }

        this.stakingPoolService.getAll(this.web3Service.chainIdNumber).subscribe(
            result => {
                console.log(result);
                this.allStakingPools = result;
                this.waitingPools = false;
                this.filterStakingPools();
            },
            error => {
                console.error(error);
                this.processServiceError(error);
            },
        );
    }

    filterStakingPools() {
        let currentTime = Math.floor(Date.now() / 1000);
        this.allStakingPools = this.allStakingPools.filter(
            p => p.poolToken == p.stakingToken && p.visibleForUsers == true,
        );
        this.closedStakingPools = this.allStakingPools.filter(p => currentTime > p.finishTime);
        this.publicStakingPools = this.allStakingPools.filter(p => p.finishTime > currentTime && p.isPublic == true);
        this.verifiedStakingPools = this.allStakingPools.filter(
            p => p.finishTime > currentTime && p.isApproved == true,
        );
    }

    processServiceError(error: any) {
        if (error.status == 401) {
            console.error('401');
            this.userSessionProvider.finishAuth();
            this.navigateToLogin();
        } else {
            console.error(error);
            this.showErrorModal(JSON.parse(error.response).message);
        }
    }

    navigateToLogin(): void {
        this.router.navigate(['/login']);
    }

    navigateToKYC(): void {
        this.router.navigate(['/kyc']);
    }
}
