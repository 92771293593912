import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'networkSymbol',
})
export class NetworkSymbolPipe implements PipeTransform {
    transform(value: number): string {
        if (value == 1 || value == 42 || value == 5) return 'ETH';
        else if (value == 56 || value == 97) return 'BNB';
        else if (value == 128 || value == 256) return 'HT';
        else if (value === 43114) return 'AVAX';
        return value.toString();
    }
}
