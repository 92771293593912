<aside class="sidenav"
       id="dashboard-nav">
  <div class="sidenav__logo desktop-logo">
    <a routerLink="/"><img src="../../assets/images/gmpd-logo.svg"
           alt="Gamespad"></a>

    <a class="token-price" routerLink="/swap-bridge">
      <div *ngIf="!tokenPrice?.gamespad?.usd" [ngClass]="{'btn-loading': !tokenPrice?.gamespad?.usd}"></div>
      <div *ngIf="tokenPrice?.gamespad?.usd" class="price-wrapper">
        <img src="assets/images/icons/gmpd-icon.svg" alt="gmpd">
        ${{ tokenPrice?.gamespad?.usd | number: '1.5-5'}}
      </div>
    </a>
  </div>
  <nav class="sidenav__nav">
    <ul class="main-navigation nav">
      <li class="nav-item"
          *ngFor="let menuItem of menuItems"
          [hidden]="menuItem.label == 'KYC' ? kycConfirmed : false">

        <ng-container *ngTemplateOutlet="menuTemplate; context:{ $implicit: menuItem }"></ng-container>

        <ng-template #menuTemplate
                     let-menu>
          <ng-container *ngTemplateOutlet="menu.childItems ? groupItem : singleItem"></ng-container>

          <ng-template #singleItem>

            <a class="nav-link"
               *ngIf="!menu.isExternal"
               [ngClass]="{'active': routerPath == menu.routePath}"
               [routerLink]="menu.routePath">
              <i class="nav-link__icon"
                 [ngClass]="menu.icon"></i>
              {{menu.translationKey | translate}}
            </a>
            <a class="nav-link"
               *ngIf="menu.isExternal"
               rel="noreferrer"
               href="{{menu.routePath}}"
               target="_blank">
              <i class="nav-link__icon"
                 [ngClass]="menu.icon"></i>
              {{menu.translationKey | translate}}
            </a>
          </ng-template>

          <ng-template #groupItem>
            <button class="nav-link"
                    (click)="toggleSubMenu($event)"
                    [ngClass]="{'active': menu.routePath.includes(routerPath) || routerPath == menu.routePath}">
              <i class="nav-link__icon"
                 [ngClass]="menu.icon"></i>
              {{menu.translationKey | translate}}
            </button>
            <ul class="sub-menu"
                [ngClass]="{'show': menu.routePath.includes(routerPath) || routerPath == menu.routePath}">
              <li class="nav-item"
                  *ngFor="let groupInnerItem of menu.childItems">
                <ng-container *ngTemplateOutlet="menuTemplate; context:{ $implicit: groupInnerItem }"></ng-container>
              </li>
            </ul>
          </ng-template>
        </ng-template>

      </li>
    </ul>
  </nav>
</aside>
