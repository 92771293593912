import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgSymbol',
})
export class ImgSymbolPipe implements PipeTransform {
    transform(symbol: string): string {
        if (symbol.includes('BNB')) return '/assets/images/currency/bnb.svg';
        if (symbol.includes('BUSD')) return '/assets/images/currency/busd.svg';
        if (symbol.includes('BSC')) return '/assets/svg/bnb.svg';
        if (symbol.includes('ETH')) return '/assets/svg/eth.svg';
        if (symbol.includes('Kovan')) return '/assets/svg/eth.svg';
        if (symbol.includes('AVAX')) return '/assets/svg/eth.svg';
        //TODO: remove HB in prod (when recreate correct token contracts)
        if (symbol.includes('heco') || symbol.includes('HT') || symbol.includes('HB')) return '/assets/svg/heco.svg';
        return '';
    }
}
