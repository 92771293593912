import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'networkFullName',
})
export class NetworkFullName implements PipeTransform {
    transform(value: number): string {
        if (value == 1 || value == 42 || value == 5) return 'Ethereum';
        else if (value == 56 || value == 97) return 'Binance';
        else if (value == 137 || value == 80001) return 'Polygon';
        else if (value == -100) return 'Solana';
        else if (value == -200) return 'Tezos';
        else if (value == -300) return 'Cardano';
        else if (value == 43114) return 'Avalanche'

        return value.toString();
    }
}
