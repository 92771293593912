import { Injectable } from '@angular/core';

import { from, Observable, Subject, throwError } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import NFTStakingJson from '../assets/abi/ENFTStaking.json';
import GMPD_NFT_ABI from '../assets/abi/GMPD_NFT_ABI.json';
import { environment } from '../environments/environment';
import { EventBus } from './event-bus';
import { UserSessionProvider } from './user-session-provider';
import swal from "sweetalert2"

declare const window: any;

@Injectable({
    providedIn: 'root',
})
export class StakingService {
    private readonly StakingAbi: any;

    public web3: Web3 = new Web3();

    private ethereumProvider: any;

    constructor(private eventBus: EventBus, private userSessionProvider: UserSessionProvider) {
        this.StakingAbi = NFTStakingJson;
        //this.initWeb3();
    }

    public get nftStakingAddress(): string {
        return environment.eth.nftStakingAddress;
    }

    public get chainIdNumber(): number {
        return this.userSessionProvider.getChainId();
    }

    private setWeb3OnCustomRPC(chainId: number = 0) {
        console.log(`set custom RPC for web3. ChainId: ${this.chainIdNumber}`);
        let chainIdNumber = this.chainIdNumber;
        if (chainId != 0) chainIdNumber = chainId;
        //ETH Mainnet
        if (chainIdNumber == 1) {
            this.web3 = new Web3('https://mainnet.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898');
        }
         //Goerli
        if (chainIdNumber == 5) {
            this.web3 = new Web3('https://maximum-shy-needle.ethereum-goerli.quiknode.pro/5aa8bdca199ee59f6729345db51f6fa76478962a/');
        }
        //Kovan
        else if (chainIdNumber == 42) {
            this.web3 = new Web3('https://kovan.infura.io/v3/46e5f1638bb04dd4abb7f75bfd4f8898');
        }
        //BSC
        else if (chainIdNumber == 56) {
            this.web3 = new Web3('https://bsc-dataseed.binance.org/');
        }
        //BSC Testnet
        else if (chainIdNumber == 97) {
            this.web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545/');
        }
        //Heco Testnet
        else if (chainIdNumber == 256) {
            this.web3 = new Web3('https://http-testnet.hecochain.com');
        }
    }

    async initWeb3(chainId: number = 0) {
        console.log('initWeb3');
        if (chainId != 0) {
            this.setWeb3OnCustomRPC(chainId);
            return;
        }
        this.ethereumProvider = window.ethereum;
        if (this.ethereumProvider) {
            this.web3 = new Web3(this.ethereumProvider);

            var metamaskChainId = this.convertChainIdToHex(await this.web3.eth.getChainId());
            // await window.ethereum.request({ method: 'eth_chainId' });
            console.log('matamask chainId: ' + metamaskChainId);
            if (parseInt(metamaskChainId, 16) != this.chainIdNumber) {
                this.setWeb3OnCustomRPC();
            }
            return;
        } else {
            //this.isWeb3Disabled = true;
            if (!this.web3 || !this.web3.currentProvider) {
                this.setWeb3OnCustomRPC();
            }
        }

    }

    public convertChainIdToHex(value: number): string {
        var hexChainId = '0x' + value.toString(16);
        if (hexChainId === '0x1') hexChainId = '0x01';
        return hexChainId;
    }

    getBridgedTokenOwner(tokenID: number){
        return new Promise((resolve, reject) => {
            const contract = new this.web3.eth.Contract(GMPD_NFT_ABI as any, environment.eth.nft);
            contract.methods.ownerOf(tokenID).call({}, (error: any, resp: string) => {
                if (typeof resp == 'undefined')
                    resolve('undefined');
                else
                    resolve(resp.toLowerCase());
            });
        }) as Promise<string>;
    }

    async stakeNFT(account: string, tokenID: number){
        const nftContract = new this.web3.eth.Contract(GMPD_NFT_ABI as any, environment.eth.nft);

        // tslint:disable-next-line:max-line-length
        const approved = await nftContract.methods.isApprovedForAll(account, this.nftStakingAddress).call(); // Check if the user approved to move his tokens
        if (!approved) {
            await nftContract.methods.setApprovalForAll(this.nftStakingAddress, true).send({ from: account, maxPriorityFeePerGas: null, maxFeePerGas: null });
        }
        const contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
        const contractEventSource = contract.methods.depositNFT(tokenID).send({ from: account, maxPriorityFeePerGas: null, maxFeePerGas: null });
        return contractEventSourceToObserves(contractEventSource);
    }

    async getStakedNFT(user: string): Promise<number> {
        return new Promise((resolve, reject) => {
            const contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
            contract.methods.userNFT(user).call({}, (error: any, resp: number) => {
                resolve(resp);
            });
        }) as Promise<number>;
    }

    async unstakeNFT(account: string){
        const contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
        const contractEventSource = contract.methods.withdrawNFT().send({ from: account, maxPriorityFeePerGas: null, maxFeePerGas: null });
        return contractEventSourceToObserves(contractEventSource);
    }

    async getUserStakeEnd(user: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
            contract.methods.getUnlockTime(user).call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getStakingStart(): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
            contract.methods.stakingStart().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }

    async getStakingPeriod(): Promise<string> {
        return new Promise((resolve, reject) => {
            let contract = new this.web3.eth.Contract(this.StakingAbi, this.nftStakingAddress);
            contract.methods.stakingPeriod().call({}, (error: any, resp: string) => {
                resolve(resp);
            });
        }) as Promise<string>;
    }
    
}

function contractEventSourceToObserves(contractEventSource: any) {
    const transactionHashSbj: Subject<string> = new Subject();
    const receiptSbj: Subject<any> = new Subject();
    const errorSbj: Subject<{
        error: any;
        receipt: any;
    }> = new Subject();

    contractEventSource
        .on('error', function (error: any, receipt: any) {
            console.log('contractEventSource error');
            console.log(error);
            errorSbj.error({ error, receipt });
            errorSbj.complete();
        })
        .on('transactionHash', function (hash: any) {
          console.log('transactionHash');
            transactionHashSbj.next(hash);
            transactionHashSbj.complete();
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt');
            receiptSbj.next(receipt);
            receiptSbj.complete();
        });

    const transactionHash$ = transactionHashSbj.asObservable().pipe(takeUntil(errorSbj));

    const receipt$ = receiptSbj.asObservable().pipe(takeUntil(errorSbj));

    const error$ = errorSbj.asObservable();

    return {
        transactionHash$,
        receipt$,
        error$,
    };
}
