import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'nameTierPipe',
})
export class NameTierPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
    transform(index: number): string {
        if (index == -1) return this.translate.instant('none');
        if (index == 0) return this.translate.instant('bot');
        if (index == 1) return this.translate.instant('cyborg');
        if (index == 2) return this.translate.instant('android');
        if (index == 3) return this.translate.instant('humanoid');
        if (index == 4) return this.translate.instant('cryptoNinja');
        return 'None';
    }
}
