import {HostListener, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'shortAddress',
})
export class ShortAddressPipe implements PipeTransform {
  private isMobile: boolean = false;
  constructor() {
    this.isMobile = window.innerWidth < 768;
  }
  transform(value: string): string {
    if (this.isMobile && value && value.length > 3) {
      return value.substring(0, 4) + "..." + value.substring(value.length - 4, value.length);
    } else if (value && value.length > 20) {
      return value.substring(0, 6) + "..." + value.substring(value.length - 6, value.length);
    }
    return value;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.isMobile = window.innerWidth < 768;
  }
}
