<section class="deals-section">
  <div class="__deals-list">
    <mat-tab-group mat-align-tabs="start"
                   class="fullpage-tabs"
                   disableRipple
                   selectedIndex="4">
      <mat-tab label="{{'upcoming' | translate}}">
        <div class="card not-found"
             *ngIf="upcomingDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{'noUpcomingDealsForNow' | translate}}</div>
        </div>

        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer"
               *ngFor="let item of upcomingDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'inProgress' | translate}}">
        <div class="card not-found"
             *ngIf="inProgressDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{'noDealsInProgressForNow' | translate}}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer"
               *ngFor="let item of inProgressDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'ended' | translate}}">
        <div class="card not-found"
             *ngIf="endedDeals.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{'noFinishedDealsYet' | translate}}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer"
               *ngFor="let item of endedDeals; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'all' | translate}}">
        <mat-spinner *ngIf="waitingDeals"
                     class="__loading"
                     diameter="40"></mat-spinner>

        <div class="card not-found"
             *ngIf="!waitingDeals && allDealsFiltered.length == 0">
          <div class="icon icomoon-box"></div>
          <div class="not-found__text">{{'noDealsYet' | translate}}</div>
        </div>
        <div class="card-list per-row-2">
          <div class="deal-card card cursor-pointer"
               *ngFor="let item of allDealsFiltered; index as i">
            <deal-viewer [item]="item"></deal-viewer>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</section>
