<section class="dashboard-section section-registration p-40">
  <div class="sign-up-form card"
       *ngIf="step==1">
    <div class="card-content">
      <div class="subtitle-1 mb-4 text-center">{{'registration' | translate}}</div>

      <form #signUpForm="ngForm"
            (ngSubmit)="signUpClick()">
        <mat-form-field appearance="fill"
                        hideRequiredMarker>
          <mat-label>E-mail</mat-label>
          <input matInput
                 type="email"
                 [(ngModel)]="signUpEmail"
                 name="signUpEmail"
                 required>
        </mat-form-field>

        <mat-form-field *ngIf="referrer" class="mt-3" appearance="fill" hideRequiredMarker>
          <mat-label>{{'referrer' | translate}}</mat-label>
          <input matInput
                 [(ngModel)]="referrer"
                 name="referrer"
                 readonly
          >
        </mat-form-field>

        <re-captcha class="__captcha my-2"
                    #recaptchaSignUp
                    [(ngModel)]="captchaResponseSignUp"
                    name="captcha"
                    required></re-captcha>

        <a href="https://help.gamespad.io/how-to-complete-kyc/" rel="noreferrer" target="_blank" mat-stroked-button class="mb-12 w-100">
          {{'prohibitedJurisdictionsList' | translate}}
        </a>

        <button type="submit"
                mat-raised-button
                color="primary"
                class="w-100"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="!signUpForm.form.valid || waiting">
          {{'next' | translate}}
        </button>
      </form>
    </div>
    <div class="card-footer">
      <div class="subtitle-1 d-flex align-items-center mb-3">
        <span class="icon icomoon-warning me-2 mute-40"></span> {{'warning' | translate}}
      </div>
      <div class="small-text mute-80">
        {{'signingWarningText' | translate}}
      </div>
    </div>
  </div>

  <div class="sign-up-form card"
       *ngIf="step==2">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">{{'checkYourEmail' | translate}}</div>

      <i class="icon icomoon-mail __icon"></i>

      <div class="small-text mute-80">
        {{'weSentConfirmationLink' | translate}} <span class="text-primary">{{signUpEmail}}</span>.<br>
        {{'followLinkInMessageFinishRegistration' | translate}}
      </div>
    </div>
    <div class="card-footer">
      <form #signUpForm="ngForm"
            (ngSubmit)="signUpClick()">
        <button type="submit"
                mat-stroked-button
                class="w-100 mb-12"
                [ngClass]="{'btn-loading': waiting}"
                [disabled]="!signUpForm.form.valid || waiting">
          {{'resendVerificationEmail' | translate}}
        </button>
        <re-captcha #recaptchaSignUp
                    [(ngModel)]="captchaResponseSignUp"
                    name="captcha"
                    required></re-captcha>
      </form>
    </div>
  </div>

  <div class="sign-up-form card"
       *ngIf="step==3">
    <div class="card-content">
      <div class="subtitle-1 mb-4 text-center">{{'finishRegistration' | translate}}</div>

      <form>
        <mat-form-field appearance="fill"
                        hideRequiredMarker>
          <mat-label>E-mail</mat-label>
          <input matInput
                 name="signUpEmail"
                 required>
        </mat-form-field>

        <mat-form-field appearance="fill"
                        hideRequiredMarker>
          <mat-label>{{'name' | translate}}</mat-label>
          <input matInput
                 name="signUpEmail"
                 required>
        </mat-form-field>
        <button type="submit"
                mat-raised-button
                color="primary"
                class="w-100 mt-3">{{'submit' | translate}}</button>
      </form>
    </div>
  </div>

  <div class="sign-up-form card"
       *ngIf="step==4">
    <div class="card-content text-center">
      <div class="subtitle-1 mb-4">{{'registrationFinished' | translate}}</div>
      <i class="icon icomoon-checked __icon"></i>
      <div class="small-text mute-80 mb-3">
        {{'youSuccessfullyRegistered' | translate}}
      </div>
      <a href="/"
         mat-raised-button
         color="primary"
         class="w-100">{{'dashboard' | translate}}</a>
    </div>
  </div>
</section>
