import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { AlertService } from '../shared-dlg.module';
import {
    AccountServiceProxy,
    AuthenticateInputDTO,
    UsersServiceProxy,
    BoolDTO,
} from '../../service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBus } from '../../shared/event-bus';
import { Web3Service } from '../../shared/web3-service';
import { MatDialog } from '@angular/material/dialog';
import { DlgUnlockWalletComponent } from '../dlg-unlock-wallet';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { UserSessionProvider } from '../../shared/user-session-provider';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginPageComponent extends ComponentBase implements OnInit {
    constructor(
        private userSessionProvider: UserSessionProvider,
        private _alertSrv: AlertService,
        private accountService: AccountServiceProxy,
        private usersService: UsersServiceProxy,
        private route: ActivatedRoute,
        private router: Router,
        private eventBus: EventBus,
        private web3Service: Web3Service,
        private _dialog: MatDialog,
        public translate: TranslateService
    ) {
        super();
    }

    waiting: boolean = false;
    account: string = '';
    signUpEmail: string = '';
    step: number = 1;
    public walletName: string = ''

    async ngOnInit() {
        this.walletName = this.userSessionProvider.walletName.charAt(0).toUpperCase() + this.userSessionProvider.walletName.slice(1);
        if (this.userSessionProvider.linkedWallet) {
            this.eventLogin(this.userSessionProvider.linkedWallet);
        }
        await this.web3Service.initWeb3();

        this.eventBus.loginEvent.subscribe(result => {
            console.log('loginEvent subscription:' + result);
            this.eventLogin(result);
        });
    }

    async eventLogin(username: string) {
        console.log('eventLogin');
        console.log(username);
        if (this.account != username) {
            this.account = username;
            this.step = 2;

            this.usersService.existAddress(username).subscribe(
                result => {
                    if (!result.value) {
                        this._alertSrv.show(this.translate.instant('processRegistrationFirst'), 'information');
                        this.router.navigate(['/signup']);
                    }
                },
                error => {
                    console.error(error);
                },
            );
        }
    }

    async unlockWallet() {
        const dialogRef = this._dialog.open(DlgUnlockWalletComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });
    }

    async loginClick() {
        if (!this.account) {
            this.unlockWallet();
            return;
        }
        var data: AuthenticateInputDTO = new AuthenticateInputDTO();
        data.timeStamp = Math.floor(Date.now() / 1000);
        data.signature = await this.web3Service.PersonalSign(data.timeStamp.toString(), this.account);
        if (!data.signature) {
            console.log('Not signed!');
            return;
        }
        data.ethAddress = this.account;
        this.waiting = true;

        this.accountService.authenticate(data).subscribe(
            result => {
                this.waiting = false;
                this.userSessionProvider.startAuth(result.userName, result.accessToken);
                this.router.navigate(['/']);
            },
            error => {
                this.waiting = false;
                let message = JSON.parse(error.response).message;
                if (message != 'User not found!') {
                    if (message && message.toLowerCase().indexOf('time') != -1) {
                        message += `<br /><a target="_blank" href="https://help.bullperks.com/how-to-fix-the-incorrect-time-error/">${this.translate.instant('clickHereForDetails')}</a>`;
                    }

                    this._alertSrv.show(message, 'error');
                } else {
                    this._alertSrv.show(this.translate.instant('processRegistrationFirst'), 'information');
                    this.router.navigate(['/signup']);
                }
                //this.showErrorModal(message);
            },
        );
    }
}
