import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './dlg-locker-info.component.html',
})
export class DlgLockerInfoComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DlgLockerInfoComponent>) {}

    async ngOnInit(): Promise<void> {}
}
