////import { RequestOptionsArgs, Headers } from "@angular/http"
////import { UserSessionProvider } from "shared/user-session-provider";

import { Injector } from '@angular/core';
import { BrowserStorageProvider } from '../shared/browser-storage-provider';

export class ServiceProxyBase {
    constructor() {}

    transformOptions(options: any): Promise<any> {
        let injector = Injector.create({ providers: [{ provide: BrowserStorageProvider, deps: [] }] });
        let browserStorage: BrowserStorageProvider = injector.get(BrowserStorageProvider);
        let storage = browserStorage.getStorage(localStorage);
        if (storage.getItem('authToken') != undefined)
            options.headers = options.headers.append('Authorization', 'Bearer ' + storage.getItem('authToken'));
        return Promise.resolve(options);
    }
}
