import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { BrowserStorageProvider } from './browser-storage-provider';

@Injectable()
export class UserSessionProvider {
    constructor(private browserStorage: BrowserStorageProvider) {
        this.storage = browserStorage.getStorage(localStorage);
    }

    private storage: Storage = localStorage;

    private langKey: string = 'lang';
    private lightModeKey: string = 'lightMode';
    private chainIdKey: string = 'chainId';

    private walletNameKey: string = 'walletName';
    private linkedWalletKey: string = 'linkedWallet';
    private authTokenKey: string = 'authToken';
    private authEthKey: string = 'authEth';

    public get getLang(): string | null {
      return this.storage.getItem(this.langKey) || 'en';
    }

    public setLang(lang: string): void {
      this.storage.setItem(this.langKey, lang);
    }
    public get getIsLightMode(): boolean {
        return this.storage.getItem(this.lightModeKey) != undefined;
    }

    public setDarkMode(): void {
        this.storage.removeItem(this.lightModeKey);
    }

    public setLightMode(): void {
        this.storage.setItem(this.lightModeKey, 'true');
    }

    public setChainId(chainId: number): void {
        //if (environment.supportedChains.indexOf(chainId) >= 0)
        this.storage.setItem(this.chainIdKey, chainId.toString());
    }

    public getChainId(): number {
        var chainValue = this.storage.getItem(this.chainIdKey);
        if (chainValue) return parseInt(chainValue);
        return environment.defaultChainId;
    }

    public get walletName(): string | null {
        return this.storage.getItem(this.walletNameKey);
    }

    public get linkedWallet(): string | null {
        return this.storage.getItem(this.linkedWalletKey);
    }

    public get isAuthorized(): boolean {
        return this.storage.getItem(this.authEthKey) != undefined;
    }

    public get authEth(): string | null {
        return this.storage.getItem(this.authEthKey);
    }

    public get authToken(): string | null {
        return this.storage.getItem(this.authTokenKey);
    }

    public startAuth(ethAddress: string, authToken: string): void {
        this.storage.setItem(this.authEthKey, ethAddress);
        this.storage.setItem(this.authTokenKey, authToken);
    }

    public finishAuth(): void {
        this.storage.removeItem(this.authEthKey);
        this.storage.removeItem(this.authTokenKey);
    }

    public linkWallet(address: string, walletName: string) {
        this.storage.setItem(this.linkedWalletKey, address.toLowerCase());
        this.storage.setItem(this.walletNameKey, walletName);
    }

    public logOutWallet() {
        this.storage.removeItem(this.linkedWalletKey);
        this.storage.removeItem(this.walletNameKey);
    }

    public finishSession() {
        this.logOutWallet();
        this.finishAuth();
    }
}
