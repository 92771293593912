import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'networkName',
})
export class NetworkNamePipe implements PipeTransform {
    transform(value: number): string {
        if (value == 1) return 'Ethereum';
        else if (value == 5) return 'Goerli';
        else if (value == 42) return 'Kovan';
        else if (value == 56) return 'BNB Chain';
        else if (value == 97) return 'BNB Test Chain';
        else if (value == 137) return 'Polygon';
        else if (value == 80001) return 'Polygon Test';
        else if (value == 256) return 'Heco Test';
        else if (value == 128) return 'Heco';
        else if (value == -100) return 'Solana';
        else if (value == -200) return 'Tezos';
        else if (value == 43114) return 'Avalanche';
        else if (value == 250) return 'Fantom';
        else if (value == 321) return 'KCC';
        else if (value == 322) return 'KCCTest';
        else if (value == -9999100) return 'MultiversX(Elrond)';
        else if (value == -9999101) return 'Cardano';
        else if (value == -9999102) return 'Other';
        else if (value == -9999103) return 'Polkadot';
        else if (value == -9999104) return 'Cosmos';
        else if (value == -9999105) return 'Flow';
        else if (value == -9999106) return 'Near';
        else if (value == 344435) return 'Skale';
        else if (value == 888) return 'Wanchain';
        else if (value == 1666600000) return 'Harmony';
        else if (value == 66) return 'OKC';
        else if (value == 42161) return 'Arbitrum';
        else if (value == -9999107) return "Sui";
        else if (value == -9999108) return "Bitcoin";
        else if (value == -9999109) return "Aptos";
        else if (value == 10) return "Optimism";
        else if (value == 8453) return "Base";
        else if (value == 1284) return "Moonbeam";
        else if (value == 592) return "Astar";
        else if (value == 1890) return "Lightlink";
        else if (value == -9999110) return "TON";
        else if (value == 81457) return "Blast";
        else if (value == -9999111) return "Injective";
        else if (value == -1) return "Unsupported";
        return value.toString();
    }
}
