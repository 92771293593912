<div class="modal-header">
  <i class="icon icomoon-wallet"></i>
  <div class="modal-title">
    {{'connectToWallet' | translate}}
  </div>
  <button class="btn-close"
          [mat-dialog-close]="true"></button>
</div>
<div class="modal-body">
  <div class="__wallet-list">
    <button (click)="unlockMetamaskClick()"
            mat-stroked-button
            class="__wallet-btn wallet_metamask">
      <img class="__wallet-btn__img"
           src="../../assets/images/wallets/metamask.svg"
           alt="">
      <span class="__wallet-btn__title">Metamask</span>
    </button>

    <button (click)="unlockMetamaskClick()"
            mat-stroked-button
            class="__wallet-btn">
      <img class="__wallet-btn__img"
           src="../../assets/images/wallets/binance.svg"
           alt="">
      <span class="__wallet-btn__title">Binance {{'wallet' | translate}}</span>
    </button>

    <button (click)="unlockWalletconnectClick()"
            mat-stroked-button
            class="__wallet-btn">
      <img class="__wallet-btn__img"
           src="../../assets/images/wallets/wallet_connect.svg"
           alt="">
      <span class="__wallet-btn__title">WalletConnect</span>
    </button>

    <button (click)="unlockOkxconnectClick()" mat-stroked-button class="__wallet-btn">
      <img class="__wallet-btn__img" src="../../assets/images/wallets/okx.png" alt="">
      <span class="__wallet-btn__title">OKX Wallet</span>
    </button>
  </div>
</div>
