import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { throwError as _throw } from 'rxjs';

import { IDlgContractProcessingData, DlgContractProcessingComponent } from './dlg-contract-processing';
import { DlgContractSubmittedComponent, IDlgContractSubmittedData } from './dlg-contract-submitted';

@Injectable()
export class DlgContractService {
    constructor(private _dialog: MatDialog) {}

    showConfirmSwap(data?: IDlgContractProcessingData): MatDialogRef<DlgContractProcessingComponent, any> {
        const dialogRef = this._dialog.open(DlgContractProcessingComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
            data,
        });

        return dialogRef;
    }

    showWaitingConfirmation(): MatDialogRef<DlgContractProcessingComponent, any> {
        const dialogRef = this._dialog.open(DlgContractProcessingComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
        });

        return dialogRef;
    }

    showSubmitted(data?: IDlgContractSubmittedData): MatDialogRef<DlgContractSubmittedComponent, any> {
        const dialogRef = this._dialog.open(DlgContractSubmittedComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
            data,
        });

        return dialogRef;
    }
}
