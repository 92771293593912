<main class="dashboard-main">
  <info-join-deals></info-join-deals>

  <section class="dashboard-section deal-detail-header">
    <div class="row gx-4">
      <div class="deal-detail-header__left col-12 mb-4">
        <div class="deal-detail-card white-card">
          <div class="deal-header">
            <div class="deal-header__avatar avatar">
              <img [src]="deal?.logoURL"
                   alt=""
                   class="deal-thumb" />
            </div>
            <div class="deal-header__title">
              <div class="deal-title h4"> {{deal?.name}} <span class="currency">{{deal?.rewardTokenSymbol}}</span></div>
              <div class="deal-address text-muted break-word">
                {{rewardToken}}
              </div>
            </div>
          </div>
          <div class="deal-join">
            <!-- if no registered: add class disabled -->
            <button class="btn btn-primary btn-icon btn-icon-left"
                    (click)="joinDealClick()"
                    *ngIf="!(dealRegistration?.signature)">
              <i class="icon icomoon-bulk-tick-square"></i>{{'joinDeal' | translate}}
            </button>
            <button class="btn btn-outline-secondary"
                    *ngIf="dealRegistration?.signature"
                    style="cursor:no-drop">{{'youAreJoined' | translate}}</button>
            <a class="btn btn-outline-secondary"
               [href]="rewardToken | blockExplorerAddressPipe: deal?.chainId"
                 target="_blank">{{'view' | translate}} Etherscan</a>
            <!--<button class="btn btn-outline-secondary">
              View Etherscan
            </button>-->
          </div>
          <div class="deal-status d-lg-flex align-items-center">
            <div class="coin-title">
              <div class="coin-title__logo">
                <img [src]="deal?.chainId | imgChainId" />
              </div>
              <div class="coin-title__title">{{deal?.chainId|networkFullName}}</div>
            </div>
            <div class="badge-status status-filled">{{'filled' | translate}}</div>
            <!-- status-filled / status-wait / status-done -->
            <div class="deal-date text-muted">
              <!--Published 12 days ago-->
            </div>
          </div>
        </div>
      </div>

      <div class="deal-detail-header__right col-12 mb-4">
        <div class="deal-swap white-card">
          <div class="deal-swap__amount">
            <div class="mb-2 d-lg-flex align-items-center justify-content-between">
              <div class="label">{{'progress' | translate}}</div>
              <div class="text-muted">1 {{paymentTokenSymbol}} = {{tokensPerETH| number: '1.0-2'}} {{rewardTokenSymbol}}</div>
            </div>

            <div class="value">
              {{tokensForDistribution| shiftDecimals:-rewardDecimals| number: '1.0-2'}} {{rewardTokenSymbol}}
              <span class="convert-value">{{totalRaise| shiftDecimals:-paymentDecimal| number: '1.0-2'}} {{paymentTokenSymbol}}</span>
            </div>
          </div>
          <div class="deal-swap__progress d-lg-flex">
            <div class=" col d-flex d-lg-block justify-content-between align-items-center">
              <div class="label">
                <span *ngIf="isUpcoming">{{'startsIn' | translate}}</span>
                <span *ngIf="now > startTimestamp">{{'closesIn' | translate}}</span>
                <!--<span *ngIf="now > finishTimestamp && now < startClaimTimestamp">Tokens will be claimable on</span>-->
              </div>
              <div class="value">
                <div *ngIf="isUpcoming || isStarted">
                  <span *ngIf="timerViewDays">{{timerViewDays}}<b>{{'time.day' | translate}}</b></span>
                  <span *ngIf="timerViewHours"> {{timerViewHours}}<b>{{'time.hour' | translate}}</b></span>
                  <span *ngIf="timerViewMin && !timerViewDays"> {{timerViewMin}}<b>{{'time.minute' | translate}}</b></span>
                  <span *ngIf="timerViewSec && !timerViewDays"> {{timerViewSec}}<b>{{'time.second' | translate}}</b></span>
                </div>
                <span *ngIf="now >finishTimestamp">{{'ended' | translate}}</span>

                <!--<span *ngIf="now > finishTimestamp && now < startClaimTimestamp">
                  {{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a'}}
                </span>-->
              </div>
            </div>

            <div class="col swap-progress progress-group ms-lg-auto mt-4 mt-lg-0">
              <div class="label">{{'progress' | translate}}</div>
              <div class="progress progress-sm">
                <div class="progress-bar"
                     role="progressbar"
                     [style.width.%]="getDistributedPercent">

                  <!--style="width: 25%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"-->
                </div>
              </div>
              <div class="progress-legend d-flex justify-content-between">
                <div class="progress-legend__percent">{{getDistributedPercent| number: '1.0-2'}}%</div>
                <div class="progress-legend__value">{{tokensForDistribution| shiftDecimals:-rewardDecimals| number: '1.0-2'}}/{{maxDistributedTokenAmount | shiftDecimals:-rewardDecimals| number: '1.0-2'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section">
    <div class="row">
      <div *ngIf="now<finishTimestamp"
           class="col-lg-6">
        <div class="white-card mb-4">
          <table class="deal-tier-table w-100">
            <tr>
              <td>{{'yourTier' | translate}}:</td>
              <td colspan="2">
                <div class="bull-value d-flex align-items-center">
                  <div class="bull-icon logo-md contain-img me-3">
                    <img [src]="userTierIndex | imgTierPipe"
                         alt="" />
                  </div>
                  <div class="value fw-normal me-3">{{getTranslatedTier(userTierIndex)}}</div>
                  <!--<div class="info-tooltip icomoon-plumpy-info"></div>-->
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {{'yourTicketSize' | translate}}:
              </td>
              <td *ngIf="userTier">
                {{userTier.ticketSize| shiftDecimals:-paymentDecimal}} {{paymentTokenSymbol}}
              </td>
              <td *ngIf="!userTier">
                0 {{paymentTokenSymbol}}
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <button class="btn btn-primary w-100"
                        (click)="payClick()"
                        [ngClass]="{'wait': waiting}"
                        [disabled]="waiting || !allowPay || !userTier || !account">
                  {{'pay' | translate}}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div *ngIf="isFinished"
           class="col-lg-6">
        <div class="white-card mb-4">
          <table class="w-100">
            <tr *ngIf="usersDebtAmount">
              <th>{{'amountForClaim' | translate}}</th>
              <td>{{usersDebtAmount | number: '1.0-4'}} {{rewardTokenSymbol}} </td>
            </tr>
            <tr *ngIf="usersDebtAmount&&vestingPercent">
              <th>{{'vestingAmount' | translate}}</th>
              <td>{{usersDebtAmount*vestingPercent/100| number: '1.0-4' }}</td>
            </tr>
            <tr *ngIf="usersDebtAmount&&vestingPercent">
              <th>{{'receiveAmount' | translate}}</th>
              <td>{{usersDebtAmount*(100-vestingPercent)/100| number: '1.0-4' }}</td>
            </tr>
            <tr *ngIf="usersDebtAmount && account">
              <td colspan="2">
                <button class="btn btn-primary"
                        (click)="claimClick()"
                        [ngClass]="{'wait': waiting}"
                        [disabled]="waiting || !allowClaim">
                  {{'claimYourPurchasedTokens' | translate}}
                </button>
              </td>
            </tr>
            <tr *ngIf="usersClaimedTokensAmount">
              <th>{{'claimedAmount' | translate}}</th>
              <td>{{usersClaimedTokensAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vestedAmount' | translate}}</th>
              <td>{{usersVestedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'releasableAmount' | translate}}</th>
              <td>{{usersReleasableAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vestedReleasedAmount' | translate}}</th>
              <td>{{usersVestedReleasedAmount | number: '1.0-4'}} {{rewardTokenSymbol}}</td>
            </tr>
            <tr *ngIf="usersReleasableAmount">
              <td colspan="2">
                <button class="btn btn-primary"
                        (click)="claimVesingClick()"
                        [ngClass]="{'wait': waiting}"
                        [disabled]="waiting || !allowClaim">
                  {{'claimYourVestingTokens' | translate}}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard-section deal-detail-info mb-5">
    <div class="white-card">
      <ul class="nav nav-tabs deal-info-nav nav-tabs-responsive-md"
          id="dealInfoTab"
          role="tablist">
        <li class="nav-item"
            role="presentation">
          <button class="nav-link active"
                  id="deal-info-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#deal-info"
                  type="button"
                  role="tab"
                  aria-controls="deal-info"
                  aria-selected="true">
            {{'dealInformation' | translate}}
          </button>
        </li>
        <li class="nav-item"
            role="presentation">
          <button class="nav-link"
                  id="deal-about-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#deal-about"
                  type="button"
                  role="tab"
                  aria-controls="deal-about"
                  aria-selected="false">
            {{'aboutTheProject' | translate}}
          </button>
        </li>
        <li class="nav-item"
            role="presentation">
          <button class="nav-link"
                  id="deal-allocation-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#deal-allocation"
                  type="button"
                  role="tab"
                  aria-controls="deal-allocation"
                  aria-selected="false">
            {{'allocationPerTier' | translate}}
          </button>
        </li>
      </ul>
      <div class="tab-content deal-info-content"
           id="dealInfoTabContent">
        <div class="tab-pane fade show active"
             id="deal-info"
             role="tabpanel"
             aria-labelledby="deal-info-tab">
          <table class="table deal-info-table table-fixed">
            <tr>
              <th>{{'type' | translate}}</th>
              <td *ngIf="deal?.dealType">{{'vc' | translate}} </td>
              <td *ngIf="!deal?.dealType">{{'ido' | translate}}</td>
            </tr>
            <tr>
              <th>{{'currency' | translate}}</th>
              <td>{{paymentTokenSymbol}}</td>
            </tr>
            <tr>
              <th>{{'minRaise' | translate}}</th>
              <td>{{minimumRaise| shiftDecimals:-rewardDecimals| number: '1.0-2'}} {{paymentTokenSymbol}}</td>
            </tr>
            <tr>
              <th>{{'start' | translate}}</th>
              <td>{{startTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
            </tr>
            <tr>
              <th>{{'end' | translate}}</th>
              <td>{{finishTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
            </tr>
            <tr>
              <th>{{'tokenGeneration' | translate}}</th>
              <td *ngIf="!deal?.hideTgeDate">{{startClaimTimestamp* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</td>
              <td *ngIf="deal?.hideTgeDate">{{'toBeAnnounced' | translate}}</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vesting' | translate}} %</th>
              <td>{{vestingPercent}}%</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vestingStartDate' | translate}}</th>
              <td>{{vestingStart* 1000 | date:'dd MMM, yyyy H:mm a' : undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}} ({{'timestamp' | translate}}: {{vestingStart}})</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vestingDuration' | translate}}</th>
              <td>{{getTranslatedShowPeriod(vestingDuration)}}</td>
            </tr>
            <tr *ngIf="vestingPercent">
              <th>{{'vestingInterval' | translate}}</th>
              <td> {{getTranslatedShowPeriod(vestingInterval)}}</td>
            </tr>
          </table>
        </div>
        <div class="tab-pane fade"
             id="deal-about"
             role="tabpanel"
             aria-labelledby="deal-about-tab">
          <div class="tab-text text-muted">
            {{deal?.description}}
          </div>
        </div>
        <div class="tab-pane fade"
             id="deal-allocation"
             role="tabpanel"
             aria-labelledby="deal-allocation-tab">
          <div class="row g-0">
            <div class="col-lg-6 border-lg-end border-md-bottom">
              <div class="form-group">
                <div class="p-4">
                  <div class="label">{{'ticketSize' | translate}}</div>
                </div>
                <div class="p-4">
                  <div class="tier-control-group">
                    <div class="tier-control"
                         *ngFor="let item of allTiers; index as i;">
                      <div class="tier-control__icon contain-img">
                        <img [src]="i | imgTierPipe"
                             alt="" />
                      </div>
                      <div class="tier-control__input">
                        <div class="input-group-icon">
                          <span class="icon token-symbol">{{paymentTokenSymbol}}</span>
                          <div class="form-control">
                            {{item.ticketSize| shiftDecimals:-paymentDecimal}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <div class="p-4">
                  <div class="label">{{'allocation' | translate}}</div>
                </div>
                <div class="p-4">
                  <div class="tier-control-group">
                    <div class="tier-control"
                         *ngFor="let item of allTiers; index as i;">
                      <div class="tier-control__icon contain-img">
                        <img [src]="i | imgTierPipe"
                             alt="" />
                      </div>
                      <div class="tier-control__input">
                        <div class="input-group-icon">
                          <span class="icon token-symbol">{{rewardTokenSymbol}}</span>
                          <!--<input type="text" class="form-control" placeholder="Bronze">-->
                          <div class="form-control">
                            {{item.allocation| shiftDecimals:-rewardDecimals}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
