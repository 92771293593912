import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { merge, throwError as _throw } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';

import { DlgAlertComponent } from './dlg-alert.component';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class AlertService {
    constructor(private _dialog: MatDialog, public translate: TranslateService) {}

    show(message?: MatDialogConfig<any> | string, icon: string = ''): Promise<any> {
        if (typeof message === 'string') {
            message = {
                data: {
                    icon,
                    innerHTML: `<div class="text-center small-text mb-4">${message}</div>`,
                },
            };
        }

        const dialogRef = this._dialog.open(DlgAlertComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
            ...message,
        });

        return dialogRef.afterClosed().toPromise();
    }

    showMinedSwap(): Promise<any> {
        var message = {
            data: {
                message: this.translate.instant('transactionWasConfirmed'),
                innerHTML:
                    'Once there are enough confirmations from oracles,<br/> it can be claimed in the target blockchain ',
            },
        };

        const dialogRef = this._dialog.open(DlgAlertComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
            ...message,
        });

        return dialogRef.afterClosed().toPromise();
    }

    showMinedTx(): Promise<any> {
        var message = {
            data: {
                message: this.translate.instant('transactionWasConfirmed'),
                innerHTML: '',
            },
        };

        const dialogRef = this._dialog.open(DlgAlertComponent, {
            panelClass: ['dlg-light', 'dlg-small'],
            scrollStrategy: new NoopScrollStrategy(),
            ...message,
        });

        return dialogRef.afterClosed().toPromise();
    }
}
