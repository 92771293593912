import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'imgBlackSymbol',
})
export class ImgBlackSymbolPipe implements PipeTransform {
    transform(symbol: string): string {
        if (symbol.includes('BNB')) return '/assets/svg/bsc.svg';
        if (symbol.includes('BSC')) return '/assets/svg/bnb.svg';
        if (symbol.includes('ETH')) return '/assets/svg/eth_black.svg';
        if (symbol.includes('AVAX')) return '/assets/svg/eth_black.svg';
        if (symbol.includes('Kovan')) return '/assets/svg/eth_black.svg';
        if (symbol.includes('HT')) return '/assets/svg/heco.svg';
        if (symbol.includes('HB')) return '/assets/svg/heco.svg';
        if (symbol.includes('heco')) return '/assets/svg/heco.svg';
        return '';
    }
}
