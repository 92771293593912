import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './edit-claiming-deal.component.html',
    styleUrls: ['./edit-claiming-deal.component.scss'],
})
export class EditClaimingDealComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
