<header class="dashboard-header">
  <div class="dashboard-header__title dashboard-header-item">
    <h1 class="header-title"
        [ngSwitch]="routerPath">
      <span *ngSwitchCase="'/'"><i class="icomoon-dashboard"></i> {{'dashboard' | translate}}</span>
      <span *ngSwitchCase="'address-book'"><i class="icomoon-address-book"></i> {{'addressBook' | translate}}</span>
      <span *ngSwitchCase="'login'">{{'login' | translate}}</span>
      <span *ngSwitchCase="'signup'">{{'registration' | translate}}</span>
      <span *ngSwitchCase="'kyc'"><i class="icomoon-kys"></i> {{'kyc' | translate}}</span>
      <span *ngSwitchCase="'staking'">{{'stakingPools' | translate}}</span>
      <span *ngSwitchCase="'farming'">{{'farmingPools' | translate}}</span>
      <span class="back-to-prev"
            *ngSwitchCase="'pool-detail'"
            (click)="backToPrev()">{{'poolDetails' | translate}}</span>
      <span *ngSwitchCase="'create-pool'">{{'createPool' | translate}}</span>

      <span *ngSwitchCase="'deals'">{{'deals' | translate}}</span>
      <a (click)="backToPrev()"
         class="back-to-prev"
         *ngSwitchCase="'deal-detail'">{{'dealDetails' | translate}}</a>

      <span *ngSwitchCase="'portfolio'"><i class="icomoon-portfolio"></i>{{'portfolio' | translate}}</span>

      <span *ngSwitchCase="'voting'">{{'voting' | translate}}</span>
    </h1>
  </div>

  <app-wallet class="dashboard-header-item"></app-wallet>

  <div class="dashboard-header__select d-flex dashboard-header-item">
    <button mat-button
            class="dropdown-button __select-network flex-grow-1"
            [matMenuTriggerFor]="networks">
      <img src="{{ web3Service.chainIdNumber | imgNetworkPipe }}" style="float:left;"/>
      <span *ngIf="!availableNetworks.includes(web3Service.chainIdNumber)">{{'unsupportedNetwork' | translate}}</span>
      <span *ngIf="availableNetworks.includes(web3Service.chainIdNumber)">{{getNetworkName(web3Service.chainIdNumber)}}</span>
    </button>
    <mat-menu #networks="matMenu"
              class="select-network-menu">
      <button mat-menu-item
              *ngFor="let networkId of availableNetworks"
              (click)="selectNetworkClick(networkId)">
        <img src="{{ networkId | imgNetworkPipe }}"/>
        {{getNetworkName(networkId)}}
      </button>
    </mat-menu>
  </div>

  <div class="langs-notifications-wrapper dashboard-header-item">
    <div class="dashboard-header__select language">
      <button mat-button class="dropdown-button __select-language" [matMenuTriggerFor]="languagesDropdown">
        <img src="assets/svg/{{translate.currentLang}}-lang.svg" style="float:left;" alt="{{translate.currentLang}}-lang"/>
        <span class="lang-text">{{translate.currentLang | languageName}}</span>
      </button>
      <mat-menu #languagesDropdown="matMenu" backdropClass="languages-menu-backdrop" class="select-network-menu">
        <button  mat-menu-item *ngFor="let language of languages" (click)="selectLanguage(language)">
          <img src="assets/svg/{{ language }}-lang.svg" alt="{{language}}-lang"/>
          {{language | languageName}}
        </button>
      </mat-menu>
    </div>

    <div class="dashboard-header__select notifications">
      <button *ngIf="authenticated" mat-icon-button matTooltip="{{'viewNotifications' | translate}}" [matMenuTriggerFor]="notificationsList" (menuClosed)="notificationsClosed()" class="__show-notifications p-3">
        <img src="../../assets/svg/notifications.svg"/>
        <div *ngIf="unreadNotificationsCount > 0" class="has-unread"></div>
      </button>
      <mat-menu #notificationsList="matMenu" xPosition="after" backdropClass="notifications-menu-backdrop" class="notifications-menu">
        <div class="notifications-top d-flex justify-content-between">
          <div class="notifications-new">{{notificationsStatus}}</div>
          <button *ngIf="notifications?.length > 0" class="mat-raised-button mat-button-base mat-primary notifications-clear" (click)="clearNotifications()">{{'clearAll' | translate}}</button>
        </div>
        <div mat-menu-item class="d-block px-4 py-2" *ngFor="let notification of notifications" (click)="selectNotification($event, notification)">
          <div class="d-flex">
            <img src="{{notification.iconURL ?? '../../assets/svg/info.svg'}}" />
            <div class="notification-title unread" [ngClass]="{'unread': !notification.read}">{{notification.title}}</div>
          </div>
          <div class="notification-text">{{notification.text}}</div>
          <div class="notification-date">{{notification.date | date :'d MMM, h:mm a': undefined : (translate.currentLang === 'vi' ? 'en' : translate.currentLang)}}</div>
        </div>
      </mat-menu>
    </div>
  </div>
</header>
