import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
    name: 'descriptionTierPipe',
})
export class DescriptionTierPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}
    transform(index: number): string {
        switch (index) {
            case -1:
                return '';
            case 0:
                return `<ul><li>${this.translate.instant('3DaysLockingPeriod')}</li></ul>`;
            case 1:
                return `<ul><li>${this.translate.instant('3DaysLockingPeriod')}</li></ul>`;
            case 2:
                return `<ul><li>${this.translate.instant('2DaysLockingPeriod')}</li></ul>`;
            case 3:
                return `<ul><li>${this.translate.instant('2DaysLockingPeriod')}</li></ul>`;
            case 4:
                return `<ul><li>${this.translate.instant('1DayLockingPeriod')}</li></ul>`;
            case 5:
                return `<ul><li>${this.translate.instant('noLockingPeriod')}</li></ul>`;
            default:
                return '';
        }
    }
}
