<section class="section-alert">
  <div class="alert alert-info"
       role="alert"
       *ngIf="!isAuthorized">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{'youNeedLoginToUsePlatform' | translate}}
    </div>
    <div class="alert-button">
      <button mat-raised-button
              color="primary"
              (click)="navigateToLogin()"
              class="login_main">
        {{'login' | translate}}
      </button>
    </div>
  </div>

  <div class="alert alert-info"
       role="alert"
       *ngIf="isAuthorized && !kycConfirmed">
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      {{'toJoinDealsNeedPassVerification' | translate}}
    </div>
    <div class="alert-button">
      <button mat-raised-button
              color="primary"
              (click)="navigateToKYC()">
        {{'kycForGamespadProjects' | translate}}
      </button>
    </div>
  </div>
  <!--  УВЕДОМЛЕНИЯ НОВЫЕ
  <div class="alert alert-info alert-lock" role="alert" >
    <div class="alert-icon icomoon-info"><img src="/assets/images/svg/lock.svg" alt=""></div>
    <div class="alert-heading">
      You are locked-up for 2d 3h 32m 15s
    </div>
    <div class="alert-button">
      <button mat-raised-button color="primary" (click)="navigateToKYC()">
        What Does It Mean?
      </button>
    </div>
  </div>

  <div class="alert alert-info alert-massenge" role="alert" >
    <div class="alert-icon icomoon-info"></div>
    <div class="alert-heading">
      Message from admin
    </div>
    <div class="alert-button">
      <button mat-raised-button color="primary" (click)="navigateToKYC()">
        Button Text
      </button>
    </div>
  </div>

-->

</section>
