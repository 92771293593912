import { Component, OnInit } from '@angular/core';
import { UserDTO, UsersServiceProxy } from '../../service-proxies/service-proxies';
import { Router } from '@angular/router';
import { UserSessionProvider } from '../../shared/user-session-provider';
import { AlertService } from '../shared-dlg.module';

@Component({
    selector: 'info-join-deals',
    templateUrl: './info-join-deals.component.html',
})
export class InfoJoinDealsComponent implements OnInit {
    constructor(
        private userSessionProvider: UserSessionProvider,
        private usersService: UsersServiceProxy,
        private router: Router,
        private _alertSrv: AlertService,
    ) {}

    userInfo: UserDTO;
    kycConfirmed: boolean = true;
    isAuthorized: boolean = false;

    async ngOnInit() {
        this.isAuthorized = this.userSessionProvider.isAuthorized;
        if (this.userSessionProvider.isAuthorized) {
            this.usersService.getMe().subscribe(
                result => {
                    this.userInfo = result;
                    this.kycConfirmed = result.kycConfirmed;
                },
                error => {
                    if (error.status == 401) {
                        console.error('401');
                        this.userSessionProvider.finishAuth();
                        this.navigateToLogin();
                    } else this._alertSrv.show(JSON.parse(error.response).message, 'error');
                },
            );
        }
    }

    navigateToLogin(): void {
        this.router.navigate(['/login']);
    }

    navigateToKYC(): void {
        this.router.navigate(['/kyc']);
    }
}
